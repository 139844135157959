import React, { Component } from 'react'
import { Button, Spinner } from "reactstrap";

export default class LoadingButton extends Component {

    render() {
        let { isLoading, disabled, children, text, ...otherProps } = this.props;

        const content = text ? text : children

        if (isLoading) {
            disabled = true;
            return (
                <Button {...otherProps} disabled={disabled}>
                    <Spinner color="light" className="button-spinner" />
                    <span>{'  '}</span>
                    {content}
                </Button>
            )
        } else {
            return (
                <Button {...otherProps} disabled={disabled}>
                    {content}
                </Button>
            )
        }
    }
}
