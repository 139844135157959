import React, { Component } from 'react';
import { withContext, session, api } from 'services';
import { Footer, Body, ButtonMenu, Manat, Dialog } from 'components/Views'
import { NavLink } from "react-router-dom"
import { Trans } from "react-i18next";
import { images } from "config";
import { Spinner } from 'reactstrap';
// import LiveChat from 'react-livechat'

class Profile extends Component {

    _isMounted = false;


    constructor(props) {
        super(props);

        this.state = {
            user: session.getUser(),
            showMessage: false,
            isContactsLoading: false,
            contacts: []
        }

        this.setStateWithMount = this.setStateWithMount.bind(this);
        this.goToWhatsapp = this.goToWhatsapp.bind(this);
        this.loadContacts = this.loadContacts.bind(this);
        // this.onChatLoaded = this.onChatLoaded.bind(this);
        this.logout = this.logout.bind(this);
    }


    logout = () => {
        this.props.client.disconnect();
        this.props.chat.clearListeners();
        session.clear();

        const livechatScript = document.getElementById("lc_id_api");
        if (livechatScript) {
            document.head.removeChild(livechatScript)

            // destroy livechatwidget
            window.LiveChatWidget.call("destroy")
        }

        // go back to homepage
        // window.location.reload()
        this.props.history.push("/");
    }

    // onChatLoaded = (ref) => {
    //     this.livechat = ref
    //     this.setState({ isContactsLoading: false })
    // }


    loadContacts() {
        this.setStateWithMount({ isContactsLoading: true })
        api.getContacts().then(data => {
            let contacts = data.contacts;
            this.setStateWithMount({ isContactsLoading: false, contacts })
        }).catch(error => {
            this.setStateWithMount({ isContactsLoading: false })
        })
    }


    goToWhatsapp = () => {
        // let { contacts } = this.state;
        // const url = "https://api.whatsapp.com/send?phone=" + contacts[0] + "&text=Salam%2C%20balans%20%C9%99m%C9%99liyyat%C4%B1%20el%C9%99m%C9%99k%20ist%C9%99yir%C9%99m.%20%C4%B0stifad%C9%99%C3%A7i%3A%20" + this.state.user.username
        // window.open(url);
        session.hideDepositMessage();
        // this.livechat.open_chat_window();
        // window.LiveChatWidget.call("maximize");
        this.setStateWithMount({ showMessage: false })

    }

    setStateWithMount = (data, cb = null) => {
        if (this._isMounted) {
            if (cb !== null) {
                this.setState(data, cb);
            } else {
                this.setState(data);
            }
        }
    };

    componentDidMount() {
        this._isMounted = true;
        // this.loadContacts();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render = () => {
        const { user, isContactsLoading } = this.state

        return (
            <div className="d-flex flex-fill flex-column flex-nowrap">

                <div className="header">
                    <div className="container">
                        <div className="header-container">
                            <div className="logo-container">
                                <NavLink to="/">
                                    <img src={images.logoWhite} className="logo" alt="" />
                                </NavLink>
                            </div>
                            <div className="profile-username">
                                <span className="username">{user.name}</span>
                                <img src={images.profile} className="logo" alt="" />
                            </div>
                        </div>

                        <Dialog
                            t={this.props.t}
                            isOpen={this.state.showMessage}
                            toggle={() => {
                                this.setStateWithMount({
                                    showMessage: !this.state.showMessage
                                })
                            }}
                            cancelText={this.props.t("dialog.close")}
                            onCancelClick={() => {
                                this.setStateWithMount({
                                    showMessage: false
                                })
                            }}

                            acceptText={this.props.t("dialog.accept")}
                            onAcceptClick={this.goToWhatsapp}
                            title={this.props.t("dialog.info")}
                        >
                            <Trans i18nKey="profile.minimumDeposit">
                                Dear customer, please note that minimum deposit amount is
                                <span style={{ color: "#713198", fontWeight: "bold", fontSize: 18 }}>
                                    5.00 AZN
                                </span>
                            </Trans>
                        </Dialog>


                        <div className="profile-balance" onClick={() => {
                            if (!isContactsLoading) {
                                // if (session.showDepositMessage() === true) {
                                //     // this.setStateWithMount({ showMessage: true })

                                // } else {
                                //     this.goToWhatsapp()
                                // }
                                this.props.history.push("/wallet")
                            }

                        }}>
                            <div className="icon-container">
                                {isContactsLoading && <Spinner style={{ color: "#d9720a" }} />}
                                {!isContactsLoading &&
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.02" height="18.393" viewBox="0 0 21.02 18.393">
                                        <path id="Icon_awesome-wallet" data-name="Icon awesome-wallet" d="M18.934,6.191H3.284a.657.657,0,0,1,0-1.314H19.049a.657.657,0,0,0,.657-.657A1.971,1.971,0,0,0,17.736,2.25H2.628A2.627,2.627,0,0,0,0,4.878V18.015a2.627,2.627,0,0,0,2.628,2.628H18.934a2.033,2.033,0,0,0,2.086-1.971V8.162A2.033,2.033,0,0,0,18.934,6.191Zm-1.856,8.539a1.314,1.314,0,1,1,1.314-1.314A1.314,1.314,0,0,1,17.079,14.731Z" transform="translate(0 -2.25)" fill="#e59741" />
                                    </svg>
                                }
                            </div>
                            <div className="amount">
                                <span className="label">{this.props.t("profile.balance")}</span>
                                <span className="balance">{user.balance} <Manat width="18" height="20" style={{ marginLeft: 0 }} /></span>
                            </div>
                            <div className="plus">
                                <span className="gg-math-plus"></span>
                            </div>
                        </div>
                    </div>

                </div>
                <Body loading={isContactsLoading || this.props.isChatLoading} cardStyle={{ padding: 8, display: "flex", flexDirection: "column" }}  >
                    <span className="section-title">{this.props.t("profile.account")}</span>
                    <ButtonMenu
                        title={this.props.t("profile.accountDetails")}
                        subtitle={this.props.t("profile.accountDetailsDesc")}
                        onClick={() => {
                            this.props.history.push("/account");
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.422" height="16.422" viewBox="0 0 16.422 16.422">
                            <path id="Icon_material-account-circle" data-name="Icon material-account-circle" d="M11.211,3a8.211,8.211,0,1,0,8.211,8.211A8.214,8.214,0,0,0,11.211,3Zm0,2.463A2.463,2.463,0,1,1,8.748,7.927,2.46,2.46,0,0,1,11.211,5.463Zm0,11.66a5.912,5.912,0,0,1-4.927-2.644c.025-1.634,3.284-2.529,4.927-2.529s4.9.895,4.927,2.529a5.912,5.912,0,0,1-4.927,2.644Z" transform="translate(-3 -3)" fill="#713198" />
                        </svg>

                    </ButtonMenu>
                    <ButtonMenu
                        title={this.props.t("profile.changePassword")}
                        subtitle={this.props.t("profile.changePasswordDesc")}
                        onClick={() => {
                            this.props.history.push("/password");
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.595" height="16.68" viewBox="0 0 14.595 16.68">
                            <path id="Icon_awesome-lock" data-name="Icon awesome-lock" d="M13.031,7.3h-.782V4.952a4.952,4.952,0,1,0-9.9,0V7.3H1.564A1.564,1.564,0,0,0,0,8.861v6.255A1.564,1.564,0,0,0,1.564,16.68H13.031a1.564,1.564,0,0,0,1.564-1.564V8.861A1.564,1.564,0,0,0,13.031,7.3Zm-3.388,0H4.952V4.952a2.346,2.346,0,1,1,4.691,0Z" fill="#fff" />
                        </svg>
                    </ButtonMenu>
                    <ButtonMenu
                        title={this.props.t("profile.language")}
                        subtitle={this.props.t("profile.languageDesc")}
                        onClick={() => {
                            this.props.history.push("/language");
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.435" height="13.977" viewBox="0 0 15.435 13.977">
                            <path id="Icon_material-translate" data-name="Icon material-translate" d="M9.5,11.807,7.787,10.116l.02-.02a11.8,11.8,0,0,0,2.5-4.4h1.974V4.348H7.564V3H6.217V4.348H1.5V5.689H9.027A10.588,10.588,0,0,1,6.891,9.3,10.542,10.542,0,0,1,5.334,7.043H3.986a11.834,11.834,0,0,0,2.008,3.073L2.565,13.5l.957.957,3.369-3.369,2.1,2.1Zm3.794-3.416H11.944L8.912,16.477H10.26l.755-2.021h3.2l.761,2.021h1.348Zm-1.765,4.717,1.092-2.918,1.092,2.918Z" transform="translate(-1.25 -2.75)" fill="#713198" stroke="#39184d" strokeWidth="0.5" />
                        </svg>
                    </ButtonMenu>
                    <span className="section-title">{this.props.t("profile.others")}</span>
                    <ButtonMenu
                        title={this.props.t("profile.playedGames")}
                        subtitle={this.props.t("profile.playedGamesDesc")}
                        onClick={() => {
                            this.props.history.push("/playedgames");
                        }}
                    >

                        <svg xmlns="http://www.w3.org/2000/svg" width="19.354" height="16.357" viewBox="0 0 19.354 16.357">
                            <path id="Icon_material-history" data-name="Icon material-history" d="M12.071,4.5a7.929,7.929,0,0,0-7.929,7.929H1.5l3.427,3.427.062.123,3.559-3.55H5.9A6.2,6.2,0,1,1,7.72,16.78L6.469,18.031A7.927,7.927,0,1,0,12.071,4.5ZM11.19,8.9v4.4l3.77,2.238.634-1.066-3.083-1.832V8.9Z" transform="translate(-0.896 -4.25)" fill="#713198" stroke="#3a184d" strokeWidth="0.5" />
                        </svg>

                    </ButtonMenu>
                    {/* <ButtonMenu
                        title={this.props.t("profile.templates")}
                        subtitle={this.props.t("profile.templatesDesc")}
                    >

                        <svg xmlns="http://www.w3.org/2000/svg" width="14.976" height="15.016" viewBox="0 0 14.976 15.016">
                            <g id="browser" transform="translate(-0.62)">
                                <g id="Group_893" data-name="Group 893" transform="translate(0.62)">
                                    <g id="Group_892" data-name="Group 892" transform="translate(0)">
                                        <path id="Path_14052" data-name="Path 14052" d="M8.425,12.531l-1.551.4a1.358,1.358,0,0,1-1.653-1.653l.035-.137H3.943a.816.816,0,1,1,0-1.632H5.688a2.283,2.283,0,0,1,.528-.827l.61-.61H3.943a.816.816,0,1,1,0-1.632H8.458L9.9,5H3.943a.816.816,0,1,1,0-1.632H9.851a.816.816,0,0,1,.816.816c0,.015,0,.029,0,.044l1.98-1.98a2.309,2.309,0,0,1,.528-.4V.925A.925.925,0,0,0,12.249,0H1.545A.925.925,0,0,0,.62.925V14.091a.925.925,0,0,0,.925.925h10.7a.925.925,0,0,0,.925-.925V8.231l-3.7,3.7A2.282,2.282,0,0,1,8.425,12.531Z" transform="translate(-0.62)" fill="#713198" />
                                        <path id="Path_14053" data-name="Path 14053" d="M171.017,131.054a1.3,1.3,0,0,0-.34.6l-.4,1.551a.379.379,0,0,0,.461.461l1.551-.4a1.3,1.3,0,0,0,.6-.34l5.266-5.266-1.87-1.87Z" transform="translate(-164.728 -121.682)" fill="#713198" />
                                        <path id="Path_14054" data-name="Path 14054" d="M378.131,78.652a1.322,1.322,0,0,0-1.87,0l-.472.472L377.656,81l.474-.474A1.322,1.322,0,0,0,378.131,78.652Z" transform="translate(-363.542 -75.71)" fill="#713198" />
                                    </g>
                                </g>
                            </g>
                        </svg>

                    </ButtonMenu> */}

                    <ButtonMenu
                        title={this.props.t("profile.transactions")}
                        subtitle={this.props.t("profile.transactionsDesc")}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.579" height="13.89" viewBox="0 0 17.579 13.89">
                            <g id="transfer" transform="translate(0.302 -50.896)">
                                <g id="Group_894" data-name="Group 894" transform="translate(0 51.198)">
                                    <path id="Path_14055" data-name="Path 14055" d="M16.8,55.62l-4.244-4.244a.606.606,0,0,0-1.035.429v1.819H7.275a.606.606,0,0,0,0,1.213h4.85a.606.606,0,0,0,.606-.606v-.962l2.78,2.78-2.78,2.78v-.962a.606.606,0,0,0-.606-.606H5.456V55.442a.606.606,0,0,0-1.035-.429L.177,59.257a.606.606,0,0,0,0,.857l4.244,4.244a.606.606,0,0,0,.429.178.6.6,0,0,0,.232-.046.606.606,0,0,0,.374-.56V62.111H9.7a.606.606,0,1,0,0-1.213H4.85a.606.606,0,0,0-.606.606v.962l-2.78-2.78,2.78-2.78v.962a.606.606,0,0,0,.606.606h6.669v1.819a.606.606,0,0,0,1.035.429L16.8,56.477A.606.606,0,0,0,16.8,55.62Z" transform="translate(0 -51.198)" fill="#713198" stroke="#39184d" strokeWidth="0.5" />
                                </g>
                            </g>
                        </svg>

                    </ButtonMenu>

                    <ButtonMenu
                        title={this.props.t("profile.logout")}
                        onClick={this.logout}
                        subtitle=""
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.048" height="17.087" viewBox="0 0 17.048 17.087">
                            <path id="logout" d="M8.711,0a8.541,8.541,0,0,1,8.5,7.767H10.265V5.437a.776.776,0,0,0-1.326-.549L5.832,7.994a.776.776,0,0,0,0,1.1L8.939,12.2a.776.776,0,0,0,1.326-.549V9.32h6.951A8.542,8.542,0,1,1,8.711,0Zm0,0" transform="translate(-0.168)" fill="#713198" />
                        </svg>
                    </ButtonMenu>


                </Body>

                <Footer unReadMessageCount={this.props.chat.getUnreadMessagesCount()}/>
            </div>

        )
    }


}

export default withContext(Profile);