import React, { Component } from 'react';
import withContext from 'services/withContext';
import { ContainerView } from 'components/Views'
import { Button } from "reactstrap";

class Transactions extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }

    this.props.client.requestUserData();
  }

  render = () => {
    return (
      <ContainerView>

        <span className="section-header">{this.props.t("profile.changePassword")}</span>
        <div className="page-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="36.148" height="36.148" viewBox="0 0 36.148 36.148">
            <g id="up-arrow" transform="translate(13.231 0) rotate(30)">
              <g id="Group_1072" data-name="Group 1072" transform="translate(0 0)">
                <g id="Group_1071" data-name="Group 1071" transform="translate(0)">
                  <path id="Path_15929" data-name="Path 15929" d="M13.231,0A13.283,13.283,0,0,0,0,13.231,13.528,13.528,0,0,0,10.905,26.462V13.449l-2.138.534a.775.775,0,0,1-.808-1.217l4.652-6.2a.8.8,0,0,1,1.24,0l4.652,6.2a.775.775,0,0,1-.808,1.217l-2.19-.534V26.462A13.573,13.573,0,0,0,26.462,13.231,13.283,13.283,0,0,0,13.231,0Z" transform="translate(0)" fill="#59c99e" />
                </g>
              </g>
            </g>
          </svg>


          <svg xmlns="http://www.w3.org/2000/svg" width="36.148" height="36.148" viewBox="0 0 36.148 36.148">
            <g id="up-arrow" transform="translate(13.231 0) rotate(30)">
              <g id="Group_1072" data-name="Group 1072" transform="translate(0 0)">
                <g id="Group_1071" data-name="Group 1071">
                  <path id="Path_15929" data-name="Path 15929" d="M13.232,26.462A13.283,13.283,0,0,1,0,13.231,13.528,13.528,0,0,1,10.906,0V13.013l-2.138-.534A.775.775,0,0,0,7.96,13.7l4.652,6.2a.8.8,0,0,0,1.24,0L18.5,13.7a.775.775,0,0,0-.808-1.217l-2.19.534V0A13.573,13.573,0,0,1,26.463,13.231,13.283,13.283,0,0,1,13.232,26.462Z" transform="translate(-0.001 0)" fill="#fd4343" />
                </g>
              </g>
            </g>
          </svg>

        </div>

        <Button className="btn-accept btn-profile" onClick={this.props.onAcceptClick}>{this.props.t("changePassword.changePassword")}</Button>

      </ContainerView>

    )
  }



}

export default withContext(Transactions);