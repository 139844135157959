import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { Dialog } from "components/Views";
import { GameContext } from "context";
import PageVisibility from 'react-page-visibility';
import * as session from './session'

const withContext = Wrapped => {
    class WithContext extends Component {
        constructor(props) {
            super(props);

            this.state = {
                connected: true,
                user: session.getUser(),
                showMessage: false,
                isLoading: false,
                unReadMessageCount: session.getUnreadMessagesCount(),
                message: '',
                isPageVisible: true,
                isChatLoading: false
            }

            if (!this.props.client.isConnected()) {
                this.props.client.connect();
            }

            if (session.getLangauge()) {
                this.props.i18n.changeLanguage(session.getLangauge())
            }


            this.props.client.socket.on('connect', () => {
                // console.log('asfasf')
                this.props.chat.init();

            })


            this.onConnectError = this.onConnectError.bind(this);
            this.onReconnect = this.onReconnect.bind(this);
            this.onReconnecting = this.onReconnecting.bind(this);
            this.onDisconnect = this.onDisconnect.bind(this);
            this.onUnauthorize = this.onUnauthorize.bind(this);
            this.onCloseScreen = this.onCloseScreen.bind(this);
            this.onConnectGame = this.onConnectGame.bind(this);
            this.onChatLoad = this.onChatLoad.bind(this)
            this.onUserInfoUpdate = this.onUserInfoUpdate.bind(this);
            this.handleVisibilityChange = this.handleVisibilityChange.bind(this)

        }

        handleVisibilityChange = isVisible => {
            this.setState({ isPageVisible: isVisible });
        }

        onChatLoad = () => {
            this.setState({
                isChatLoading: false
            })
        }

        onCloseScreen = () => {
            this.props.client.disconnect();
            this.setState({
                showMessage: true,
                isChatLoading: false,
                message: this.props.t("error.useHere")
            })
        }

        onConnectGame = (uuid) => {
            this.props.history.push({
                pathname: "/game",
                search: "?gameId=" + uuid
            });
        }

        onUserInfoUpdate = (user) => {
            this.setState({
                user
            })
            session.setUser(user);
        }

        onDisconnect = () => {
        };

        onReconnecting = () => {
        }

        onReconnect = attemptNumber => {
            if (this.state.isLoading) {

                this.setState({
                    isLoading: false,
                    showMessage: false,
                    // isChatLoading: false,
                    connected: true
                })
            }
        };

        onConnectError = error => {
            if (!this.state.isLoading) {
                this.setState({
                    isLoading: true,
                    showMessage: true,
                    isChatLoading: false,
                    connected: false
                })
            }
        };

        onUnauthorize = () => {
            session.clear();
            this.props.chat.clearListeners();
            this.props.history.push("/");
        }

        componentDidMount = () => {
            this.props.client.registerConnectErrorHandler(this.onConnectError);
            this.props.client.registerReconnectHandler(this.onReconnect);
            this.props.client.registerReconnectingHandler(this.onReconnecting);
            this.props.client.registerDisconnectHandler(this.onDisconnect);
            this.props.client.registerUnauthorized(this.onUnauthorize);
            this.props.client.registerCloseScreen(this.onCloseScreen);
            this.props.client.registerStartGameHandler(this.onConnectGame);
            this.props.client.registerUserInfoUpdateHandler(this.onUserInfoUpdate);


        }

        componentWillUnmount = () => {
            this.props.client.unregisterReconnectHandler(this.onReconnect);
            this.props.client.unregisterDisconnectHandler(this.onDisconnect);
            this.props.client.unregisterReconnectingHandler(this.onReconnecting);
            this.props.client.unregisterDisconnectHandler(this.onDisconnect);
            this.props.client.unregisterUnauthorized(this.onUnauthorize);
            this.props.client.unregisterCloseScreen(this.onCloseScreen);
            this.props.client.unregisterStartGameHandler(this.onConnectGame);
            this.props.client.unregisterUserInfoUpdateHandler(this.onUserInfoUpdate);

            if (window.LiveChatWidget) {
                window.LiveChatWidget.off('ready', this.onChatLoad)
            }
        }

        render = () => {
            return (
                <PageVisibility onChange={this.handleVisibilityChange}>
                    <div className="d-flex flex-fill">
                        <Dialog
                            t={this.props.t}
                            isOpen={this.state.showMessage}
                            isProgress={this.state.isLoading}
                            acceptText={this.props.t("dialog.useHere")}
                            onAcceptClick={() =>
                                window.location.reload(false)
                            }
                            title={this.props.t("dialog.error")}
                            content={this.state.message}
                        />

                        <Wrapped
                            {...this.props}
                            // unReadMessageCount={this.state.unReadMessageCount}
                            isConnected={this.state.connected}
                            isChatLoading={this.state.isChatLoading}
                            isPageVisible={this.state.isPageVisible}
                            user={this.state.user} />
                    </div>
                </PageVisibility>

            )
        }
    }

    function Consumer(props) {
        return (
            <GameContext.Consumer>
                {context =>
                    <WithContext {...props}
                        client={context.client}
                        chat={context.chat}
                        // firebase={context.firebase} zzz
                        audio={context.audio}
                    // isSoundLoaded={context.audio.isSoundLoaded}
                    />
                }
            </GameContext.Consumer>
        )
    }


    return compose(withRouter,
        withTranslation("common")
    )(Consumer);
}

export default withContext;