const images = {
    logoDark: require('assets/images/logoDark.svg').default,
    logoWhite: require('assets/images/logoWhite.svg').default,
    profile: require('assets/images/profile.png'),
    flags: {
        az: require('assets/images/flags/az.png'),
        tr: require('assets/images/flags/tr.png'),
        ru: require('assets/images/flags/ru.png'),
        en: require('assets/images/flags/en.png'),
        kk: require('assets/images/flags/kk.png'),
        kz: require('assets/images/flags/kz.png'),
    },
    winner: require('assets/images/winner.png'),
    coinstack: require('assets/images/coinstack.png'),
    star: require('assets/images/svg/star.svg'),
    money: require('assets/images/svg/money.svg'),
    sad: require('assets/images/sad.svg'),
    ranking: {
        gold: require('assets/images/ranking/gold.png'),
        silver: require('assets/images/ranking/silver.png'),
        bronze: require('assets/images/ranking/bronze.png'),
    },
    confetti: require('assets/images/confetti.png'),
    status: {
        success: require('assets/images/status/success.svg').default,
        pending: require('assets/images/status/pending.svg').default,
        fail: require('assets/images/status/fail.svg').default
    },
    wonsign: require('assets/images/svg/wonsign.svg').default,
    svg: {
        keg: require('assets/images/svg/keg.svg')
    },
    cardType: {
        mastercard: require('assets/images/cardType/mastercard.svg').default,
        visa: require('assets/images/cardType/visa.svg').default,
        c2c: require('assets/images/cardType/c2c.svg').default,
        smartpay: require('assets/images/cardType/smartpay.svg').default,
        emanat: require('assets/images/cardType/emanat.svg').default
    },
    icons: {
        keg: require('assets/images/icons/keg.png'),
        chat: require('assets/images/icons/chat.png'),
        leaderboard: require('assets/images/icons/leaderboard.png'),
        profile: require('assets/images/icons/profile.png'),
        settings: require('assets/images/icons/settings.png'),
        edit: require("assets/images/icons/edit.png"),
        refresh: require("assets/images/icons/refresh.png"),
        delete: require("assets/images/icons/delete.png"),
    },
    social: {
        whatsapp: require('assets/images/whatsapp.png'),
        tiktok: require('assets/images/tiktok.png'),
        instagram: require('assets/images/instagram.png'),
        facebook: require('assets/images/facebook.png')
    },
}

export default images