import React, { Component } from 'react';
import { session, withContext } from 'services';
import { Input, FormGroup, Label, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap"
import { Header, Body, LoadingButton, Dialog, Manat, ButtonWithSVG } from 'components/Views'
import { ReactComponent as EditIcon } from '../assets/images/ticket/edit.svg'
import { ReactComponent as BookmarkFilledIcon } from '../assets/images/ticket/bookmark_filled.svg'
import { ReactComponent as BookmarkIcon } from '../assets/images/ticket/bookmark.svg'
import { ReactComponent as ClearIcon } from '../assets/images/ticket/eraser.svg'
import { ReactComponent as TrashIcon } from '../assets/images/ticket/trash.svg'
import { ReactComponent as RefreshIcon } from '../assets/images/ticket/restart.svg'
import { ReactComponent as StarIcon } from '../assets/images/svg/star.svg'
// import { ReactComponent as OkIcon } from '../assets/images/ticket/ok.svg'
import { Ticket } from "components/Ticket";
import { Trans } from "react-i18next";
import { config } from "config"
import cloneDeep from 'lodash.clonedeep'
import tools from "utils/tools";
import axios from "axios";



class TicketSelect extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        // this.isSmallScreen = window.innerHeight < 700;
        let tickets = [];
        tickets.push(tools.generateSingleTicket());


        this.state = {
            loading: true,
            fetching: false,
            isAcceptLoading: false,
            autoModeInfoDialog: session.showAutoMessage(),
            showTemplateDialog: false,
            isAuto: true,
            buyLoading: false,
            gameId: new URLSearchParams(this.props.location.search).get("gameId"),
            action: "",
            showEditDialog: false,
            ticketTemp: null,
            tickets,
            ticketCount: 1,
            editTicketError: null,
            maxTicket: 3,
            errorCode: 0,
            showMore: false,
            selectedIndex: 0,
            game: {
                status: "active",
                ticketPrice: 0,
                userCount: 0,
                money: 0,
                coins: {
                    amount: 0,
                    multiplier: 0
                }
            },
            templates: [],
            templateField: {
                value: "",
                error: "",
                valid: false,
                invalid: false
            }
        }

        this.loadErrors();


        //  this.onTicketRefresh = this.onTicketRefresh.bind(this);
        this.setStateWithMount = this.setStateWithMount.bind(this);
        this.onBuyTickets = this.onBuyTickets.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.removeTicket = this.removeTicket.bind(this);
        this.onTicketCountChange = this.onTicketCountChange.bind(this);
        this.onTicketCountBlur = this.onTicketCountBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.updateGameDetails = this.updateGameDetails.bind(this)
        this.countdown = this.countdown.bind(this);
        this.loadTemplates = this.loadTemplates.bind(this);
        this.saveTemplate = this.saveTemplate.bind(this);
        this.onAutoClick = this.onAutoClick.bind(this);
        this.onTicketCellChange = this.onTicketCellChange.bind(this);

        this.setNextGame = this.setNextGame.bind(this)
        this.renderAutoDialog = this.renderAutoDialog.bind(this);
        this.props.client.requestUserData();

    }


    onAutoClick = () => {
        let { tickets, isAuto } = this.state;
        isAuto = !isAuto;

        tickets = tickets.map((ticket) => {
            ticket.isAuto = isAuto;
            return ticket;
        })


        this.setStateWithMount({
            isAuto,
            tickets
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        // clear active timer
        clearInterval(this.timer)

        this.props.client.unregisterGameListener(this.state.gameId);
    }

    setNextGame() {
        let { game } = this.state;
        if (game.nextGameUUID) {
            let gameId = game.nextGameUUID
            this.props.client.unregisterGameListener(this.state.gameId);
            this.props.client.getGame(gameId);
            this.props.client.registerGameListener(gameId, this.updateGameDetails);
            this.props.history.replace({ pathname: '/ticket', search: `?gameId=${gameId}` })
            this.setStateWithMount({ gameId: gameId, errorCode: 0 });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        //this.props.client.socket.emit("test");
        this.props.client.getGame(this.state.gameId);
        this.props.client.registerGameListener(this.state.gameId, this.updateGameDetails);
    }

    setStateWithMount = (data, cb = null) => {
        if (this._isMounted) {
            if (cb !== null) {
                this.setState(data, cb);
            } else {
                this.setState(data);
            }
        }
    };


    loadTemplates = (index) => {
        const { user } = this.props;
        this.setStateWithMount({
            showTemplateDialog: true,
            action: "load",
            fetching: true,
            selectedIndex: index
        });

        axios.get(config.API_URL + "/templates",
            {
                headers: {
                    Authorization: user.token
                }
            }).then(result => {
                let data = result.data;
                if (data.status === "error") {
                    if (data.code === 403) {
                    } else {

                    }
                } else {
                    let templates = data.data;

                    this.setStateWithMount({ templates: templates, fetching: false });
                }

            }).catch(error => {
                // console.log(error);
                this.setStateWithMount({
                    fetching: false
                })
            });
    }

    saveTemplate = (ticket) => {
        let numbers = tools.extractValuesFromTicketNumbers(ticket.numbers);

        this.setStateWithMount({
            isAcceptLoading: true
        })

        axios({
            method: "POST",
            url: config.API_URL + "/template",
            data: {
                name: this.state.templateField.value,
                numbers: numbers
            },
            headers: {
                Authorization: session.getUser().token
            }
        }).then(result => {
            let data = result.data;
            if (data.status === "error") {
                // console.log("error", data)
            } else {
                this.setStateWithMount({
                    isAcceptLoading: false,
                    showTemplateDialog: false
                })
            }
        }).catch(error => {
            // console.log(error);
            this.setStateWithMount({
                isAcceptLoading: false
            })
        });

    }


    loadErrors = () => {
        this.errors = {
            500: this.props.t("error.general"),
            404: this.props.t("error.gameNotFound"),
            101: this.props.t("error.maxTicket"),
            102: this.props.t("error.lowBalance"),
            103: this.props.t("error.alreadyInGame"),
            104: this.props.t("game.startedMessage"),
            105: this.props.t("error.gameDisabled"),
            106: this.props.t("error.minTicket"),
            1001: this.props.t("error.invalidTicket")
        };
    };


    showErrorMessage = code => {
        this.setStateWithMount({
            errorCode: code,
            loading: false,
            buyLoading: false
        });
    };

    onBuyTickets = () => {
        let tickets = this.state.tickets;
        let game = this.state.game;
        this.setStateWithMount({ buyLoading: true })

        if (tickets.length === 0) {
            this.showErrorMessage(106)
        } else if ((game.ticketPrice * tickets.length) > session.getBalance()) {
            this.showErrorMessage(102);
        } else {
            if (tickets.every((ticket) => this.isTicketValid(ticket))) {
                this.props.client.buy(game.uuid, tickets, (err, data) => {
                    this.setStateWithMount({ buyLoading: false })
                    if (err) {
                        this.showErrorMessage(err.code);
                    }
                })
            } else {
                this.showErrorMessage(1001)

            }
        }
        //this.props.history.push("/game");
    }

    countdown = () => {
        const { game } = this.state;
        game.remaining--;
        this.setStateWithMount({
            game
        })
    }


    validateNumber = (column, number) => {
        if (number < 1 || number > 90) {
            return false;
        }

        // find min and max value for each item
        let min = column === 0 ? 1 : column * 10;
        let max = column === 8 ? 90 : (column + 1) * 10 - 1;

        // if not in range then return false
        if (number < min || number > max) {
            return false;
        }
        return true;
    }

    isNumber = number => {
        return typeof number === "number";
    };

    validateRow = (x, ticket) => {
        const row = ticket.numbers[x];
        let rowCount = 0;
        for (let i = 0; i < row.length; i++) {
            if (row[i].isEmpty === false && this.isNumber(row[i].value)) {
                rowCount++;
            }
        }

        return rowCount === 5
    }

    validateColumn = (y, numbers) => {
        let dupes = {};
        numbers.forEach((item, index) => {
            const value = item[y].value
            if (this.validateNumber(y, value) || !value) {
                delete numbers[index][y].isInvalid;
            } else {
                numbers[index][y].isInvalid = true;
            }

            if (value) {
                if (value in dupes) {
                    numbers[index][y].isInvalid = true
                    numbers[dupes[value]][y].isInvalid = true
                }
                dupes[value] = index
            }
        });

        return numbers;
    }


    getTicketError = (ticket) => {
        let validation = {
            rowError: false,
            cellError: false
        }


        if (ticket.rowErrors && ticket.rowErrors.length > 0) {
            validation.rowError = true;
        }

        const { numbers } = ticket;

        for (let rowIndex = 0; rowIndex < numbers.length; rowIndex++) {
            const column = numbers[rowIndex];
            for (let columnIndex = 0; columnIndex < column.length; columnIndex++) {
                const cell = column[columnIndex];

                if (cell.isInvalid) {
                    validation.cellError = true;
                    break;
                }
            }
        }

        // console.log(validation)

        return validation;
    }

    isTicketValid = (ticket) => {
        if (ticket.rowErrors && ticket.rowErrors.length > 0) {
            return false;
        }

        const { numbers } = ticket;

        for (let rowIndex = 0; rowIndex < numbers.length; rowIndex++) {
            const column = numbers[rowIndex];
            for (let columnIndex = 0; columnIndex < column.length; columnIndex++) {
                const cell = column[columnIndex];

                if (cell.isInvalid) {
                    return false;
                }
            }
        }

        return true;
    }

    onTicketCellChange(x, y, number) {
        const { ticketTemp } = this.state;

        ticketTemp.numbers[x][y].value = number;
        ticketTemp.numbers[x][y].isEmpty = !this.isNumber(number);

        ticketTemp.numbers = this.validateColumn(y, ticketTemp.numbers);

        const rowValid = this.validateRow(x, ticketTemp);
        const { rowErrors = [] } = ticketTemp
        if (!rowValid) {
            if (!rowErrors.includes(x)) {
                rowErrors.push(x)
            }
            ticketTemp.rowErrors = rowErrors
        } else {
            if (rowErrors.includes(x)) {
                rowErrors.splice(rowErrors.indexOf(x), 1)
            }

            if (rowErrors.length === 0) {
                delete ticketTemp.rowErrors;
            }
        }

        this.setStateWithMount({ ticketTemp })
    }


    updateGameDetails = (game) => {

        // console.log("gameDetails", game)
        clearInterval(this.timer);

        if (game === null) {
            this.showErrorMessage(404);
        } else {


            if (game.status === "starting") {
                this.timer = setInterval(this.countdown, 1000);
            } else if (game.status === "started") {
                this.showErrorMessage(104)
            }

            // console.log(game)
            this.setStateWithMount({
                game: game,
                loading: false,
            })
        }
    }


    onAddClick = () => {
        let tickets = this.state.tickets;
        let ticketCount = this.state.ticketCount;

        if (tickets.length < this.state.maxTicket || this.state.maxTicket === 0) {
            let ticket = tools.generateSingleTicket();
            ticket.isAuto = this.state.isAuto;
            tickets.push(ticket);
            ticketCount++;
        }


        this.setStateWithMount({
            tickets: tickets,
            ticketCount: ticketCount
        })
    }

    handleFocus = (event) => event.target.select();

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            let tickets = this.state.tickets;
            let count = event.target.value;

            if (count <= this.state.maxTicket) {
                if (tickets.length < count) {
                    let newTickets = tools.generateTickets(count - tickets.length);
                    tickets = tickets.concat(newTickets);
                } else {
                    tickets.splice(count - tickets.length, tickets.length - count);
                }

                this.setStateWithMount({
                    tickets: tickets,
                })
            }
        }
    }

    onTicketCountChange = (event) => {

        if (event.target.value.length <= 2) {
            this.setStateWithMount({
                ticketCount: event.target.value
            })
        }
    }

    onTicketCountBlur = () => {
        if (this.state.tickets.length !== parseInt(this.state.ticketCount)) {
            this.setStateWithMount({
                ticketCount: this.state.tickets.length
            })
        }
    }

    onRemoveClick = () => {
        this.removeTicket();
    }

    deleteTicketIndex = (index) => {
        this.removeTicket(index);
    }

    removeTicket = (index = null) => {
        let tickets = this.state.tickets;
        let ticketCount = this.state.ticketCount;

        if (tickets.length > 0) {
            tickets.splice(index === null ? -1 : index, 1)
            ticketCount--;
        }
        this.setStateWithMount({
            tickets: tickets,
            ticketCount: ticketCount
        })
    }

    onMoreClicked = (index) => {
        let tickets = this.state.tickets;
        if (index !== this.state.selectedIndex) {
            tickets[this.state.selectedIndex].selected = false;
        }
        tickets[index].selected = !tickets[index].selected;
        this.setStateWithMount({
            selectedIndex: index,
            tickets: tickets
        })
    }

    onTicketClear = () => {
        let { ticketTemp } = this.state;
        ticketTemp.numbers = ticketTemp.numbers.map((row) => {

            return row.map((cell) => {
                cell.isEmpty = true;
                cell.value = null
                return cell;
            })
        })
        ticketTemp.rowErrors = [...Array(ticketTemp.numbers.length).keys()];


        this.setStateWithMount({
            ticketTemp
        });
    }

    onTicketRefresh = (index) => {
        let { tickets, isAuto } = this.state
        let ticket = tools.generateSingleTicket();
        ticket.isAuto = isAuto;
        if (tickets[index].editable) {
            ticket.editable = true;
        }
        tickets[index] = ticket;
        this.setStateWithMount({
            tickets
        });
    }

    onTicketRandomize = () => {
        let { ticketTemp, isAuto } = this.state
        let ticket = tools.generateSingleTicket();
        ticket.isAuto = isAuto;
        ticket.editable = true;
        ticketTemp = ticket;
        this.setStateWithMount({
            ticketTemp
        });
    }

    onTicketEdit = (index) => {
        const ticketTemp = cloneDeep(this.state.tickets[index]);

        this.setStateWithMount({
            showEditDialog: true,
            ticketTemp,
            selectedIndex: index,
        })
    }

    renderTickets = tickets => {
        const { t } = this.props;
        return tickets.map((datarow, index) => {
            return (
                <div style={{ borderRadius: 9, padding: 5, background: "white", marginBottom: 10 }} key={index} >
                    <div style={{ display: "flex", flexDirection: "row", padding: 6, justifyContent: "flex-start", alignItems: "center", paddingRight: 5 }}>
                        <ButtonWithSVG text={t("ticket.refresh")} className="d-flex align-items-center justify-content-center" onClick={this.onTicketRefresh.bind(this, index)} >
                            <RefreshIcon fill='white' width={13} height={13} style={{ marginRight: 4 }} />
                        </ButtonWithSVG>
                        <ButtonWithSVG text={t("ticket.delete")} onClick={this.deleteTicketIndex.bind(this, index)} >
                            <TrashIcon fill='white' width={13} height={13} style={{ marginRight: 4 }} />
                        </ButtonWithSVG>
                        {/* {datarow.editable &&
                            <ButtonWithSVG onClick={this.onTicketEdit.bind(this, index)} className={`btn-green`}><OkIcon fill='white' style={{ padding: 4 }} /></ButtonWithSVG>
                        } */}
                        <ButtonWithSVG text={t('ticket.edit')} onClick={this.onTicketEdit.bind(this, index)}><EditIcon fill='white' width={13} height={13} style={{ marginRight: 4 }} /></ButtonWithSVG>

                        <Dropdown isOpen={datarow.selected} toggle={this.onMoreClicked.bind(this, index)} style={{ marginLeft: "auto" }} direction="down">
                            <DropdownToggle className="dropdown-more">
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => {
                                    this.loadTemplates(index);
                                }}><BookmarkFilledIcon />{t('ticket.loadFromTemplate')}</DropdownItem>
                                <DropdownItem onClick={() => {
                                    this.setStateWithMount({
                                        showTemplateDialog: true,
                                        action: "save",
                                        selectedIndex: index
                                    });
                                }}><BookmarkIcon />{t('ticket.saveAsTemplate')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className='d-flex flex-row'>
                        <div style={{ padding: 3, flexGrow: 1 }}>
                            <Ticket
                                key={datarow.id}
                                index={datarow.index}
                                isGame={false}
                                ticketData={datarow.numbers}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    };

    renderTemplateDialog = () => {
        const { showTemplateDialog, action } = this.state;
        const { t } = this.props

        let content, title, acceptButton, onAcceptClick, onCancelClick, cancelText;

        // set default text for cancel button
        cancelText = t("dialog.close");

        // set default behaviour for cancel button
        onCancelClick = () => {
            this.setStateWithMount({
                showTemplateDialog: false
            })
        }

        if (action === "save") {
            title = t('dialog.save');
            acceptButton = t('dialog.save');

            // find selected ticket
            let ticket = this.state.tickets[this.state.selectedIndex];

            onAcceptClick = () => {
                this.saveTemplate(ticket);
            }

            content = <div>
                <FormGroup >
                    <Label>{t('ticket.templateName')}</Label>
                    <Input
                        type="username"
                        value={this.state.templateField.name}
                        onChange={(event) => {
                            let value = event.target.value;
                            let templateField = this.state.templateField;
                            templateField.value = value;

                            this.setStateWithMount({
                                templateField
                            })
                        }}
                        placeholder={t("ticket.templateName")}
                    />
                </FormGroup>
                <FormGroup style={{ marginBottom: 20 }}>
                    <Label>{t('ticket.ticket')}</Label>
                    <Ticket
                        //size="small"
                        key={ticket.id}
                        index={ticket.index}
                        isGame={false}
                        editable={false}
                        ticketData={ticket.numbers}
                    />
                </FormGroup>

            </div>

        } else if (action === "load") {
            title = t('ticket.selectTemplate');
            acceptButton = "";

            if (this.state.templates.length > 0) {
                content =
                    this.state.templates.map((template, index) => {
                        let numbers = tools.convertToTicket(template.numbers);
                        return (
                            <div key={"container" + index}>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: 2, justifyContent: "space-between", alignItems: "flex-end" }}>
                                    <span style={{ fontWeight: "400" }}>{template.name}</span>
                                    <span style={{ fontSize: 11 }}>{template.created_date}</span>
                                </div>
                                <div>
                                    <Ticket
                                        key={index}
                                        // size="small"
                                        isGame={false}
                                        editable={false}
                                        clickable={true}
                                        onTicketClick={() => {
                                            let tickets = this.state.tickets;
                                            // tickets[this.state.selectedIndex].numbers = tools.convertToTicket(JSON.stringify(this.state.templates[index].numbers))
                                            tickets[this.state.selectedIndex].numbers = numbers;
                                            this.setStateWithMount({
                                                tickets,
                                                showTemplateDialog: false
                                            })
                                        }}
                                        ticketData={numbers}
                                    />
                                    <div style={{ width: 300 }}></div>
                                </div>
                                <div className="divider" style={{ marginBottom: 10 }} />
                            </div>
                        )
                    });
            }
            else {
                content = <div className="empty">
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.15 14.05C32.75 13.55 32.1 13.35 31.5 13.6L7.5 22.1C7.15 22.2 6.9 22.45 6.7 22.75L0.699999 32.75C0.349999 33.3 0.399999 34 0.799999 34.45C1.15 34.8 1.55 35 2 35C2.15 35 2.35 34.95 2.5 34.9L26.5 26.4C26.85 26.3 27.1 26.05 27.3 25.75L33.3 15.75C33.6 15.25 33.55 14.55 33.15 14.05Z" fill="#3C1E57" fillOpacity="0.88" />
                        <path d="M56 23.5L32 15L8 23.5V53.5L32 62L56 53.5V23.5Z" fill="white" />
                        <path d="M56 23.5L32 15L8 23.5V53.5L32 62L56 53.5V23.5ZM56 23.5L32 15Z" fill="white" />
                        <path d="M32 63.5C31.85 63.5 31.65 63.45 31.5 63.4L7.5 54.9C6.9 54.7 6.5 54.1 6.5 53.5V23.5C6.5 22.85 6.9 22.3 7.5 22.1L31.5 13.6C32.3 13.3 33.15 13.75 33.4 14.5C33.7 15.3 33.25 16.15 32.5 16.4L9.5 24.55V52.45L32.5 60.6C33.3 60.9 33.7 61.75 33.4 62.5C33.2 63.1 32.6 63.5 32 63.5Z" fill="#3C1E57" fillOpacity="0.88" />
                        <path d="M56 23.5L32 15L56 23.5ZM56 23.5L32 15L56 23.5ZM32 32L8 23.5L32 32ZM62 33.5L38 42L32 32L56 23.5L62 33.5Z" fill="white" />
                        <path d="M63.3 32.75L57.3 22.75L57.25 22.7C57.25 22.65 57.2 22.65 57.2 22.6C57.2 22.55 57.15 22.55 57.15 22.5C57.15 22.5 57.15 22.5 57.15 22.45C57.1 22.4 57.1 22.4 57.05 22.35L57 22.3L56.95 22.25H56.9L56.85 22.2C56.8 22.15 56.75 22.15 56.7 22.15C56.65 22.15 56.65 22.1 56.6 22.1L32.6 13.6C32.5 13.55 32.4 13.55 32.3 13.55H32.25C32.15 13.55 32.05 13.55 32 13.55C31.95 13.55 31.95 13.55 31.9 13.55C31.8 13.55 31.7 13.6 31.6 13.6L7.60001 22.1C7.00001 22.3 6.60001 22.9 6.60001 23.5V53.5C6.60001 54.15 7.00001 54.7 7.60001 54.9L31.6 63.4C31.65 63.4 31.7 63.45 31.75 63.45C31.8 63.45 31.8 63.45 31.85 63.45C31.9 63.45 31.95 63.45 32.05 63.45H32.1C32.2 63.45 32.25 63.45 32.35 63.45H32.4C32.45 63.45 32.5 63.4 32.55 63.4L56.55 54.9C57.15 54.7 57.55 54.1 57.55 53.5V36.7L62.55 34.95C62.95 34.8 63.3 34.45 63.45 34.05C63.6 33.65 63.5 33.1 63.3 32.75ZM51.5 23.5L33.5 29.9V17.1L51.5 23.5ZM9.50001 25.6L30.5 33.05V59.85L9.50001 52.4V25.6ZM54.5 52.45L33.5 59.9V37.4L36.7 42.75C37 43.25 37.5 43.5 38 43.5C38.15 43.5 38.35 43.45 38.5 43.4L54.5 37.75V52.45ZM38.65 40.2L34.2 32.8L55.3 25.3L59.75 32.7L38.65 40.2Z" fill="#3C1E57" fillOpacity="0.88" />
                    </svg>
                    <span>{this.props.t("ticketSelect.emptyTemplate")}</span>
                </div>
            }
            content = <div className="modal-body-list overflow-auto">{content}</div>
        }


        return (
            <Dialog
                t={t}
                isOpen={showTemplateDialog}
                toggle={onCancelClick}
                cancelText={cancelText}
                onCancelClick={onCancelClick}
                isLoading={this.state.fetching}
                isAcceptLoading={this.state.isAcceptLoading}
                onClose={() => {
                    this.setStateWithMount({ action: "", showTemplateDialog: false })
                }}
                acceptText={acceptButton}
                onAcceptClick={onAcceptClick}
                title={title}
            >
                {content}
            </Dialog>
        )
    }



    renderErrorDialog = () => {
        const { errorCode } = this.state;
        const { t, history, isAcceptLoading } = this.props

        let acceptButton, onAcceptClick, onCancelClick;

        // set default behaviour for cancel button
        onCancelClick = () => {
            this.setStateWithMount({
                errorCode: 0
            })
        }

        acceptButton = "";

        if (errorCode === 104) {
            onCancelClick = () => {
                history.push("/");
            }
            onAcceptClick = () => {
                this.setNextGame();
            }
            acceptButton = t('dialog.accept');
        } else if (this.state.errorCode === 404) {
            onCancelClick = () => {
                history.push("/");
            }
        }


        return (
            <Dialog
                t={t}
                isOpen={errorCode !== 0}
                toggle={onCancelClick}
                cancelText={t("dialog.close")}
                onCancelClick={onCancelClick}
                isLoading={this.state.fetching}
                isAcceptLoading={isAcceptLoading}
                onClose={() => {
                    this.setStateWithMount({ errorCode: 0 })
                }}
                acceptText={acceptButton}
                onAcceptClick={onAcceptClick}
                title={t("dialog.error")}
            >
                {this.errors[errorCode]}
            </Dialog>
        )
    }

    renderEditTicketDialog = () => {
        const { ticketTemp, showEditDialog, tickets, selectedIndex } = this.state
        const { t } = this.props;

        let ticketErrors = {};
        let isTicketValid = true;
        if (ticketTemp) {
            ticketErrors = this.getTicketError(ticketTemp);
            isTicketValid = !ticketErrors.rowError && !ticketErrors.cellError;
        }


        const onCancel = () => {
            this.setStateWithMount({ showEditDialog: false })
        }



        return (
            <Dialog
                t={this.props.t}
                isOpen={showEditDialog}
                className={"modal-ticket-edit"}
                cancelText={t('dialog.cancel')}
                onCancelClick={onCancel}
                isLoading={this.state.fetching}
                isAcceptLoading={this.state.isAcceptLoading}
                onClose={onCancel}
                acceptText={t('dialog.save')}
                acceptDisabled={!isTicketValid}
                onAcceptClick={() => {
                    if (isTicketValid) {
                        tickets[selectedIndex] = cloneDeep(ticketTemp);
                        this.setStateWithMount({ tickets, showEditDialog: false })
                    }
                }}
                title={t('ticket.edit')}
            >
                {ticketTemp &&
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", padding: 6, justifyContent: "flex-start", alignItems: "center", paddingLeft: 0, marginBottom: 5 }}>
                            <ButtonWithSVG text={t("ticket.refresh")} className="d-flex align-items-center justify-content-center" onClick={this.onTicketRandomize.bind(this)} >
                                <RefreshIcon fill='white' width={13} height={13} style={{ marginRight: 4 }} />
                            </ButtonWithSVG>
                            <ButtonWithSVG onClick={this.onTicketClear.bind(this)} text={t('ticket.clear')}> <ClearIcon fill='white' width={13} height={13} style={{ marginRight: 4 }} /></ButtonWithSVG>
                        </div>
                        <Ticket
                            key={ticketTemp.id}
                            isGame={false}
                            editable={true}
                            onCellChange={(x, y, number) => { this.onTicketCellChange(x, y, number) }}
                            rowErrors={ticketTemp.rowErrors}
                            ticketData={ticketTemp.numbers}
                        />
                        <ul className='ticket-errors'>
                            {ticketErrors.cellError &&
                                <li>
                                    <Trans i18nKey="ticket.cellError">
                                        Each row must have only 5 numbers. The row has highlighted with yellow color.
                                    </Trans>
                                </li>
                            }

                            {ticketErrors.rowError &&
                                <li>
                                    <Trans i18nKey="ticket.rowError">
                                        Each row must have only 5 numbers. The row has highlighted with yellow color.
                                    </Trans>
                                </li>
                            }
                        </ul>
                    </div>
                }
            </Dialog>
        )
    }

    renderAutoDialog = () => {
        const { autoModeInfoDialog, fetching, isAcceptLoading } = this.state
        const { t } = this.props;
        return (
            <Dialog
                t={t}
                isOpen={autoModeInfoDialog}
                isLoading={fetching}
                isAcceptLoading={isAcceptLoading}
                acceptText={this.props.t("dialog.close")}
                onAcceptClick={() => {
                    session.hideAutoMessage();
                    this.setStateWithMount({ autoModeInfoDialog: !autoModeInfoDialog })
                }}
                title={this.props.t("dialog.news")}
            >
                <Trans i18nKey="game.autoModeInformation">
                    Artıq siz biletlərinizi avtomatik rejime qoyaraq rahat işarəliya bilərsiniz.
                    <br />Avto rejimi
                    aktiv elemek üçün
                    <ButtonWithSVG
                        text={this.props.t("game.auto")}
                        style={{ margin: 3 }}
                        className={"auto off"}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="8.222" height="13.54" viewBox="0 0 8.222 13.54">
                            <path id="Path_16093" data-name="Path 16093" d="M2921.6,5484.7l-3.832,6.875h3.832l-2.234,6.257,5.217-8.239h-3.453Z" transform="translate(-2917.087 -5484.506)" strokeWidth="0.8" />
                        </svg>
                    </ButtonWithSVG>
                    düyməsinə basın.
                    <br />
                    Avto aktiv olan zaman sizdə
                    <ButtonWithSVG
                        text={this.props.t("game.auto")}
                        style={{ margin: 3 }}
                        className={"auto on"}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="8.222" height="13.54" viewBox="0 0 8.222 13.54">
                            <path id="Path_16093" data-name="Path 16093" d="M2921.6,5484.7l-3.832,6.875h3.832l-2.234,6.257,5.217-8.239h-3.453Z" transform="translate(-2917.087 -5484.506)" strokeWidth="0.8" />
                        </svg>
                    </ButtonWithSVG>
                    görsənəcək.

                    <br />
                    Oyuna girdikdən sonra da avto rejimi yanıdırıb söndürmək mümkündür.
                    <br />
                    <span style={{ fontWeight: "bold", color: "red" }}>
                        Avto rejim yalnız aktiv olandan sonra çıxan nömrələri işarələyir.
                        Əgər nömrə artıq çıxıbsa o zaman avtomatik rejimi söndürüb işarələyin və sonra yenidən aktiv edin.
                    </span>
                </Trans>

            </Dialog>)
    }

    render = () => {
        return (
            <div className="d-flex flex-fill flex-column flex-nowrap">
                {this.renderTemplateDialog()}
                {this.renderAutoDialog()}
                {this.renderEditTicketDialog()}
                {this.renderErrorDialog()}
                <Header
                    auto={this.state.isAuto ? "on" : "off"}
                    players={this.state.game.userCount}
                    prize={this.state.game.money.toFixed(0)}
                    //   smallHeader={this.isSmallScreen}
                    onAutoClick={this.onAutoClick}
                    t={this.props.t}
                    showGameInfo={true}
                >
                    {/* <div className="header-bottom-extra">
                        <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                            <span style={{ fontWeight: "bold" }}>{this.props.t("ticket.ticketCount")}</span>
                            <Input
                                className="input-ticket-count"
                                type="number"
                                value={this.state.ticketCount}
                                onChange={this.onTicketCountChange}
                                onBlur={this.onTicketCountBlur}
                                onFocus={this.handleFocus}
                                onKeyDown={this.handleKeyDown}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingRight: 5 }}>
                            <button className="circle minus-button" style={{ marginRight: 10 }} onClick={this.onRemoveClick}></button>
                            <button className="circle plus-button" onClick={this.onAddClick}></button>
                        </div>
                    </div> */}
                </Header>
                <Body mainStyle={{ marginTop: -5 }} loading={this.state.loading || this.props.isChatLoading} cardStyle={{ overflow: "auto", paddingTop: 14 }}>
                    {this.renderTickets(this.state.tickets)}
                </Body>
                <div className="footer">
                    <div className="container" style={{ display: "flex", flexDirection: "row", height: "100%", paddingTop: 12, paddingBottom: 0, paddingLeft: 11, paddingRight: 11, alignItems: "center", justifyContent: "space-between" }}>



                        <div style={{ position: "relative", marginRight: 15, width: '100%' }}>
                            {this.state.game.status === "starting" && <span className="clock-label" style={{ right: 5 }}>{tools.secondsToClock(this.state.game.remaining)}</span>}
                            <LoadingButton
                                // style={{ width: "100%", height: 'auto', paddingRight: 40, paddingLeft: 40 }}
                                className='btn-buy'
                                onClick={this.onBuyTickets}
                                isLoading={this.state.buyLoading}
                            >
                                <div className='d-flex flex-column'>
                                    <span>{this.props.t('ticket.buyNow').toUpperCase()} <span >{(this.state.game.ticketPrice * this.state.tickets.length).toFixed(0)}</span><Manat /></span>
                                    {this.state.game.coins &&
                                        <span>{this.props.t('ticket.youWillGet')} <StarIcon width={23} height={23} style={{ marginLeft: 5, marginRight: 2 }} /> {this.state.game.coins.amount * this.state.tickets.length * this.state.game.coins.multiplier} </span>
                                    }
                                </div>
                            </LoadingButton>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingRight: 5 }}>
                            <button className="circle minus-button" style={{ marginRight: 12 }} onClick={this.onRemoveClick}></button>
                            <button className="circle plus-button" onClick={this.onAddClick}></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withContext(TicketSelect);

// <div className='d-flex'>

// <div className="total-price">
//     <span className="label">{this.props.t("ticket.totalPrice")}</span>
//     <span className="amount">{(this.state.game.ticketPrice * this.state.tickets.length).toFixed(2)} <Manat height="13" width="20" /></span>

// </div>

// {this.state.game.coins &&
//     <div className='coin-container' style={{ background: 'none' }}>
//         <div className='game-star'>
//             {this.state.game.coins.amount * this.state.tickets.length} <StarIcon height={30} width={30} />
//         </div>
//         <div style={{ marginTop: -9 }}>qazan</div>
//         {/* {this.state.game.coins !== 1 &&

//                 <span className='multiplier'>x{game.coins.multiplier}</span>} */}
//     </div>
// }
// </div>

