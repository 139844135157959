import React, { Component } from 'react'

/*
export default class Manat extends Component {
    render() {
        const { color = "currentColor", height = "12", width = "25", ...otherProps } = this.props;
        return (
            <svg width={width} height={height} viewBox="0 0 25 20" fill={color} xmlns="http://www.w3.org/2000/svg" {...otherProps} >
                <g clipPath="url(#clip0)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.1229 18.1216L11.6681 0.772227L13.4674 0.0514832L13.6855 16.7316L11.1229 18.1216Z" fill={color} stroke={color} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 19.1512L2.50811 19.2542C2.5565 7.68118 9.13691 3.86528 12.377 3.75816C21.1319 4.02204 22.1354 14.5912 22.4639 19.2542L24.972 19.3571C24.7833 8.18587 18.2989 2.05724 12.9767 2.16223C3.90178 2.46425 0.445586 11.2101 0 19.1512Z" fill={color} stroke={color} />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="25" height="19.4086" fill={color} />
                    </clipPath>
                </defs>
            </svg>
        )
    }
}
*/

export default class Manat extends Component {
    render() {
        const { color = "currentColor", height = "12", width = "25", ...otherProps } = this.props;
        return (
            <svg width={width} height={height} viewBox="0 0 25 20" fill={color} xmlns="http://www.w3.org/2000/svg" {...otherProps} >
                <g clipPath="url(#clip0)">
                    <path fillRule="evenodd" clipRule="evenodd" d="m19.70738,8.29021c0,1.28391 -0.25313,1.91915 -0.76986,1.91915l-6.35342,0l0,13.31887c0,0.28979 -0.21966,0.48554 -0.67572,0.59685a8.20275,7.52497 0 0 1 -1.90791,0.15929a7.39314,6.78226 0 0 1 -1.9351,-0.17272c-0.43514,-0.10939 -0.6548,-0.30706 -0.6548,-0.57574l0,-13.31695l-6.35342,0c-0.51463,0 -0.76986,-0.64483 -0.76986,-1.91915a5.10031,4.67888 0 0 1 0.18828,-1.47966c0.12552,-0.30706 0.32008,-0.46059 0.58158,-0.46059l17.88036,0c0.27405,0 0.47488,0.15353 0.58995,0.46635a5.25511,4.82089 0 0 1 0.17991,1.4739l0,-0.0096zm-18.65021,-3.70971c-0.51463,0 -0.76986,-0.64483 -0.76986,-1.92874a5.02081,4.60595 0 0 1 0.18828,-1.47966c0.12343,-0.30706 0.32008,-0.46059 0.58158,-0.46059l17.88036,0c0.27405,0 0.47279,0.15545 0.59622,0.46635a5.33252,4.8919 0 0 1 0.17991,1.4739c0,1.28391 -0.25522,1.92874 -0.76986,1.92874l-17.88663,0z" fill={color} stroke={color} />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="25" height="19.4086" fill={color} />
                    </clipPath>
                </defs>
            </svg>
        )
    }
}
