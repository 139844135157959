import React, { Component } from 'react';
import { withContext, session } from 'services';
import { LiveChat } from "components/Views";
import { Spinner } from 'reactstrap';
import { Portal } from 'react-portal';


class Chat extends Component {
  customerSDK = null

  constructor(props) {
    super(props);

    this.state = {
      isChatLoading: true
    }
  }

  onVisibilityChanged(data) {
    if (data.visibility === "hidden") {
      console.log(this.props)
    }
  }

  onReady = (data) => {
    this.setState({
      isChatLoading: false
    })
    window.LiveChatWidget.call("maximize")
    // window.LiveChatWidget.on("new_event", (data) => {
    //   console.log(data)
    // })

    window.LiveChatWidget.on('visibility_changed', (data) => {
      if (data.visibility === "hidden") {
        this.props.history.goBack();
      }
    })

  }

  componentDidMount = () => {
    if (window.LiveChatWidget) {
      window.LiveChatWidget.on('ready', this.onReady)
    }
  }

  render = () => {
    const { isChatLoading } = this.state;

    return (
      <div className="w-100 h-100 d-flex flex-fill flex-column pt-3 bg-white">
        {isChatLoading &&
          <Portal node={document && document.getElementById('root')}>
            <div className="loading-container" id="loader">

              <div style={{ position: "absolute", left: "50%", top: "50%", marginLeft: -45, marginTop: -45 }}>
                <div className="spinner-container" style={{ top: "unset", left: "unset", marginLeft: 0, marginTop: 0, position: "initial", marginBottom: 10, background: "#713198" }}>
                  <Spinner />
                </div>
                <span style={{ marginLeft: -5 }}>{this.props.t("wallet.chatLoading")}</span>
              </div>
            </div>

          </Portal>
        }
        <LiveChat
          user={session.getUser()}
        />
      </div>
    )
  }


}

export default withContext(Chat);