import React, { Component } from 'react';
import withContext from 'services/withContext';
import { ContainerView } from 'components/Views'

class Example extends Component {
    render = () => {
        return (
            <ContainerView>
                <div>
                    <span>Hello {this.props.t("home.gameStarted")}</span>
                </div>
            </ContainerView>

        )
    }


    componentDidMount() {
        this.props.client.socket.emit("test");
    }
}

export default withContext(Example);