import React, { Component } from "react";
import { Ticket } from "components/Ticket";
import tools from "utils/tools";
import sounds from "config/sounds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Button } from "reactstrap"
import { library } from "@fortawesome/fontawesome-svg-core";
import { Header, Body } from 'components/Views'
import { Dialog, Manat } from "components/Views";
import { Trans } from "react-i18next";
import Bounty from 'react-bounty';
import { withContext } from 'services';
import {
  faUsers,
  faMoneyBillAlt,
  faClock,
  faSortNumericUp,
  faMedal
} from "@fortawesome/free-solid-svg-icons";

class Test extends Component {
  _isMounted = false;
  user = JSON.parse(localStorage.getItem("user"));
  audio = new Audio(sounds.ball);


  constructor(props) {
    // Add fontawesone images to library
    library.add(faMoneyBillAlt, faUsers, faClock, faMedal, faSortNumericUp);
    super(props);
    this.state = {
      infoModal: false,
      clickCount: 0,
      showJackpot: false,
      showJackpotResult: false,
      jackpotCounter: 5,
      winners: null,
      game: {
        jackpot: { isInRange: true }
      },
      info: {
        status: "active",
        users: 0,
        money: 0
      },
      jackpotWinners: [],
      selectedNumbers: [],
      number: '000',
      stat: {
        marked: 2,
        waiting_players: 125
      },
      clock: Math.random() * 1000,
      tickets: tools.generateTickets(3)
    };

    this.isSmallScreen = window.innerHeight < 700;

    this.setStateWithMount = this.setStateWithMount.bind(this);
    this.sendfake = this.sendfake.bind(this);
    this.selectNumber = this.selectNumber.bind(this);
    this.jackpotWinnerCounter = this.jackpotWinnerCounter.bind(this);
    this.jackpotCounter = this.jackpotCounter.bind(this);
    this.showJackpotResult = this.showJackpotResult.bind(this);
  }


  showJackpotResult = () => {
    clearInterval(this.jackpotDrawTimer)
    this.setStateWithMount({
      showJackpotResult: true
    })
  }

  sendfake = (callback) => {

  }



  renderJackpot = () => {
    const { showJackpot, winners, showJackpotResult } = this.state;
    const { t } = this.props;

    let content, result, message, title, className;

    let hasAnimation = false;

    if (winners !== null) {

      let prize = '000';


      if (winners.jackpot) {
        // create lsit view from tickets
        const ticket = winners.jackpot.ticket;
        hasAnimation = true
        prize = winners.jackpot.prize;

        if (ticket.user.userId === 21) {
          message = (
            <div className="jackpot-winner-message" style={{ height: showJackpotResult ? "auto" : 0 }}>
              <span style={{ fontSize: 25, color: "#dc3545" }} role="img" aria-label="won">{t('game.congratulations')}! 🤩</span>
              <span className="jackpot-winner">{t('game.youWonJackpot')}</span>
            </div>
          )
        } else {
          const jackpotWinnerUsername = ticket.user.username;
          message = (
            <div className="jackpot-winner-message" style={{ height: showJackpotResult ? "auto" : 0 }}>
              <span style={{ fontSize: 25, color: "#dc3545" }} role="img" aria-label="won">{t('game.jackpotWon')}! 🤩</span>
              <span><Trans i18nKey={'game.othersWonJackpot'}>Qızıl kartın sahibi <span className="jackpot-winner">{{ jackpotWinnerUsername }}</span> oldu.</Trans></span>
            </div >
          )
        }

        result =
          (
            <div className="px-2">
              <div className="py-2">
                {message}
              </div>
              <div className="winner-user-info">
                <span className="winner-name">{ticket.user.name}</span>
              </div>
              <Ticket
                key={ticket.id}
                size="small jackpot-winner"
                isGame={false}
                editable={false}
                ticketData={ticket.numbers}
              />
            </div>
          )

      } else {
        result = (
          <div className="px-2">
            <div className="jackpot-winner-message" style={{ height: showJackpotResult ? "auto" : 0 }}>
              <span style={{ fontSize: 25, color: "#dc3545" }} role={"img"} aria-label="sad">{t('game.jackpotNotWon')} 😞</span>
              <span style={{textAlign:"center"}}>{t('game.jackpotNotWonMessage')}</span>
            </div>
          </div>
        )
      }

      if (!showJackpotResult) {
        hasAnimation = false;
      }

      content = (
        <div className="expand-container">
          <div className={`expand-content ${showJackpotResult ? 'expanded' : ''}`}>

            {result}
            <div className="modal-footer" style={{ marginTop: 19, padding: 1 }}>

              <Button className="btn-cancel">{t('dialog.close')}</Button>
              <Button className="btn-accept">{t('dialog.joinAgain')}</Button>

            </div>
          </div>
        </div>
      )

      const prizeToShow = parseInt(prize).toString().padStart(3, '0').split('')
      // content = null;

      return (
        <Dialog
          t={this.props.t}
          className={className}
          title={title}
          hideFooter={true}

          // cancelText="asfasf"
          // acceptText="asfaf"
          isOpen={showJackpot}
        >
          <div >
            <div className="d-flex w-100 align-items-center justify-content-center flex-column" style={{ marginBottom: -20 }}>
              <div className={`bouncing-text ${hasAnimation ? '' : 'no-animation'}`}>
                <div className="b">J</div>
                <div className="o">A</div>
                <div className="u">C</div>
                <div className="n">K</div>
                <div className="c">P</div>
                <div className="e">O</div>
                <div className="d">T</div>
              </div>
              <img src={`/jackpot/jackpotWinners.jpg`} alt='jackpot' style={{ height: 'auto' }} />
              <div className={`jackpot-sign-container ${hasAnimation ? '' : 'no-animation'}`}>

                <Bounty value={prizeToShow[0]} animationDelay={1000} initialValue={'0'} letterAnimationDelay={5000} duration={6000} className="bounty" />
                <Bounty value={prizeToShow[1]} animationDelay={1400} initialValue={'0'} letterAnimationDelay={5000} duration={7000} className="bounty" />
                <Bounty value={prizeToShow[2]} animationDelay={1800} initialValue={'0'} letterAnimationDelay={5000} duration={7500} className="bounty" />
              </div>

              {/* <span className="jackpot-winner-prize" >501</span> */}
            </div>
            <div className="px-0">
              {content}
            </div>
          </div>
        </Dialog>
      )
    }
  }


  renderJackpotWinners = () => {
    const { jackpotWinners } = this.state;
    const { t } = this.props;
    let title, className, content, isOpen = false;

    if (jackpotWinners.length > 0) {
      isOpen = true;

      const jackpotWinner = jackpotWinners[0];
      let { ticket, wait, prize, ticketPrice } = jackpotWinner

      ticketPrice = ticketPrice.toFixed(0)

      prize = parseInt(prize)

      // create lsit view from tickets
      const ticketsListView =
        // className = "confetti medal-background";
        (
          <div style={{ marginTop: 10 }}>
            <Ticket
              key={ticket.id}
              size="small jackpot-winner"
              isGame={false}
              editable={false}
              ticketData={ticket.numbers}
            />
          </div>
        )
      title = (
        <span className="countdown-timer">{wait}</span>
      )

      const jackpotWinnerUsername = ticket.user.username;

      content = (
        <div >
          <div className="d-flex w-100 align-items-center justify-content-center flex-column" style={{ marginBottom: -5 }}>
            <div className="bouncing-text">
              <div className="b">J</div>
              <div className="o">A</div>
              <div className="u">C</div>
              <div className="n">K</div>
              <div className="c">P</div>
              <div className="e">O</div>
              <div className="d">T</div>
            </div>
            <img src={`/jackpot/jackpotWinners.jpg`} alt='jackpot' style={{ height: 'auto' }} />
            <div className="jackpot-sign-container">
              {prize.toString().padStart(3, '0').split('').map((digit, index) => {
                return (<span key={index} className="jackpot-winner-prize">{digit}</span>)
              })}
            </div>
          </div>
          <span className="game-result-info">
          </span>
          <div className="px-3">
            <div className="jackpot-winner-message">
              <span style={{ fontSize: 25, color: "#dc3545" }} role="img" aria-label="won">{t('game.jackpotWon')}! 🤩</span>
              <span><Trans i18nKey={'game.jackpotWonByRoom'}><span style={{ fontWeight: 600, fontSize: 21 }}>{{ ticketPrice }} ₼ </span> otaqda qızıl kartın sahibi <span className="jackpot-winner">{{ jackpotWinnerUsername }}</span> oldu.</Trans></span>
            </div >
            {ticketsListView}
          </div>
        </div>
      )
    }

    return (
      <Dialog
        t={this.props.t}
        className={className}
        title={title}
        isOpen={isOpen}
      >
        {content}
      </Dialog>
    )
  }

  jackpotCounter = () => {
    let { jackpotCounter, showJackpot } = this.state;

    if (jackpotCounter === 0) {
      clearInterval(this.jackpotTimer)
      showJackpot = true;
      this.jackpotDrawTimer = setInterval(this.showJackpotResult, 9500)
    } else {
      jackpotCounter--;
    }

    this.setStateWithMount({ jackpotCounter, showJackpot })
  }

  jackpotWinnerCounter = () => {
    const { jackpotWinners } = this.state;
    // if there are any jackpot winners
    if (jackpotWinners.length > 0) {
      // if the first jackpot winner time equal to zero
      if (jackpotWinners[0].wait === 0) {
        // remove first element from the array
        if (jackpotWinners.length === 1) {
          clearInterval(this.jackpotWinnersCounter);
        }
        jackpotWinners.shift();
      } else {
        jackpotWinners[0].wait -= 1;
      }
      this.setState({ jackpotWinners })
    } else {
      clearInterval(this.jackpotWinnersCounter);
    }
  }

  selectNumber = () => {
    const rndInt = Math.floor(Math.random() * 90) + 1

    let { selectedNumbers } = this.state;

    selectedNumbers.unshift(rndInt);

    this.setState({ selectedNumbers })
  }

  setStateWithMount = (data, cb = null) => {
    if (this._isMounted) {
      if (cb !== null) {
        this.setState(data, cb);
      } else {
        this.setState(data);
      }
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {

    this._isMounted = true;
    this.timer = setInterval(this.selectNumber, 4000)

    this.jackpotTimer = setInterval(this.jackpotCounter, 1000);

    this.jackpotWinnersCounter = setInterval(this.jackpotWinnerCounter, 1000);

    this.setState({
      number: '999'
    })

    const ticket = tools.generateSingleTicket();
    const ticket2 = tools.generateSingleTicket();

    ticket.user = {
      userId: 21,
      name: "test",
      username: 'test'
    }
    ticket.prize = 100;

    ticket2.user = {
      name: "test",
      username: 'test'
    }
    ticket2.prize = 100;

    // const jackpotWinners = [{
    //   ticket,
    //   prize: 400.59,
    //   gameId: 1,
    //   ticketPrice: 0.20,
    //   wait: 5
    // },
    // {
    //   ticket: ticket2,
    //   prize: 90.24,
    //   gameId: 1,
    //   ticketPrice: 0.20,
    //   wait: 5
    // }];



    // const winners = {
    //   found: true,
    //   commission: 20,
    //   tickets: [ticket],
    //   jackpot:
    //     // false
    //   {
    //     ticket,
    //     prize: 90.20,
    //     gameId: 1,
    //     ticketPrice: 0.20,
    //     wait: 5
    //   }
    // }


    // this.setState({
    //   jackpotWinners,
    //   winners
    // })

  }


  checkWinner = tickets => {
    let result = {
      ticketsWon: 0,
      prizeWon: 0,
      singleWinner: false
    };
    tickets.forEach(ticket => {
      if (parseInt(ticket.user.userId) === parseInt(this.user.id)) {
        result.ticketsWon++;
        result.prizeWon += ticket.prize;
      }
    });

    if (result.ticketsWon === tickets.length) {
      result.singleWinner = true;
    }

    return result;
  };

  handleCellClick(index, x, y) {
    let tickets = this.state.tickets;
    let clickCount = this.state.clickCount;
    let winners = this.state.winners;

    const rand = Math.random() * 90;
    if (
      !tickets[index].numbers[x][y].isEmpty &&
      !tickets[index].numbers[x][y].isRevealed
    ) {
      if (tickets[index].numbers[x][y].value > rand) {
        // tickets[index].numbers[x][y].isRevealed = true;
        tickets[index].numbers[x][y].isPending = true;

        this.setState({
          tickets
        })


        clickCount++;
        if (clickCount > 5) {
          winners = {};
          winners.found = false;
          winners.commission = 20;
          winners.tickets = [
            {
              numbers: tickets[index].numbers,
              uuid: "124124124safafa1",
              prize: 23.7,
              user: {
                userId: this.user.id,
                name: "Ali Aliyev"
              }
            },
            {
              numbers: tickets[index].numbers,
              uuid: "124124124safafa1",
              prize: 23.7,
              user: {
                userId: this.user.id,
                name: "Ali Aliyev"
              }
            }
          ];
        }
      } else {
        tickets[index].numbers[x][y].isMissed = true;
      }
    }



    setTimeout(() => {

      if (tickets[index].numbers[x][y].isPending) {
        tickets[index].numbers[x][y].isRevealed = true;
      }

      this.setState({
        tickets: tickets,
        clickCount: clickCount,
        winners: winners
      });

    }, 1000)
  }

  renderKegs(numbers) {
    let num = numbers.slice(0, Math.max(5, 1));
    return new Array(5).fill(0).map((current, index) => {
      let mainClass = index === 0 ? "keg-hole main" : "keg-hole";
      if (num[index] !== void 0) {
        //console.log(num[num.length - 1], index);
        return (
          <div className={mainClass} key={index}>
            <div className="loto-keg-new">
              <span>{num[index]}</span>
            </div>
            {index === 0 && <span className="label-number">0</span>}

          </div>
        );
      } else {
        return <div className={mainClass} key={index} />;
      }
    });
  }

  renderGame(tickets) {
    return tickets.map((datarow, index) => {
      return (
        <div style={{ borderRadius: 15, padding: 8, background: "white", marginBottom: 10 }} key={index}>

          <Ticket
            key={datarow.id}
            index={datarow.index}
            isGame={true}
            background="green"
            selected={datarow.selected}
            ticketData={datarow.numbers}
            onCellClick={this.handleCellClick.bind(this, index)}
          />
        </div>


      );
    });
  }


  renderWinners = () => {
    const { winners, game, showJackpot } = this.state;

    let content, isOpen = false, message, title, className, cancelText = this.props.t("dialog.goBack")
    let onCancelClick = () => {
      this.props.history.push("/");
    };

    if (winners !== null) {
      isOpen = !showJackpot;
      if (winners.found) {
        // console.log("Found winners");

        // get the result of the game
        const gameResult = this.checkWinner(winners.tickets);

        // check if user won the game alone
        if (gameResult.singleWinner) {
          title = this.props.t("game.youWon");

          className = "confetti medal-background";

          let prize = parseFloat(gameResult.prizeWon).toFixed(0);
          message = (
            <Trans i18nKey="game.onlyWinnerText">
              Sizi təbrik edirik. Siz qalib oldunuz. Balansınıza
              <span style={{ color: "black", fontWeight: "bold" }}>
                {" "}
                {{ prize }} <Manat />
              </span>{" "}
              yatırıldı.
            </Trans>
          )

          content = (
            <div className="modal-body-list">
              <span className="game-result-info">
                {message}
              </span>
            </div>
          )
        } else {

          // create lsit view from tickets
          const ticketsListView =
            winners.tickets.map((ticket, index) => {
              return (
                <div key={"container" + index} style={{ marginTop: 10 }}>
                  <div className="winner-user-info">
                    <span className="winner-name">{ticket.user.name}</span>
                    <span className="winner-prize">{ticket.prize.toFixed(0)}<Manat width={15} height={8} color="#d9720a" /></span>
                  </div>
                  <Ticket
                    key={ticket.id}
                    size="small"
                    isGame={false}
                    editable={false}
                    ticketData={ticket.numbers}
                  />
                </div>
              )

            });


          if (gameResult.ticketsWon === 0) {
            className = "sad-background";
            title = this.props.t("game.gameFinished");
            message = (
              <Trans i18nKey="game.youLostText">
                Təəssüf ki, siz{" "}
                <span style={{ color: "black" }}> qalib ola bilmədiniz</span>
                . Aşağıda qalib olanların siyahısı:
              </Trans>
            )
          } else {
            className = "confetti medal-background";
            title = this.props.t("game.youWon");

            const winnerCount = winners.tickets.length;
            const prizeCount = gameResult.prizeWon;
            const ticketCount = gameResult.ticketsWon;

            message = (
              <Trans i18nKey="game.multipleWinnerText">
                Təbrik edirik. Sizin{" "}
                <span style={{ color: "green", fontWeight: "700" }}>{{ ticketCount }}</span>{" "}
                biletiniz
                <span style={{ color: "black", fontWeight: "700" }}>
                  {{ winnerCount }}{" "}
                </span>{" "}
                qalib bilet dsgsg. Balansınıza
                <span style={{ color: "black", fontWeight: "bold" }}>
                  {{ prizeCount }} <Manat color="black" />
                </span>
                yatırıldı. Bütün qalib biletlərin siyahısı:
              </Trans>
            );
          }

          const toggle = !this.state.showList ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg> :
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>;

          content = (
            <div className="modal-body-list">
              <span className="game-result-info">
                {message}
              </span>


              <div className="list-of-winners-bar">
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 9, alignItems: "center" }}>
                  <span style={{ color: "#414141" }}>{this.props.t("game.listOfWinners")}</span>
                  <Button className="btn-img" onClick={() => {
                    this.setStateWithMount({
                      showList: !this.state.showList
                    })
                  }}>{toggle}</Button>
                </div>


                <Collapse isOpen={this.state.showList}>
                  {ticketsListView}
                </Collapse>
              </div>


            </div>
          )
        }
      } else {
        className = "confetti";
        // console.log("No winners found");
        title = this.props.t("game.winnerNotFound")
        let prize =
          ((this.state.tickets.length * this.state.game.ticketPrice * (100 - winners.commission)) / 100).toFixed(2);

        let commission = winners.commission;
        message = (
          <Trans i18nKey="game.winnerNotFoundText">
            Bu oyunda heç bir qalib tapılmadı. Biletlərinizdən oyunun
            komissiya faizi
            <span style={{ color: "black" }}> {{ commission }} % </span>
            çıxılaraq balansınıza qaytarıldı. Ümumi məbləğ{" "}
            <span style={{ color: "black" }}> {{ prize }} <Manat /> </span> .
          </Trans>
        )

        content = (
          <div className="modal-body-list">
            <span className="game-result-info">
              {message}
            </span>
          </div>
        )
      }


      if (game.jackpot) {
        if (game.jackpot.isInRange) {
          cancelText = ""
          title = (
            <div>
              {title}
              <span className="countdown-timer">{this.state.jackpotCounter}</span>
            </div>
          )
        }
      }

    }

    return (
      <Dialog
        t={this.props.t}
        className={className}
        title={title}
        onCancelClick={onCancelClick}
        isOpen={isOpen}
        cancelText={cancelText}
      >
        {content}
      </Dialog>
    )
  }

  renderWinnerTickets = winners => {
    return winners.map((winner, index) => {
      return (
        <div key={"general_" + winner.uuid}>
          <div className="ticket-winner" key={"container_" + winner.uuid}>
            <span key={"name_" + winner.uuid}>{winner.user.name}</span>
            <span key={"prize_" + winner.uuid}>{winner.prize} manat</span>
          </div>

          <Ticket
            size="small"
            key={winner.uuid}
            index={index}
            isGame={false}
            ticketData={winner.numbers}
          />
        </div>
      );
    });
  };

  renderDialog = () => {
    const info = this.state.info;
    const winners = this.state.winners;
    const isProgress = this.state.isProgress;
    let clock = "",
      message = "";

    if (winners !== null) {
      if (winners.found === false) {
        const prize =
          (this.state.tickets.length * (100 - winners.commission)) / 100;
        console.log(winners);
        const commission = winners.commission;
        console.log(commission);
        return (
          <Dialog
            t={this.props.t}
            isOpen={true}
            isCancellable={true}
            title={this.props.t("game.winnerNotFound")}
            transparent={true}
            button={this.props.t("dialog.goBack")}
            onButtonClick={this.onBackPressed}
            progressCancelable={false}
            isProgress={isProgress}
          >
            <span className="info">
              <Trans i18nKey="game.winnerNotFoundText">
                Bu oyunda heç bir qalib tapılmadı. Biletlərinizdən oyunun
                komissiya faizi
                <span style={{ color: "yellow" }}> {{ commission }} % </span>
                çıxılaraq balansınıza qaytarıldı. Ümumi məbləğ{" "}
                <span style={{ color: "yellow" }}> {{ prize }} manat </span> .
              </Trans>
            </span>
          </Dialog>
        );
      } else {
        let result = this.checkWinner(winners.tickets);
        let prize = result.prizeWon;
        if (result.singleWinner) {
          return (
            <Dialog
              isOpen={true}
              t={this.props.t}
              isCancellable={true}
              title={this.props.t("game.youWon")}
              transparent={true}
              button={this.props.t("dialog.goBack")}
              onButtonClick={this.onBackPressed}
              progressCancelable={false}
              isProgress={isProgress}
            >
              <div className="ticket-winner">
                <FontAwesomeIcon size="3x" icon="medal" color="#3f51b5" />
                <span className="info" style={{ border: "none" }}>
                  <Trans i18nKey="game.onlyWinnerText" i18n={this.props.i18n}>
                    Sizi təbrik edirik. Siz qalib oldunuz. Balansınıza
                    <span style={{ color: "yellow" }}>
                      {" "}
                      {{ prize }} manat{" "}
                    </span>{" "}
                    yatırıldı.
                  </Trans>
                </span>
              </div>
            </Dialog>
          );
        } else {
          let title = "";
          let info = "";

          if (result.ticketsWon === 0) {
            title = this.props.t("game.gameFinished");
            info = (
              <span className="info">
                <Trans i18nKey="game.youLostText">
                  Təəssüf ki, siz{" "}
                  <span style={{ color: "yellow" }}> qalib ola bilmədiniz</span>
                  . Aşağıda qalib olanların siyahısı:
                </Trans>
              </span>
            );
          } else {
            title = this.props.t("game.youWon");
            const winnerCount = winners.tickets.length;
            const prizeCount = result.prizeWon;
            const ticketCount = result.ticketsWon;
            info = (
              <span className="info">
                <Trans i18nKey="game.multipleWinnerText">
                  Təbrik edirik. Sizin{" "}
                  <span style={{ color: "yellow" }}>{{ ticketCount }}</span>{" "}
                  biletiniz
                  <span style={{ color: "yellow" }}>
                    {{ winnerCount }}{" "}
                  </span>{" "}
                  qalib bilet dsgsg. Balansınıza
                  <span style={{ color: "yellow" }}>
                    {{ prizeCount }} manat
                  </span>
                  yatırıldı. Bütün qalib biletlərin siyahısı:
                </Trans>
              </span>
            );
          }

          return (
            <Dialog
              isOpen={true}
              isCancellable={true}
              title={title}
              t={this.props.t}
              transparent={true}
              button={this.props.t("dialog.goBack")}
              onButtonClick={this.onBackPressed}
              progressCancelable={false}
              isProgress={isProgress}
            >
              {info}
              {this.renderWinnerTickets(winners.tickets)}
            </Dialog>
          );
        }
      }
    } else {
      if (info.status === "starting") {
        clock = (
          <span className="orange">
            <FontAwesomeIcon size="lg" icon="clock" />
            {tools.secondsToClock(info.remaining)}
          </span>
        );
        message = this.props.t("game.beReady");
      } else {
        message = this.props.t("game.gameNotStarted");
      }

      return (
        <Dialog
          isOpen={this.state.infoModal}
          isCancellable={false}
          title={this.props.t("game.game")}
          t={this.props.t}
          progressCancelable={false}
          isProgress={isProgress}
        >
          <div className="join-game-modal">
            <span className="blue">
              <FontAwesomeIcon size="lg" icon="users" /> {info.users}{" "}
              {this.props.t("home.players")}
            </span>
            <span className="green">
              <FontAwesomeIcon size="lg" icon="money-bill-alt" />
              {info.money} manat
            </span>
            {clock}
            <span className="info">{message}</span>
          </div>
        </Dialog>
      );
    }
  };


  render() {
    return (
      <div className="d-flex flex-fill flex-column flex-nowrap">
        {this.renderJackpotWinners()}
        {this.renderJackpot()}
        {this.renderWinners()}
        <Header
          jackpotBelow={true}
          logoClick={false}
          auto={"on"}
          players={100}
          t={this.props.t}
          prize={100.20}
          hideTopBar={true}
          stat={this.state.stat}
          showGameInfo={true}
        >
          <div className="number-list">
            {this.renderKegs(this.state.selectedNumbers)}
          </div>
        </Header>

        <Body style={{ paddingTop: 10}} loading={this.state.loading}>
          {this.renderGame(this.state.tickets)}
        </Body>
      </div>
    )
  }


}
export default withContext(Test);
