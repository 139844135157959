import React, { Component } from "react";
import axios from "axios";
import tools from "utils/tools";
import config from "config/config";
import { Ticket } from "components/Ticket";
import { Button, ButtonGroup } from "reactstrap";
import { Dialog } from "components/Views";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { withTranslation } from "react-i18next";
import {
  faCheckCircle,
  faTimesCircle,
  faCircle,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

class History extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    library.add(faCheckCircle, faTimesCircle, faCircle, faArrowLeft);
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      tickets: [],
      modal: true,
      ticketStatus: 0
    };

    this.props.i18n.changeLanguage(this.state.user.language_code);
    //custom state function
    this.setStateWithMount = this.setStateWithMount.bind(this);
    this.fetchUserInfo = this.fetchUserInfo.bind(this);
    this.fetchTickets = this.fetchTickets.bind(this);

    this.changeSelection = this.changeSelection.bind(this);
  }

  setStateWithMount = data => {
    if (this._isMounted) {
      this.setState(data);
    }
  };

  changeSelection = selected => {
    if (selected !== this.state.ticketStatus) {
      this.setState(
        {
          ticketStatus: selected
        },
        () => {
          this.fetchTickets();
        }
      );
    }
  };

  fetchTickets = () => {
    this.setStateWithMount({ modal: true });

    axios
      .get(config.API_URL + "/tickets?status=" + this.state.ticketStatus, {
        headers: {
          Authorization: this.state.user.auth_token
        }
      })
      .then(result => {
        let data = result.data;
        if (data.status === "error") {
          if (data.code === 403) {
            localStorage.removeItem("user");
            this.props.history.push("/");
          } else {
            this.setStateWithMount({
              modal: false
            });
          }
        } else {
          let tickets = data.data;
          this.setStateWithMount({ tickets: tickets, modal: false });
        }
      })
      .catch(error => console.log(error));
  };

  fetchUserInfo = () => {
    this.setStateWithMount({
      modal: true
    });

    axios
      .get(config.API_URL + "/user", {
        headers: {
          Authorization: this.state.user.auth_token
        }
      })
      .then(result => {
        let data = result.data;
        if (data.status === "error") {
          if (data.code === 403) {
            localStorage.removeItem("user");
            this.props.history.push("/");
          } else {
            this.setStateWithMount({
              modal: false
            });
          }
        } else {
          let user = data.data;
          localStorage.setItem("user", JSON.stringify(user));

          if (user.isOnGame) {
            this.props.history.push("/game");
          } else {
            this.setStateWithMount({
              user: user
            });

            this.props.i18n.changeLanguage(this.state.user.language_code);

            this.fetchTickets();
          }
        }
      })
      .catch(error => console.log(error));
  };

  renderTickets = tickets => {
    return tickets.map((datarow, index) => {
      let ticket = tools.convertToTicket(datarow.numbers);
      let icon = "";

      if (datarow.is_won) {
        icon = (
          <FontAwesomeIcon
            size="1x"
            key={"icon_" + datarow.uuid}
            icon="check-circle"
            color="#00f90a"
          />
        );
      } else {
        icon = (
          <FontAwesomeIcon
            size="1x"
            key={"icon_" + datarow.uuid}
            icon="times-circle"
            color="red"
          />
        );
      }
      return (
        <div key={"general_" + datarow.uuid}>
          <div className="ticket-winner" key={"container_" + datarow.uuid}>
            <span key={"prize_" + datarow.uuid} style={{ paddingLeft: 10 }}>
              {" "}
              {icon} {datarow.buy_date}
            </span>
            <span key={"name_" + datarow.uuid} style={{ paddingRight: 10 }}>
              {datarow.prize} manat
            </span>
          </div>
          <Ticket
            key={datarow.id}
            index={datarow.index}
            isGame={false}
            ticketData={ticket}
          />
        </div>
      );
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchUserInfo();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="game-container" style={{ position: "static" }}>
        <div
          className="game-header one-edge-shadow padding-7"
          style={{
            position: "fixed",
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <span className="header-title">
            <Button
              className="button-back"
              onClick={() => this.props.history.push("/")}
            >
              <FontAwesomeIcon size="xs" icon="arrow-left" color="white" />{" "}
            </Button>
            {this.props.t("history.pastTickets")}
          </span>
          <ButtonGroup className="one-edge-shadow" style={{ margin: 10 }}>
            <Button
              color={this.state.ticketStatus === 1 ? "danger" : "secondary"}
              style={{ borderTopLeftRadius: 2, borderBottomLeftRadius: 2 }}
              onClick={() => this.changeSelection(1)}
            >
              <FontAwesomeIcon size="1x" icon="check-circle" color="white" />
            </Button>
            <Button
              color={this.state.ticketStatus === 2 ? "danger" : "secondary"}
              onClick={() => this.changeSelection(2)}
            >
              <FontAwesomeIcon size="1x" icon="times-circle" color="white" />
            </Button>
            <Button
              color={this.state.ticketStatus === 0 ? "danger" : "secondary"}
              onClick={() => this.changeSelection(0)}
              style={{ borderTopRightRadius: 2, borderBottomRightRadius: 2 }}
            >
              <FontAwesomeIcon size="1x" icon="circle" color="white" />
            </Button>
          </ButtonGroup>
        </div>
        <div style={{ marginTop: 80 }}>
          {this.renderTickets(this.state.tickets)}
        </div>
        <Dialog
          t={this.props.t}
          isOpen={this.state.modal}
          isProgress={true}
          progressCancelable={false}
        />
      </div>
    );
  }
}

export default withTranslation("common")(History);
