import React, { Component } from 'react'
import { PaymentContainer, LoadingButton, Dialog, Manat } from 'components/Views'
import CForm from 'components/CreditCard/form'
import Card from 'components/CreditCard/card'
import { session, withContext } from 'services';
import { config, images } from "config";
import { Trans } from "react-i18next";
import NumberFormat from 'react-number-format';
import axios from "axios";

class Withdraw extends Component {


  constructor(props) {
    super(props);

    this.initialState = {
      cardNumber: '',
      cardHolder: '',
      cardMonth: '',
      cardYear: '',
      amount: 1500,
      isLoading: true,
      isValid: false,
      errors: {},
      currentFocusField: null,
      prevFocusField: null,
      infoDialog: true,
      statusDialog: {
        enabled: false,
        status: "",
        message: "",
      }
    }

    this.state = this.initialState;

    this.formFieldsRefObj = {
      cardNumber: React.createRef(),
      cardHolder: React.createRef(),
      cardDate: React.createRef(),
    };


    this.cardElementsRef = {
      cardNumber: React.createRef(),
      cardHolder: React.createRef(),
      cardDate: React.createRef()
    };

    this.onCardFormInputFocus = this.onCardFormInputFocus.bind(this)
    this.updateStateValues = this.updateStateValues.bind(this)
    this.onCardInputBlur = this.onCardInputBlur.bind(this);
    this.focusFormFieldByKey = this.focusFormFieldByKey.bind(this);
    this.onWithdrawClick = this.onWithdrawClick.bind(this);
    this.validateForm = this.validateForm.bind(this)
    this.onAmountChange = this.onAmountChange.bind(this);
    this.getPaymentStatus = this.getPaymentStatus.bind(this);
  }


  getPaymentStatus = () => {
    const { user, history } = this.props;
    axios
      .get(config.API_URL + `/payment/status`,
        {
          headers: {
            Authorization: user.token
          }
        }
      )
      .then(result => {
        const data = result.data;
        if (data.status === "success") {
          if (!data.data.withdraw.enabled) {
            history.push("/wallet")
          } else {
            this.setState({
              isLoading: false
            })
          }
        } else {
          history.push("/wallet")
        }
      })
      .catch(error => {
        history.push("/wallet")
      });
  }

  onAmountChange(values) {
    const { value } = values;
    this.setState({ amount: value });
    this.validateForm("amount", value, false)
  }

  updateStateValues = (field, value) => {
    this.validateForm(field, value, false)
    this.setState({ [field]: value })
  }

  componentDidMount = () => {
    this.getPaymentStatus()
  }

  validateForm = (field, value = null, isFocus = true) => {
    let { errors, isValid } = this.state;
    const { t, user } = this.props;

    isValid = true

    if (field === 'cardNumber') {
      errors[field] = null

      const cardNumber = (isFocus ? this.state[field] : value).replace(/\s/g, "")
      if (isFocus && cardNumber.length !== 16) {
        errors[field] = t('error.cardNumberEmpty');
        isValid = false
      } else {
        if (cardNumber.length === 16) {
          if (!this.luhnChk(cardNumber)) {
            errors[field] = t('error.wrongCardNumber');
            isValid = false
          }
        }
      }
    } else if (field === 'cardHolder') {
      errors[field] = null

      const cardHolder = isFocus ? this.state[field] : value
      if (cardHolder.length < 1) {
        errors[field] = t('error.cardHolderEmpty');
        isValid = false
      }
    } else if (field === 'cardMonth' || field === 'cardYear') {
      errors[field] = null

      errors['cardDate'] = null
      if (!isFocus && value.length < 1) {
        errors['cardDate'] = t('error.cardDateEmpty');
        isValid = false
      }
    } else if (field === 'cardDate') {
      errors[field] = null
      if (this.state.cardYear.length < 1 || this.state.cardMonth.length < 1) {
        errors['cardDate'] = t('error.cardDateEmpty');
        isValid = false
      }
    } else if (field === "amount") {
      const balance = parseFloat(user.balance);
      const amount = parseFloat(isFocus ? this.state.amount : value);
      if (amount < 1500 || amount > 50000) {
        errors['amount'] = t('error.wrongWithdrawRange')
        isValid = false
      } else if (amount > balance) {
        errors['amount'] = t('error.withdrawMoreThanBalance')
        isValid = false
      } else {
        errors['amount'] = null
      }
    }

    this.setState({ errors, isValid })
    return isValid
  }

  focusFormFieldByKey = (key) => {
    this.formFieldsRefObj[key].current.focus();
  }

  onCardFormInputFocus = (event, inputName) => {
    this.setState({ currentFocusField: inputName })
  }

  onCardInputBlur = () => {
    const { currentFocusField } = this.state
    this.validateForm(currentFocusField)
    this.setState({ currentFocusField: null })
  }

  luhnChk = (function (arr) {
    return function (ccNum) {
      var
        len = ccNum.length,
        bit = 1,
        sum = 0,
        val;

      while (len) {
        val = parseInt(ccNum.charAt(--len), 10);
        // eslint-disable-next-line no-cond-assign
        sum += (bit ^= 1) ? arr[val] : val;
      }

      return sum && sum % 10 === 0;
    };
  }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

  onWithdrawClick = () => {
    const { cardHolder, cardNumber, cardMonth, cardYear, amount, statusDialog } = this.state;
    const { t } = this.props;


    if (this.validateForm("cardNumber") &&
      this.validateForm("cardHolder") &&
      this.validateForm("cardDate") &&
      this.validateForm("amount")) {

      this.setState({ isLoading: true })

      axios(
        {
          method: "POST",
          url: config.API_URL + "/payment/withdraw",
          data: {
            cardExpiryDate: `${cardMonth}/${cardYear.toString().substr(-2)}`,
            cardHolder,
            amount,
            cardNumber: cardNumber.replace(/\s/g, ""),
          },
          headers: {
            Authorization: session.getUser().token
          }
        }
      )
        .then(result => {
          let data = result.data;
          statusDialog.enabled = true;
          statusDialog.status = data.status;
          if (data.status === "error") {
            if (data.code === 102) {
              statusDialog.message = t('error.withdrawMoreThanBalance')
            } else if (data.code === 410) {
              statusDialog.message = t('error.wrongWithdrawAmount')
            } else if (data.code === 411) {
              statusDialog.message = t('error.wrongCardDetails')
            } else if (data.code === 412) {
              statusDialog.message = t('error.cardExpired')
            } else if (data.code === 413) {
              statusDialog.message = t('error.withdrawFailed')
            } else {
              statusDialog.message = t('error.internalError')
            }
          }

          this.setState({ isLoading: false })

        })
        .catch(error => {
          this.setState({ isLoading: false })
        });

    }

  }


  renderStatusDialog = () => {
    const { t, history } = this.props;
    let { statusDialog } = this.state

    let dialogContent, onCancelClick = () => {
      statusDialog.enabled = false
      this.setState({ statusDialog })
    };

    if (statusDialog.enabled) {

      if (statusDialog.status === "success") {
        onCancelClick = () => {
          history.push("/wallet")
        }
        dialogContent = (
          <div className='d-flex flex-column align-items-center'>
            <Trans i18nKey="wallet.pendingTransaction">
              <span style={{ fontWeight: 500 }}>Təşəkkür edirik</span>
              <span style={{ textAlign: "center" }}>Sizin chekiniz uğurla yüklendi</span>
            </Trans>
          </div>
        );
      } else if (statusDialog.status === "error") {
        dialogContent = (
          <div className='d-flex flex-column align-items-center'>
            <span style={{ fontWeight: 500 }}>{t('wallet.unsuccessfulTransaction')}</span>
            <span style={{ textAlign: "center" }}>{statusDialog.message}</span>
          </div>
        )
      }

      return (
        <Dialog
          isOpen={statusDialog.enabled}
          cancelText={t("dialog.close")}
          onCancelClick={onCancelClick}
          toggle={onCancelClick}
          t={t}
        >
          <div className='d-flex flex-column align-items-center p-2'>
            {<img src={images.status[statusDialog.status === "success" ? "pending" : "fail"]} style={{ height: 37, width: 37 }} alt={statusDialog.status} />}
            {dialogContent}
          </div>
        </Dialog>
      )
    }
  }


  renderInfoDialog = () => {
    const { infoDialog } = this.state
    const { t } = this.props
    return (
      <Dialog
        isOpen={infoDialog}
        title="Məlumat"
        cancelText={t("dialog.close")}
        onCancelClick={() => {
          this.setState({ infoDialog: false })
        }}
        toggle={() => {
          this.setState({ infoDialog: false })
        }}
        t={t}
      >
        <span>
          <Trans i18nKey="wallet.bankMessage">
            <b>Kapital Bank</b> və <b>Azərbaycan Türk Bank</b> kartlarına köçürmə <b>3</b> gün ərzində həyata keçirilir. <div style={{ color: "red", fontWeight: "bold", marginTop: 0 }}>Sürətli köçürmə üçün digər banklardan istifadə edin.</div>
          </Trans>
        </span>
      </Dialog>
    )
  }

  render() {
    const { history, t, user } = this.props;
    const state = this.state
    return (
      <PaymentContainer backText="Back" title={t("wallet.withdraw")} history={history} t={t} isLoading={state.isLoading} onBack={() => { this.props.history.push("/wallet") }}>
        {/* zzz this.renderInfoDialog()*/}
        {this.renderStatusDialog()}
        <div className='px-3'>

          <div className='wallet-balance bg-gradient-purple'>
            <span style={{ fontSize: 14, marginBottom: -4 }}>{t('home.balance')}</span>
            <span style={{ fontSize: 25, fontWeight: 600, display: "flex", alignItems: "center" }}>{user.balance} <Manat height={17} style={{ marginLeft: 4, marginBottom: -4 }} /></span>
          </div>
          <div className='divider' style={{ backgroundColor: "#6c757d8f", marginTop: 15 }}></div>

          <Card
            t={t}
            cardNumber={state.cardNumber}
            cardHolder={state.cardHolder}
            cardMonth={state.cardMonth}
            cardYear={state.cardYear}
            onCardElementClick={this.focusFormFieldByKey}
            cardNumberRef={this.cardElementsRef.cardNumber}
            cardHolderRef={this.cardElementsRef.cardHolder}
            cardDateRef={this.cardElementsRef.cardDate}
            currentFocusedElm={this.cardElementsRef[state.currentFocusField]}
          />

          <CForm
            t={t}
            cardMonth={state.cardMonth}
            cardYear={state.cardYear}
            onUpdateState={this.updateStateValues}
            onCardInputFocus={this.onCardFormInputFocus}
            onCardInputBlur={this.onCardInputBlur}
            errors={state.errors}
            cardNumberRef={this.formFieldsRefObj.cardNumber}
            cardHolderRef={this.formFieldsRefObj.cardHolder}
            cardDateRef={this.formFieldsRefObj.cardDate}
          />
          <div className='d-flex align-items-center p-3 shadow-md my-3' style={{ background: "#ffe3b1", borderRadius: 9, color: "black", fontSize: 14 }}>
            <svg width="20" height="20" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
            <span className='pl-3'>{t('wallet.commissionMessage')}</span>
          </div>


          <div className={`card-input ${(state.errors.hasOwnProperty("amount") && state.errors.amount !== null) ? "error" : ""}`}>
            <label htmlFor="amount" className="card-input__label">
              {t('wallet.amount')}
            </label>
{/* zzz */}
            <NumberFormat
              suffix=" ₸"
              style={{ fontSize: 30, height: 60 }}
              type="text"
              name="amount"
              decimalScale={0}
              fixedDecimalScale={true}
              allowNegative={false}
              placeholder={"0 ₸"}
              onValueChange={this.onAmountChange}
              value={state.amount}
              className="card-input__input"
              autoComplete="off"
              maxLength="19"
            />
            {state.errors.hasOwnProperty("amount") &&
              <span className='form-error'>{state.errors.amount}</span>}

            <span>{state.amount * 0.95} ₼</span>

          </div>
          <LoadingButton
            className="btn-yellow my-4  shadow-none"
            style={{ fontWeight: 500 }}
            disabled={!state.isValid || state.isLoading}
            isLoading={state.isLoading}
            onClick={this.onWithdrawClick}
            text={this.props.t("wallet.withdraw")}
          />

        </div>

      </PaymentContainer>
    )
  }
}

export default withContext(Withdraw);