import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import Manat from "./Manat"
import { images } from "config";
import { session } from "services"
import { Trans } from "react-i18next";
import { ButtonWithSVG } from '.';
import AnimatedNumber from "animated-number-react";
// import { UncontrolledTooltip } from "reactstrap"


export default class Header extends Component {

    renderGameStats = () => {
        const { stat, players, prize, t } = this.props;

        if (stat) {
            const marked = parseInt(stat.marked);
            const waitingPlayers = stat.waiting_players;
            const remaining = 5 - marked
            let text = ""

            if (marked === 1) {
                text = <div style={{ fontWeight: "400" }}>
                    <Trans i18nKey="game.statMarkedFirst" count={2}>
                        <span style={{ fontWeight: "600", fontSize: 18 }}>{{ waitingPlayers }}</span>{" "}bilet{" "}
                        <span style={{ fontWeight: "600", fontSize: 18 }}>1</span>{" "}rəqəm işarələdi
                    </Trans>
                </div>
            } else if (marked === 5) {
                text = <div style={{ fontWeight: "400" }}>
                    <Trans i18nKey="game.statMarkedWinner" count={waitingPlayers}>
                        <span style={{ fontWeight: "600", fontSize: 18 }}>{{ waitingPlayers }}</span>{" "}bilet bütün sıranı işarələdi
                    </Trans>
                </div>
            } else {
                text = <div style={{ fontWeight: "400" }}>
                    <Trans i18nKey="game.statMarkedGeneral" count={waitingPlayers}>
                        <span style={{ fontWeight: "600", fontSize: 18 }}>{{ waitingPlayers }}</span>{" "}bilet{" "}
                        <span style={{ fontWeight: "600", fontSize: 18 }}>{{ remaining }}</span>{" "}rəqəm gözləyir
                    </Trans>
                </div>
            }

            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                        <g id="Group_1262" data-name="Group 1262" transform="translate(-3963 -4635)">
                            <circle id="Ellipse_82" data-name="Ellipse 82" cx="9.5" cy="9.5" r="9.5" transform="translate(3963 4635)" fill="#de9411" />
                            <text id="i" transform="translate(3971 4649)" fill="#fff" fontSize="13" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700"><tspan x="0" y="0">i</tspan></text>
                        </g>
                    </svg>
                    {text}
                </div>
            )
        } else {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.959" height="15.959" viewBox="0 0 15.959 15.959">
                        <path id="Icon_ionic-md-person" data-name="Icon ionic-md-person" d="M12.479,12.479A3.99,3.99,0,1,0,8.49,8.49,4,4,0,0,0,12.479,12.479Zm0,1.995c-2.643,0-7.979,1.347-7.979,3.99v1.995H20.459V18.464C20.459,15.821,15.123,14.474,12.479,14.474Z" transform="translate(-4.5 -4.5)" fill="#de9411" />
                    </svg>
                    <span style={{ marginRight: 10 }}><span style={{ fontWeight: "600" }}>{players}</span> {t("gameItem.players")}</span>
                    <svg id="manat_1_" data-name="manat (1)" xmlns="http://www.w3.org/2000/svg" width="18.959" height="18.959" viewBox="0 0 18.959 18.959">
                        <path id="XMLID_669_" d="M9.479,0a9.479,9.479,0,1,0,9.479,9.479A9.474,9.474,0,0,0,9.479,0ZM14.5,13.383H13.383V10.037a3.909,3.909,0,0,0-3.346-3.863v6.094H8.922V6.174a3.909,3.909,0,0,0-3.346,3.863v3.346H4.461V10.037A5.026,5.026,0,0,1,8.922,5.05V3.9h1.115V5.05A5.026,5.026,0,0,1,14.5,10.037Z" fill="#de9411" />
                    </svg>
                    <span>{t("gameItem.money")}: <span style={{ fontWeight: "600" }}>
                        <AnimatedNumber
                            value={prize}
                            formatValue={(value) => value.toFixed(0)}
                        /> <Manat style={{ marginLeft: -4, marginTop: -2 }} color="#150121" /></span></span>
                </div>
            )
        }

    }

    renderGameInfo = () => {
        const { showGameInfo, auto = 'disabled', onAutoClick, t } = this.props;

        if (!showGameInfo) return;

        return (
            <div>
                <div className="container">
                    <div className="play-info">
                        {this.renderGameStats()}
                        <div style={{ marginLeft: "auto" }}>
                            <ButtonWithSVG
                                text={t("game.auto")}
                                className={"auto " + auto}
                                onClick={onAutoClick}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="8.222" height="13.54" viewBox="0 0 8.222 13.54">
                                    <path id="Path_16093" data-name="Path 16093" d="M2921.6,5484.7l-3.832,6.875h3.832l-2.234,6.257,5.217-8.239h-3.453Z" transform="translate(-2917.087 -5484.506)" strokeWidth="0.8" />
                                </svg>
                            </ButtonWithSVG>
                        </div>
                    </div>

                </div>
            </div>
        )
    }


    renderTopBar = () => {
        const { hideTopBar, t } = this.props;
        let { user } = this.props;

        if (hideTopBar) return;

        if (!user) {
            user = session.getUser();
        }

        return (
            <div className="header-container">
                <div style={{ display: "flex", flex: 1 }}>
                    <div className="username-container">
                        <img src={images.profile} className="logo" alt="" />
                        <span className="username">{user.username}</span>
                    </div>
                </div>
                <div>
                    <div className="logo-container">
                        {process.env.REACT_APP_RELEASE_TYPE === "beta" &&
                            <span className="beta-label">beta</span>
                        }
                        <NavLink to="/">
                            <img src={images.logoWhite} className="logo" alt="" />
                        </NavLink>

                    </div>
                </div>
                <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                    <NavLink to="/deposit">
                        <div className="balance-container" id="UncontrolledTooltipExample" onClick={() => { }}>
                            <div className="balance">
                                {user.balance}<Manat color="#CB9032" height="15" width="14" style={{ marginLeft: 4, marginBottom: -1 }} />
                            </div>
                        </div>
                    </NavLink>
                    {(user.balance < 50) && <div id="there" className="bounce">{t("home.increaseBalance")}</div>}
                </div>
            </div>
        )
    }


    render() {
        const { children } = this.props;

        return (
            <div className="header-parent">
                <div className="header">
                    <div className="container">
                        {this.renderTopBar()}
                        {children}
                    </div>
                </div>
                {this.renderGameInfo()}
            </div>
        )
    }
}
