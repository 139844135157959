import React, { Component } from "react";
import { Input, Form, FormGroup, Label, FormFeedback, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import { Redirect } from "react-router-dom";
import { config, images } from "config";
import { LoadingButton, Dialog, FooterPages } from "components/Views";
import axios from "axios";
import { session } from "services"
import { withTranslation, Trans } from "react-i18next";


class Login extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLogin: !this.props.isRegister,
      redirectToReferrer: false,
      languageIsOpen: false,
      languageCode: "ru",
      showMessage: false,
      message: "",
      isValid: false,
      validFields: {
        loginUsername: false,
        loginPassword: false,
        username: false,
        name: false,
        email: false,
        password: false,
        passwordRetype: false,
      },
      invalidFields: {
        loginUsername: false,
        loginPassword: false,
        username: false,
        name: false,
        acceptAge: false,
        acceptTerms: false,
        email: false,
        password: false,
        passwordRetype: false
      },
      errors: {
        loginUsername: '',
        loginPassword: '',
        username: '',
        name: '',
        email: '',
        password: '',
        passwordRetype: ''
      },
      fields: {
        loginUsername: '',
        loginPassword: '',
        username: '',
        name: '',
        email: '',
        password: '',
        passwordRetype: '',
        acceptAge: false,
        acceptTerms: false
      },
    };

    this.setStateWithMount = this.setStateWithMount.bind(this);
    this.login = this.login.bind(this);
    this.register = this.register.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    // console.log("user:", session.getUser());
  }

  setStateWithMount = (data, cb = null) => {
    if (this._isMounted) {
      if (cb !== null) {
        this.setState(data, cb);
      } else {
        this.setState(data);
      }
    }
  };


  onChangeLanguage = (code) => {
    this.props.i18n.changeLanguage(code, () => {
      this.setStateWithMount({ languageCode: code })
    })
  }


  handleCheckbox(evt) {
    let { fields, invalidFields } = this.state;
    let field = evt.target.name;
    fields[field] = !fields[field]
    if (fields[field]) {
      invalidFields[field] = false;
    }

    this.setStateWithMount({ invalidFields, fields })

  }


  handleChange(evt) {
    let value = evt.target.value;
    let field = evt.target.name;
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const usernameRegex = /^[0-9a-z_]+$/i;
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$/i;

    let validFields = this.state.validFields;
    let invalidFields = this.state.invalidFields;
    let errors = this.state.errors;
    let fields = this.state.fields;

    fields[field] = value;

    if (!this.state.isLogin) {

      if (field === "username") {
        if (value.length < 4) {
          invalidFields[field] = true;
          validFields[field] = false;
          errors[field] = this.props.t("error.usernameLength");
        } else if (!usernameRegex.test(value)) {
          invalidFields[field] = true;
          validFields[field] = false;
          errors[field] = this.props.t("error.usernameFormat")
        } else {
          validFields[field] = true;
          invalidFields[field] = false;
        }
      } else if (field === "email") {
        if (!emailRegex.test(value)) {
          invalidFields[field] = true;
          errors[field] = this.props.t("error.emailFormat");
        } else {
          invalidFields[field] = false;
          validFields[field] = true;
        }
      } else if (field === "name") {
        if (value.length < 3) {
          invalidFields[field] = true;
          errors[field] = this.props.t("error.nameLength");
        } else {
          validFields[field] = true;
          invalidFields[field] = false;
        }
      } else if (field === "password") {
        if (value.length < 8) {
          invalidFields[field] = true;
          errors[field] = this.props.t("error.passwordLength");
        } else if (!passwordRegex.test(value)) {
          invalidFields[field] = true;
          errors[field] = this.props.t("error.passwordWeak");
        } else {
          validFields[field] = true;
          invalidFields[field] = false;
        }
        if (fields['passwordRetype'].length > 0) {
          if (fields['passwordRetype'] !== fields['password']) {
            invalidFields["passwordRetype"] = true;
            errors["passwordRetype"] = this.props.t("error.passwordRetypeError");
          } else {
            invalidFields["passwordRetype"] = false;
            validFields["passwordRetype"] = true;
          }
        }

      } else if (field === "passwordRetype") {
        if (fields['passwordRetype'] !== fields['password']) {
          invalidFields[field] = true;
          errors[field] = this.props.t("error.passwordRetypeError");
        } else {
          validFields[field] = true;
          invalidFields[field] = false;
        }
      }
    } else {
      invalidFields[field] = false;
    }

    this.setState({
      fields,
      validFields,
      invalidFields,
      errors
    });
  }


  componentDidMount() {
    this._isMounted = true;
    //document.body.style.backgroundColor = "#713198"
  }

  componentWillUnmount() {
    this._isMounted = false;
    //document.body.style.backgroundColor = "#240735"
  }

  handleKeyPress(e) {

    if (e.key === "Enter") {
      if (this.state.isLogin) { this.login() }
      else { this.register() }
    }
  }


  login() {

    let isFormValid = true;
    let fields = this.state.fields;
    let invalidFields = this.state.invalidFields;
    let errors = this.state.errors;

    if (fields['loginUsername'].length === 0) {
      invalidFields['loginUsername'] = true;
      errors['loginUsername'] = this.props.t("error.usernameEmpty");
      isFormValid = false;
    }

    if (fields['loginPassword'].length === 0) {
      invalidFields['loginPassword'] = true;
      errors['loginPassword'] = this.props.t("error.passwordEmpty");
      isFormValid = false
    }


    if (isFormValid) {
      this.setStateWithMount({ isLoading: true });

      axios
        .post(config.API_URL + "/auth", {
          username: this.state.fields["loginUsername"],
          password: this.state.fields["loginPassword"]
        })
        .then(result => {
          let data = result.data;
          let showMessage = this.state.showMessage;
          let message = this.state.message;
          let redirectToReferrer = this.state.redirectToReferrer;

          if (data.status === "error") {
            message = this.props.t("login.error");
            showMessage = true;
          } else {
            // console.log("set user", data.data)

            session.setUser(data.data);

            this.props.i18n.changeLanguage(data.data.language_code);
            // console.log("login_session_info", session.getUser());
            redirectToReferrer = true;
          }

          this.setStateWithMount({
            isLoading: false,
            redirectToReferrer: redirectToReferrer,
            showMessage: showMessage,
            message: message
          })
        })
        .catch(error => {
          // console.log(error);
          this.setStateWithMount({
            isLoading: false
          })
        });
    }

    this.setStateWithMount({
      invalidFields,
      errors,
    })

  }

  register() {

    let isFormValid = true;
    let { validFields, invalidFields, errors, fields } = this.state



    Object.keys(validFields).forEach(field => {
      if (field !== "loginUsername" && field !== "loginPassword") {
        if (!validFields[field]) {
          invalidFields[field] = true;
          errors[field] = field + " must not be empty";
          errors[field] = this.props.t("login." + field) + this.props.t("error.notEmpty");
          isFormValid = false;
        }
      }
    });

    if (!fields["acceptAge"]) {
      invalidFields["acceptAge"] = true
      isFormValid = false;
    }

    if (!fields["acceptTerms"]) {
      invalidFields["acceptTerms"] = true
      isFormValid = false;
    }

    if (isFormValid) {
      this.setStateWithMount({ isLoading: true });


      const params = new URLSearchParams(this.props.location.search);


      axios
        .post(config.API_URL + "/register", {
          username: this.state.fields['username'],
          password: this.state.fields['password'],
          email: this.state.fields['email'],
          name: this.state.fields['name'],
          referral: params.get("ref"),
          payload: params.get("payload"),
          language_code: this.state.languageCode
        })
        .then(result => {
          let data = result.data;
          let showMessage = this.state.showMessage;
          let message = this.state.message;

          if (data.status === "error") {
            showMessage = true;
            if (data.code === 406) {
              message = this.props.t("error.usernameExists");
            } else if (data.code === 407) {
              message = this.props.t("error.emailExists");
            } else {
              message = this.props.t("error.internalError");
            }

            this.setStateWithMount({
              isLoading: false,
              showMessage: showMessage,
              message: message
            })
          } else {
            session.setUser(data.data);
            this.props.history.push("/")
          }

        })
        .catch(error => {
          // console.log(error);
          this.setStateWithMount({
            isLoading: false
          })
        });
    } else {
      this.setStateWithMount({
        validFields,
        invalidFields,
        errors,
      })
    }
  }


  renderLogin() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <Form className="login-form">
        <div className="form-header">
          <span className="title">{this.props.t("login.login")}</span>
          <img src={images.logoDark} className="logo" alt="" />
        </div>

        <FormGroup>
          <Label for="exampleEmail">{this.props.t("login.username")}</Label>
          <Input
            type="username"
            autoCapitalize='off'
            name="loginUsername"
            value={this.state.fields["loginUsername"]}
            invalid={this.state.invalidFields["loginUsername"]}
            onChange={this.handleChange}
            placeholder={this.props.t("login.username")}
          />
          <FormFeedback>{this.state.errors["loginUsername"]}</FormFeedback>
        </FormGroup>

        <FormGroup style={{ marginBottom: 40 }}>
          <Label for="exampleEmail">{this.props.t("login.password")}</Label>
          <Input
            type="password"
            name="loginPassword"
            value={this.state.fields["loginPassword"]}
            invalid={this.state.invalidFields["loginPassword"]}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            placeholder={this.props.t("login.password")}
          />
          <FormFeedback>{this.state.errors["loginPassword"]}</FormFeedback>
        </FormGroup>
        <LoadingButton
          className="btn-yellow mt-4"
          isLoading={this.state.isLoading}
          onClick={this.login}
          text={this.props.t("login.login")}
        />
        <span className="bottom-text" onClick={() => { this.setState({ isLogin: false }) }}>{this.props.t("login.noAccountMessage")} <span className="blue-bold">{this.props.t("login.registerHere")}</span></span>
      </Form>

    );

  }

  renderRegister() {
    // console.log(this.state.ref)
    return (
      <Form className="login-form">
        <div className="form-header">
          <span className="title">{this.props.t("login.register")}</span>
          <img src={images.logoDark} className="logo" alt="" />
        </div>
        <FormGroup>
          <Label for="exampleEmail">{this.props.t("login.username")}</Label>
          <Input
            type="text"
            name="username"
            invalid={this.state.invalidFields["username"]}
            valid={this.state.validFields["username"]}
            autoCapitalize='off'
            value={this.state.fields["username"]}
            onChange={this.handleChange}
            placeholder={this.props.t("login.username")}
          />
          <FormFeedback>{this.state.errors["username"]}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="exampleEmail">{this.props.t("login.name")}</Label>
          <Input
            type="text"
            name="name"
            invalid={this.state.invalidFields["name"]}
            valid={this.state.validFields["name"]}
            value={this.state.fields["name"]}
            onChange={this.handleChange}
            placeholder={this.props.t("login.name")}
          />
          <FormFeedback>{this.state.errors["name"]}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">{this.props.t("login.email")}</Label>
          <Input
            type="email"
            name="email"
            invalid={this.state.invalidFields["email"]}
            valid={this.state.validFields["email"]}
            value={this.state.fields["email"]}
            onChange={this.handleChange}
            placeholder={this.props.t("login.email")}
          />
          <FormFeedback>{this.state.errors["email"]}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">{this.props.t("login.password")}</Label>
          <Input
            type="password"
            name="password"
            invalid={this.state.invalidFields["password"]}
            valid={this.state.validFields["password"]}
            value={this.state.fields["password"]}
            onChange={this.handleChange}
            placeholder={this.props.t("login.password")}
          />
          <FormFeedback>{this.state.errors["password"]}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="exampleEmail">{this.props.t("login.passwordConfirm")}</Label>
          <Input
            type="password"
            name="passwordRetype"
            invalid={this.state.invalidFields["passwordRetype"]}
            valid={this.state.validFields["passwordRetype"]}
            value={this.state.fields["passwordRetype"]}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            placeholder={this.props.t("login.passwordConfirm")}
          />
          <FormFeedback>{this.state.errors['passwordRetype']}</FormFeedback>
        </FormGroup>
        <FormGroup check style={{ marginTop: 10 }}>
          <Input
            name="acceptAge"
            invalid={this.state.invalidFields["acceptAge"]}
            type="checkbox"
            onChange={this.handleCheckbox}
            checked={this.state.fields["acceptAge"]}
          />
          <Label check for="ageCheckbox">{this.props.t("login.acceptAge")}</Label>
        </FormGroup>
        <FormGroup check>
          <Input
            checked={this.state.fields["acceptTerms"]}
            invalid={this.state.invalidFields["acceptTerms"]}
            name="acceptTerms"
            type="checkbox"
            onChange={this.handleCheckbox}
          />
          <Label check for="termsCheckbox">
            <Trans i18nKey="login.acceptTerms">
              I am familiar with and agree to the <a href="/terms-and-conditions" target="_blank">Terms of Service</a> and the <a href="/privacy" target="_blank" >Privacy policy</a>.
            </Trans>
          </Label>
        </FormGroup>
        <LoadingButton
          className="btn-yellow mt-4"
          isLoading={this.state.isLoading}
          onClick={this.register}
          text={this.props.t("login.signup")}
        />
        <span className="bottom-text" onClick={() => { this.setState({ isLogin: true }) }}>{this.props.t("login.haveAccountMessage")} <span className="blue-bold" >{this.props.t("login.loginHere")}</span></span>
      </Form>
    );
  }


  // switch (code) {
  //   case "az": title = "Azərbaycan Dili"; break;
  //   case "tr": title = "Türkçe"; break;
  //   case "ru": title = "Pусский"; break;
  //   case "en": title = "English"; break;
  //   default: title = "Azərbaycan Dili";
  // }


  renderLanguages() {
    const { languageIsOpen, languageCode } = this.state;
    const languages = {
      // az: "Azərbaycan Dili",
      // tr: "Türkçe",
      kk: "Қазақ",
      ru: "Pусский",
      en: "English",
    }

    const otherLangs = Object.assign({}, languages)
    delete otherLangs[languageCode]

    return (
      <Dropdown direction="down"
        className="language-dropdown"
        isOpen={languageIsOpen}
        style={{ paddingTop: 10 }}
        toggle={() => this.setStateWithMount({ languageIsOpen: !languageIsOpen })}>
        <DropdownToggle caret>
          <div className="dropdown-container">
            <img src={images.flags[languageCode]} alt="az" />
            <div className="dropdown-selected">
              <span className="label">{this.props.t("language.changeLanguage")}</span>
              <span className="name">{languages[languageCode]}</span>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {Object.keys(otherLangs).map((item) => {
            return (
              <DropdownItem key={item} onClick={() => this.onChangeLanguage(item)}>
                <div className="dropdown-container">
                  <img src={images.flags[item]} alt={item} />
                  <span className="label">{languages[item]}</span>
                </div>
              </DropdownItem>
            )
          })}

        </DropdownMenu>
      </Dropdown>
    )
  }

  render() {
    return (
      <div className="d-flex flex-fill flex-column overflow-auto">
        <div className="container" style={{ height: "auto" }}>

          {this.renderLanguages()}
        </div>

        <div className="container d-flex flex-fill py-4 login-container h-auto" >
          {this.state.isLogin && this.renderLogin()}
          {!this.state.isLogin && this.renderRegister()}
          <Dialog
            t={this.props.t}
            isOpen={this.state.showMessage}
            toggle={() => {
              this.setState({
                showMessage: !this.state.showMessage
              })
            }}
            cancelText={this.props.t("dialog.close")}
            onCancelClick={() => {
              this.setState({
                showMessage: false
              })
            }}
            title={this.props.t("dialog.error")}
            content={this.state.message}
          >
          </Dialog>
        </div>
        <FooterPages />
      </div>
    )
  }
}

export default withTranslation("common")(Login);
