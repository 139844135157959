import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "translations/en/common.json";
// import common_az from "translations/az/common.json";
import common_ru from "translations/ru/common.json";
import common_kk from "translations/kk/common.json";
// import common_tr from "translations/tr/common.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: "common",
    resources: {
      /*
      az: {
        common: common_az // 'common' is our custom namespace
      },*/
      kk: {
        common: common_kk // 'common' is our custom namespace
      },
      en: {
        common: common_en // 'common' is our custom namespace
      },
      ru: {
        common: common_ru
      },
      /*
      tr: {
        common: common_tr
      }
      */
    },

    lng: "ru",
    debug: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
