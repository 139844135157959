import React, { Component } from 'react'
import { Portal } from 'react-portal';
import { Spinner } from 'reactstrap';


export default class Body extends Component {

    render() {
        const { loading, cardStyle, mainStyle, ...otherProps } = this.props;

        if (loading) {
            return (
                <>
                    <Portal node={document && document.getElementById('root')}>
                        <div className="loading-container" id="loader"><div className="spinner-container"><Spinner /> </div></div>
                    </Portal>
                    <div className="main-container" style={mainStyle} >
                        <div className="container" {...otherProps}>
                            <div className="card-container" style={cardStyle}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <div className="main-container" style={mainStyle} >
                    <div className="container" {...otherProps}>
                        <div className="card-container" style={cardStyle}>
                            {this.props.children}
                        </div>
                    </div>
                </div>

            )
        }
    }

}
