import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Spinner } from "reactstrap";

class Dialog extends Component {

  render = () => {

    // create empty variables for header and footer
    // let header = "", footer = "";

    let content;

    content = this.props.content ? this.props.content : this.props.children;
    let blurred = this.props.acceptText && this.props.cancelText;

    if (this.props.isLoading) {
      content =
        <div className="dialog-spinner">
          <Spinner />
        </div>
    }

    if (this.props.isProgress) {
      return (
        <Modal
          modalClassName={"modal-progress"}
          isOpen={this.props.isOpen}
          modalTransition={{ timeout: 100 }}
          backdropTransition={{ timeout: 100 }}
          centered={true}
        >
          <ModalBody>
            <div>
              <div className="lds-circle">
                <div> 90 </div>
              </div>
              Connecting please wait...
            </div>
          </ModalBody>
        </Modal>
      )
    } else {
      return (
        <Modal
          modalClassName={this.props.className}
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id={this.props.id}
          onClosed={this.props.onClose}
          modalTransition={{ timeout: 100 }}
          backdropTransition={{ timeout: 100 }}
          centered={true}
        >
          <ModalHeader toggle={this.props.toggle} >
            {this.props.title}
          </ModalHeader>
          <ModalBody style={this.props.bodyStyle}>
            {content}
          </ModalBody>
          {!this.props.hideFooter &&
            <ModalFooter>
              {this.props.cancelText !== "" && <Button className={blurred ? "btn-cancel" : "btn-accept"} onClick={this.props.onCancelClick} >{this.props.cancelText}</Button>}
              {this.props.acceptText !== "" && <Button className={`btn-accept ${this.props.acceptDisabled ? 'btn-disabled' : ''}`} onClick={this.props.onAcceptClick} >
                {this.props.isAcceptLoading &&
                  <Spinner size="sm" style={{ marginRight: 5 }} />}
                {this.props.acceptText}
              </Button>}
            </ModalFooter>
          }
        </Modal>
      )
    }


  }




  renderOld() {
    let buttonColor = "btn-text " + this.props.buttonColor;
    let modalHeader = "",
      modalFooter = "";

    let buttonText = (this.props.button === "") ? this.props.t('dialog.close') : this.props.button;

    if (this.props.title !== "") {
      modalHeader = (
        <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
      );
    }

    if (this.props.isProgress) {
      if (this.props.progressCancelable) {
        modalFooter = (
          <ModalFooter>
            <Button
              className="btn-text"
              onClick={this.props.onButtonClick}
            >
              {this.props.t("dialog.close")}
            </Button>{" "}
          </ModalFooter>
        );
      }

      return (
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          centered={true}
        >
          <ModalBody className="loading-modal">
            <div className="lds-circle">
              <div> 90 </div>
            </div>
            <span>{this.props.t("dialog.connecting")}</span>
          </ModalBody>
          {modalFooter}
        </Modal>
      );
    } else {
      if (this.props.isCancellable) {
        modalFooter = (
          <ModalFooter>
            <Button
              className={buttonColor}
              style={{ fontSize: "0.8em", height: "9vh" }}
              onClick={this.props.onButtonClick}
            >
              {buttonText}
            </Button>{" "}
          </ModalFooter>
        );
      }

      return (
        <Modal
          modalClassName={this.props.transparent ? "transparent" : ""}
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          centered={true}
        >
          {modalHeader}
          <ModalBody>
            {this.props.text}
            {this.props.children}
          </ModalBody>
          {modalFooter}
        </Modal>
      );
    }
  }
}

Dialog.propTypes = {
  toggle: PropTypes.func,
  t: PropTypes.func.isRequired,
  text: PropTypes.string,
  progressCancelable: PropTypes.bool,
  isCancellable: PropTypes.bool,
  transparent: PropTypes.bool,
  isProgress: PropTypes.bool,
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  onAcceptClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isOpen: PropTypes.bool,
  buttonColor: PropTypes.string
};

Dialog.defaultProps = {
  title: "",
  buttonColor: "red",
  button: "",
  transparent: false,
  isOpen: false,
  cancelText: "",
  isCancellable: true,
  acceptText: "",
  isProgress: false,
  progressCancelable: true
};

export default Dialog;
