import React, { Component } from 'react'
import { PaymentContainer, ButtonWithSVG, Manat, Dialog, NotFound } from 'components/Views'
import { Input, Form, FormGroup, Label, FormFeedback, Button } from "reactstrap";
import { withContext } from 'services';
import moment from "moment"
import InfiniteScroll from 'react-infinite-scroller';
import { Trans } from 'react-i18next'
import NumberFormat from 'react-number-format';
import { config, images } from "config";
import axios from "axios";


class Wallet extends Component {
  constructor(props) {
    super(props)

    this.props.client.requestUserData();

    this.state = {
      isLoading: true,
      depositDialog: false,
      showDepositWarning: true,
      paymentStatus: {
        withdraw: {
          enabled: false,
        },
        deposit: {
          enabled: false,
          card_type_list: []
        }
      },
      isDepositLoading: false,
      statusDialog: {
        enabled: false,
        orderId: "",
        status: "",
        type: "deposit",
        amount: 0
      },
      amountInvalid: false,
      cardType: "",
      amountError: '',
      amount: 10,
      transactions: []
    }

    this.setAmount = this.setAmount.bind(this);
    this.depositMoney = this.depositMoney.bind(this)
    this.setStateWithMount = this.setStateWithMount.bind(this);
    this.loadTransactions = this.loadTransactions.bind(this);
    this.getPaymentStatus = this.getPaymentStatus.bind(this);
  }

  getPaymentStatus = () => {
    const { user } = this.props;
    axios
      .get(config.API_URL + `/payment/status`,
        {
          headers: {
            Authorization: user.token
          }
        }
      )
      .then(result => {
        const data = result.data;
        if (data.status === "success") {
          this.setStateWithMount({
            paymentStatus: data.data
          })
        }
      })
      .catch(error => {
      });

  }

  setStateWithMount = (data, cb = null) => {
    if (this._isMounted) {
      if (cb !== null) {
        this.setState(data, cb);
      } else {
        this.setState(data);
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadTransactions();

    this.getPaymentStatus();


    const params = new URLSearchParams(this.props.location.search);
    const orderId = params.get("orderID");
    const status = params.get("status");
    const amount = params.get("amount");
    const type = params.get("type");

    if (type === "deposit" && status && orderId) {
      this.setStateWithMount({
        statusDialog: {
          enabled: true,
          orderId,
          status,
          type,
          amount
        }
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  loadTransactions = () => {
    const { user } = this.props;

    axios
      .get(config.API_URL + `/transactions?type_id=[1,2,10,11]`,
        {
          headers: {
            Authorization: user.token
          }
        }
      )
      .then(result => {
        const data = result.data;
        this.setStateWithMount({
          transactions: data.data.transactions,
          isLoading: false
        })
      })
      .catch(error => {
        // console.log(error);
        this.setStateWithMount({
          isLoading: false
        })
      });
  }

  depositMoney = () => {

    let { amount, isDepositLoading, cardType } = this.state;
    const { user, t } = this.props;

    if (!isDepositLoading) {

      if (!amount) {
        this.setStateWithMount({ amountError: t('error.emptyDepositAmount'), amountInvalid: true })
        return
      } else if (amount < 2) {
        this.setStateWithMount({ amountError: t('error.wrongDepositAmount'), amountInvalid: true })
        return
      }

      this.setStateWithMount({ isDepositLoading: true })

      axios
        .get(config.API_URL + `/payment/deposit?amount=${amount}&cardType=${cardType}`,
          {
            headers: {
              Authorization: user.token
            }
          }
        )
        .then(result => {
          const data = result.data;
          if (data.status === "success") {
            const forwardURL = data.data.forwardUrl;
            window.open(forwardURL, "_self")
          } else {
            this.setStateWithMount({
              isLoading: false,
              isDepositLoading: false
            })
          }
        })
        .catch(error => {
          this.setStateWithMount({
            isLoading: false,
            isDepositLoading: false
          })
        });
    }

  }

  setAmount = (amount) => {
    return () => {
      this.setStateWithMount({
        amount,
        amountInvalid: false,
        amountError: ''
      })
    }
  }

  groupByDate = (transactions) => {
    const transactionsByDate = [];
    let groupIndex = 0;
    transactions.forEach((transaction, index) => {
      if (index !== 0) {
        if (transaction.transfer_date === transactionsByDate[groupIndex].transfer_date) {
          transactionsByDate[groupIndex].transactions.push(transaction)
        } else {
          transactionsByDate.push({
            transfer_date: transaction.transfer_date,
            transactions: [transaction]
          })
          groupIndex++;
        }

      } else {
        transactionsByDate.push({
          transfer_date: transaction.transfer_date,
          transactions: [transaction]
        })
      }
    })


    return transactionsByDate;
  }

  renderTransactionSection = (transactions) => {
    const { t } = this.props

    transactions = this.groupByDate(transactions);
    const currentDate = moment(new Date()).format("DD/MM/YYYY")
    const yesterday = moment().subtract(1, 'days').format("DD/MM/YYYY");
    if (transactions.length === 0) {
      return <NotFound color="#713198" />
    } else {
      return transactions.map((item, index) => {
        const date = item.transfer_date === currentDate ? t("wallet.today") : item.transfer_date === yesterday ? t("wallet.yesterday") : item.transfer_date;
        return (
          <div key={index}>
            <span style={{ color: "#713198", fontWeight: 700 }}>{date}</span>
            {this.renderTransactions(item.transactions)}
          </div>
        )
      })
    }

  }

  renderStatusDialog = () => {
    const { t } = this.props;
    let { statusDialog } = this.state


    let dialogContent;

    if (statusDialog.enabled) {

      if (statusDialog.type === "deposit") {
        if (statusDialog.status === "success") {
          dialogContent = (
            <div className='d-flex flex-column align-items-center'>
              <Trans i18nKey="wallet.successMessage">
                <span style={{ fontWeight: 500 }}>Təşəkkür edirik</span>
                <span style={{ textAlign: "center" }}>Sizin chekiniz uğurla yüklendi</span>
              </Trans>

            </div>
          );
        } else if (statusDialog.status === "fail") {
          dialogContent = (
            <div className='d-flex flex-column align-items-center'>
              <Trans i18nKey="wallet.failMessage">
                <span style={{ fontWeight: 500 }}>Təşəkkür edirik</span>
                <span style={{ textAlign: "center" }}>Sizin chekiniz uğurla yüklendi</span>
              </Trans>
            </div>
          )
        }
      } else {
        if (statusDialog.status === "success") {
          dialogContent = (
            <div className='d-flex flex-column align-items-center'>
              <Trans i18nKey="wallet.successfulTransaction">
                <span style={{ fontWeight: 500 }}>Təşəkkür edirik</span>
                <span style={{ textAlign: "center" }}>Sizin chekiniz uğurla yüklendi</span>
              </Trans>
            </div>
          );
        } else if (statusDialog.status === "fail") {
          dialogContent = (
            <div className='d-flex flex-column align-items-center'>
              <span style={{ fontWeight: 500 }}>{t('wallet.unsuccessfulTransaction')}</span>
              <span style={{ textAlign: "center" }}>{t('error.withdrawFailed')}</span>
            </div>
          )
        } else if (statusDialog.status === "pending") {
          dialogContent = (
            <div className='d-flex flex-column align-items-center'>
              <Trans i18nKey="wallet.pendingTransaction">
                <span style={{ fontWeight: 500 }}>Təşəkkür edirik</span>
                <span style={{ textAlign: "center" }}>Sizin chekiniz uğurla yüklendi</span>
              </Trans>
            </div>
          )
        }
      }


      return (
        <Dialog
          isOpen={statusDialog.enabled}
          cancelText={t("dialog.close")}
          onCancelClick={() => {
            statusDialog.enabled = false
            this.setStateWithMount({ statusDialog })
          }}
          toggle={() => {
            statusDialog.enabled = false
            this.setStateWithMount({ statusDialog })
          }}
          t={t}
        >
          <div className='d-flex flex-column align-items-center p-2'>
            {<img src={images.status[statusDialog.status]} style={{ height: 37, width: 37 }} alt={statusDialog.status} />}
            {dialogContent}
          </div>
        </Dialog>
      )
    }

  }




  renderDepositDialog = () => {
    const { t } = this.props;
    let { depositDialog, cardType, isDepositLoading, showDepositWarning, paymentStatus } = this.state

    const { deposit } = paymentStatus;


    let dialogContent, acceptText, acceptClick, title;

    if (showDepositWarning) {

      title = 'Məlumat'

      acceptClick = () => {
        this.setStateWithMount({ showDepositWarning: false })
      }

      acceptText = 'Davam et'

      dialogContent = (<div>
        <div>{'Ödənişlərlə bağlı yaranmış müvəqqəti çətinliklərə görə sizdən üzr istəyirik. Çətinliklərin tez bir zamanda aradan qaldırılması üçün işlər görülür. Bu səbəbdən ödənişlərdə gecikmə halları baş verə bilər.'}</div>
        <div style={{ fontWeight: 'bold' }}>{'Şikəyətləriniz olduqda bizə chata yazın. Anlayışınız üçün təşəkkür edirik 🙂'}</div>
      </div>)
    } else {

      if (cardType === "") {
        title = t('wallet.selectCardType')
        acceptText = ""
        dialogContent = (
          <div className='d-flex flex-row p-2'>
            {deposit.card_type_list.includes('visa') &&
              <div className='d-flex flex-column align-items-center  justify-content-around border rounded p-3 pb-0 mr-1' onClick={() => {
                this.setStateWithMount({ cardType: "visa" })
              }}>
                <img className='w-100 p-3' src={images.cardType.visa} alt="visa" style={{ maxWidth: 170 }} />
                <span className='pt-2 card-type-brand'>Visa</span>
              </div>
            }
            {deposit.card_type_list.includes('mc') &&
              <div className='d-flex flex-column align-items-center border rounded p-3 pb-0 ml-1' onClick={() => {
                this.setStateWithMount({ cardType: "mc" })
              }}>
                <img className='h-100 w-100 p-3' src={images.cardType.mastercard} alt="mc" style={{ maxWidth: 170 }} />
                <span className='pt-2 card-type-brand'>Master Card</span>
              </div>
            }
          </div>
        );
      } else {
        title = t('wallet.inputAmount')
        dialogContent = (
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row money-box-container'>
              <Button className='btn-outline' onClick={this.setAmount(5)}>5.00<Manat width={10} style={{ margin: 3 }} /></Button>
              <Button className='btn-outline' onClick={this.setAmount(25)}>25.00<Manat width={10} style={{ margin: 3 }} /></Button>
              <Button className='btn-outline' onClick={this.setAmount(50)}>50.00<Manat width={10} style={{ margin: 3 }} /></Button>
              <Button className='btn-outline' onClick={this.setAmount(100)}>100.00<Manat width={10} style={{ margin: 3 }} /></Button>
              <Button className='btn-outline' onClick={this.setAmount(250)}>250.00<Manat width={10} style={{ margin: 3 }} /></Button>
              <Button className='btn-outline' onClick={this.setAmount(500)}>500.00<Manat width={10} style={{ margin: 3 }} /></Button>
            </div>
            <Form className="wallet-form p-1" >
              <FormGroup>
                <Label for="amount">{this.props.t("wallet.amount")}</Label>
                <Input
                  type="text"
                  style={{ marginBottom: 0, fontWeight: 500 }}
                  name="amount"
                  suffix=" ₼"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  allowNegative={false}
                  isNumericString={true}
                  invalid={this.state.amountInvalid}
                  value={this.state.amount}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setStateWithMount({ amount: value, amountError: '', amountInvalid: false });
                  }}
                  tag={NumberFormat}
                  placeholder={"0.00 ₼"}
                />
                <FormFeedback>{this.state.amountError}</FormFeedback>

              </FormGroup>

              {/* <div style={{ paddingTop: 5, fontWeight: 400 }}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 6 }} width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg>
                <span style={{ color: "red", fontWeight: "bold" }}>Leobank</span> kartlarında bank tərəfindən əlavə olaraq <b>2.00 manat</b> kommisiya tutulur.
                 </div> */}

            </Form>
          </div>
        )

        acceptClick = this.depositMoney
        acceptText = t("wallet.deposit")
      }

    }


    return (
      <Dialog
        title={title}
        isOpen={depositDialog}
        cancelText={t("dialog.close")}
        acceptText={acceptText}
        onAcceptClick={acceptClick}
        isAcceptLoading={isDepositLoading}
        onCancelClick={() => {
          this.setStateWithMount({ depositDialog: false, isDepositLoading: false })
        }}
        toggle={() => {
          this.setStateWithMount({ depositDialog: false, isDepositLoading: false })
        }}
        t={t}
      >
        {dialogContent}
      </Dialog>
    )
  }



  renderTransactions = (transactions) => {

    return transactions.map((transaction, index) => {
      return (
        <Transaction
          onClick={() => {
            const typeId = transaction.type_id;
            let status = "";
            switch (typeId) {
              case 1: status = "success"; break;
              case 2: status = "success"; break;
              case 10: status = "pending"; break;
              case 11: status = "fail"; break;
              default: status = "";
            }
            this.setStateWithMount({
              statusDialog: {
                enabled: true,
                type: typeId === 1 ? "deposit" : "withdraw",
                status
              }
            })
          }}
          t={this.props.t}
          key={index}
          {...transaction}
        />
      )
    })
  }

  render() {
    const { transactions, isLoading, paymentStatus } = this.state;
    const { t, history, user } = this.props;

    const { withdraw, deposit } = paymentStatus;

    return (
      <PaymentContainer backText="Back" title={t("wallet.wallet")} history={history} t={t} isLoading={isLoading} onBack={() => { this.props.history.push("/") }}>
        <div className='px-3'>
          {this.renderDepositDialog()}
          {this.renderStatusDialog()}
          <div className='chat-box' onClick={() => { this.props.history.push("/chat") }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24.294" viewBox="0 0 26 24.294">
              <path id="Icon_simple-hipchat" data-name="Icon simple-hipchat" d="M21.381,20.972s.112-.079.289-.215A10.593,10.593,0,0,0,26,12.4C26,6.2,20.179,1.181,13,1.181S0,6.2,0,12.4,5.819,23.614,13,23.614a14.908,14.908,0,0,0,2.734-.247l.284-.049a13.53,13.53,0,0,0,6.812,2.156.7.7,0,0,0,.6-1.2,13.158,13.158,0,0,1-2.045-3.306Zm-1.584-4.9a8.4,8.4,0,0,1-6.775,3.13h-.05A8.414,8.414,0,0,1,6.2,16.069a1.232,1.232,0,0,1-.3-.622.531.531,0,0,1,.484-.57.058.058,0,0,1,.023,0,.681.681,0,0,1,.355.12A9.9,9.9,0,0,0,13,17.221a9.539,9.539,0,0,0,6.24-2.231.5.5,0,0,1,.339-.132.523.523,0,0,1,.523.515,1.431,1.431,0,0,1-.29.7H19.8Z" transform="translate(0 -1.181)" fill="#fff" />
            </svg>
            <span>{t("wallet.contactUs")}</span>
          </div>
          <div className="wallet bg-gradient-purple p-3 px-3 position-relative" >
            <div className="d-flex flex-column pb-2">
              <span style={{ fontWeight: 500 }}>{user.name}</span>
              <span className="d-flex align-items-center py-2 pt-3 " style={{ fontSize: 25, fontWeight: 500 }}>{user.balance} <Manat height={17} style={{ marginLeft: 4 }} /></span>
            </div>
            <img src={images.logoWhite} style={{ position: "absolute", height: 30, right: 20, top: 20 }} className="logo" alt="" />

            <div className="d-flex flex-row justify-content-center">
              <ButtonWithSVG text={t("wallet.deposit")} disabled={!deposit.enabled} className="btn-wallet" iconDirection="right" onClick={() => {
                // this.setStateWithMount({ depositDialog: true, cardType: "", amount: 10, showDepositWarning: true })
                this.props.history.push("/deposit")
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.6601 1.66023C9.97698 0.697205 7.98141 0.438449 6.1083 0.940348C4.23519 1.44225 2.63635 2.66412 1.6602 4.3397C0.799972 5.83997 0.485804 7.59183 0.771099 9.29753C1.05639 11.0032 1.92354 12.5575 3.22516 13.6962L6.82509 7.46093L5.65293 7.12534C5.56774 7.10099 5.49219 7.05086 5.43666 6.98183C5.38113 6.9128 5.34834 6.82826 5.34281 6.73984C5.33727 6.65142 5.35926 6.56345 5.40575 6.48804C5.45225 6.41262 5.52096 6.35346 5.60244 6.31868L9.54664 4.63485C9.60804 4.61152 9.67386 4.60215 9.73934 4.60741C9.80481 4.61268 9.86829 4.63245 9.92517 4.66529C9.98205 4.69813 10.0309 4.74322 10.0682 4.79729C10.1055 4.85135 10.1303 4.91304 10.1408 4.97788L10.6547 9.23558C10.6653 9.32353 10.6484 9.41262 10.6063 9.49059C10.5643 9.56856 10.4991 9.63159 10.4197 9.67101C10.3404 9.71042 10.2508 9.7243 10.1632 9.71072C10.0757 9.69714 9.9945 9.65678 9.93083 9.59518L9.0292 8.73347L5.42928 14.9687C7.06821 15.5295 8.8506 15.5064 10.4745 14.9035C12.0984 14.3005 13.4638 13.1547 14.3396 11.6602C15.3026 9.977 15.5614 7.98144 15.0595 6.10833C14.5576 4.23521 13.3357 2.63638 11.6601 1.66023V1.66023Z" fill={deposit.enabled ? "#59C99E" : "white"} />
                </svg>
              </ButtonWithSVG>
              <div className="mx-2" />
              <ButtonWithSVG text={t("wallet.withdraw")} className="btn-wallet" disabled={!withdraw.enabled} iconDirection="right" onClick={() => {
                this.props.history.push("/withdraw")
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.33967 14.3396C2.66393 13.3636 1.4419 11.7647 0.939906 9.89154C0.437912 8.01835 0.696644 6.02268 1.65972 4.33943C2.52893 2.84426 3.8891 1.69624 5.50902 1.09051C7.12895 0.484776 8.90865 0.45872 10.5456 1.01677L6.94569 7.25202L6.06898 6.40469C6.00528 6.34244 5.92376 6.30158 5.83577 6.28778C5.74778 6.27398 5.65767 6.28792 5.57796 6.32767C5.49825 6.36742 5.4329 6.431 5.39098 6.50959C5.34907 6.58818 5.33266 6.67787 5.34405 6.76621L5.85791 11.0239C5.8684 11.0887 5.8932 11.1504 5.93049 11.2045C5.96779 11.2586 6.01665 11.3037 6.07353 11.3365C6.13042 11.3693 6.1939 11.3891 6.25937 11.3944C6.32484 11.3996 6.39066 11.3903 6.45206 11.3669L10.3943 9.682C10.4758 9.64723 10.5445 9.58806 10.591 9.51265C10.6375 9.43723 10.6595 9.34927 10.654 9.26085C10.6484 9.17242 10.6157 9.08789 10.5601 9.01886C10.5046 8.94983 10.429 8.89969 10.3439 8.87535L9.14678 8.52537L12.7478 2.28821C14.0535 3.42699 14.9253 4.9822 15.2154 6.69026C15.5055 8.39832 15.1961 10.1542 14.3396 11.6602C13.3635 13.3358 11.7646 14.5576 9.89151 15.0595C8.0184 15.5614 6.02283 15.3027 4.33967 14.3396Z" fill={withdraw.enabled ? "#FD4343" : "white"} />
                </svg>
              </ButtonWithSVG>
            </div>
          </div>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => { console.log("load more") }}
            hasMore={false}
            loader={<div className="loader" key={0}>Loading ...</div>}
          >
            <div className="transactions  overflow-auto" style={{ paddingBottom: 100 }}>
              {this.renderTransactionSection(transactions)}
            </div>

          </InfiniteScroll>

        </div>
      </PaymentContainer>
    )
  }
}

const Transaction = (props) => {
  let { transaction_id, type_id, amount, transfer_time, onClick, t } = props;


  let type = t("wallet.withdrawing")
  let icon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.33967 14.3396C2.66393 13.3636 1.4419 11.7647 0.939906 9.89154C0.437912 8.01835 0.696644 6.02268 1.65972 4.33943C2.52893 2.84426 3.8891 1.69624 5.50902 1.09051C7.12895 0.484776 8.90865 0.45872 10.5456 1.01677L6.94569 7.25202L6.06898 6.40469C6.00528 6.34244 5.92376 6.30158 5.83577 6.28778C5.74778 6.27398 5.65767 6.28792 5.57796 6.32767C5.49825 6.36742 5.4329 6.431 5.39098 6.50959C5.34907 6.58818 5.33266 6.67787 5.34405 6.76621L5.85791 11.0239C5.8684 11.0887 5.8932 11.1504 5.93049 11.2045C5.96779 11.2586 6.01665 11.3037 6.07353 11.3365C6.13042 11.3693 6.1939 11.3891 6.25937 11.3944C6.32484 11.3996 6.39066 11.3903 6.45206 11.3669L10.3943 9.682C10.4758 9.64723 10.5445 9.58806 10.591 9.51265C10.6375 9.43723 10.6595 9.34927 10.654 9.26085C10.6484 9.17242 10.6157 9.08789 10.5601 9.01886C10.5046 8.94983 10.429 8.89969 10.3439 8.87535L9.14678 8.52537L12.7478 2.28821C14.0535 3.42699 14.9253 4.9822 15.2154 6.69026C15.5055 8.39832 15.1961 10.1542 14.3396 11.6602C13.3635 13.3358 11.7646 14.5576 9.89151 15.0595C8.0184 15.5614 6.02283 15.3027 4.33967 14.3396Z" fill="#FD4343" />
  </svg>

  if (type_id === 1) {
    type = t("wallet.deposited")
    icon =
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6601 1.66023C9.97698 0.697205 7.98141 0.438449 6.1083 0.940348C4.23519 1.44225 2.63635 2.66412 1.6602 4.3397C0.799972 5.83997 0.485804 7.59183 0.771099 9.29753C1.05639 11.0032 1.92354 12.5575 3.22516 13.6962L6.82509 7.46093L5.65293 7.12534C5.56774 7.10099 5.49219 7.05086 5.43666 6.98183C5.38113 6.9128 5.34834 6.82826 5.34281 6.73984C5.33727 6.65142 5.35926 6.56345 5.40575 6.48804C5.45225 6.41262 5.52096 6.35346 5.60244 6.31868L9.54664 4.63485C9.60804 4.61152 9.67386 4.60215 9.73934 4.60741C9.80481 4.61268 9.86829 4.63245 9.92517 4.66529C9.98205 4.69813 10.0309 4.74322 10.0682 4.79729C10.1055 4.85135 10.1303 4.91304 10.1408 4.97788L10.6547 9.23558C10.6653 9.32353 10.6484 9.41262 10.6063 9.49059C10.5643 9.56856 10.4991 9.63159 10.4197 9.67101C10.3404 9.71042 10.2508 9.7243 10.1632 9.71072C10.0757 9.69714 9.9945 9.65678 9.93083 9.59518L9.0292 8.73347L5.42928 14.9687C7.06821 15.5295 8.8506 15.5064 10.4745 14.9035C12.0984 14.3005 13.4638 13.1547 14.3396 11.6602C15.3026 9.977 15.5614 7.98144 15.0595 6.10833C14.5576 4.23521 13.3357 2.63638 11.6601 1.66023V1.66023Z" fill="#59C99E" />
      </svg>
  }

  let statusIcon = <img src={images.status.success} alt="success" style={{ height: 18, width: 18 }} />

  if (type_id === 10) {
    statusIcon = <img src={images.status.pending} alt="pending" style={{ height: 18, width: 18 }} />

  } else if (type_id === 11) {
    statusIcon = <img src={images.status.fail} alt="fail" style={{ height: 18, width: 18 }} />
  }

  // statusIcon = ""

  return (
    <div className="d-flex text-dark justify-content-between p-2 mb-2 px-3" style={{ background: "#F9F9F9", borderRadius: 10, cursor: "pointer" }} onClick={onClick}>
      <div className="d-flex flex-column">
        <span style={{ fontWeight: 700 }}> {icon} {type}</span>
        <span style={{ fontSize: 12, color: "#727272" }}>ID: {transaction_id}</span>
      </div>
      <div className="d-flex flex-column align-items-end justify-content-center">
        <span className="d-flex align-items-center" style={{ fontWeight: 700 }}>{amount}<Manat color="black" />{statusIcon}</span>
        <span style={{ fontSize: 12, color: "#727272" }}>{transfer_time}</span>
      </div>
    </div>
  )
}

export default withContext(Wallet);