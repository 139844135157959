// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AAAIzaSyDKRgqfBbAwkCLYSidiEn94_0jmptihsA4",
  authDomain: "legi10.firebaseapp.com",
  projectId: "legi10",
  storageBucket: "legi10.appspot.com",
  messagingSenderId: "62197363099",
  appId: "1:62197363099:web:a3ba90b7161f87b83f713e",
  measurementId: "G-G1LVCR4PGD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const fetchToken = () => {
  return getToken(messaging, {vapidKey: 'BBnUsJetTETdXCQG2nR2X0G5InCc2QHiEPo5MUx6iXsZTsj9I8kpqjrABiv4RhiES3w0Z0jUPRVlLNy_sbefE2E'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});