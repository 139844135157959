import React, { Component } from "react";
import Cell from "./Cell";

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.onTicketClick = this.onTicketClick.bind(this);
  }

  handleCellClick(x, y) {
    if (this.props.isGame) {
      if (this.props.onCellClick !== null) {
        this.props.onCellClick(x, y);
      }
    }
  }

  handleCellChange(x, y, number) {
    if (this.props.onCellChange !== null) {
      this.props.onCellChange(x, y, number);
    }
  }

  onTicketClick(event) {
    if (!this.props.isGame) {
      if (this.props.onTicketClick !== null) {
        this.props.onTicketClick();
      }
    }
  }
  renderTicket(data) {
    const { rowErrors = [] } = this.props;
    return data.map((datarow, index) => {
      return (
        <div className={`ticket-row ${rowErrors.includes(index) ? 'invalid' : ''}`} key={index * 1000}>
          {" "}
          {datarow.map(dataitem => {
            let disabled = true;
            if (!dataitem.isEmpty) {
              disabled = !this.props.isGame;
            }

            return (
              <Cell
                key={dataitem.x * datarow.length + dataitem.y}
                value={dataitem.value}
                data={dataitem}
                editable={this.props.editable}
                disabled={disabled}
                onClick={() => this.handleCellClick(dataitem.x, dataitem.y)}
                onCellChange={(number) => this.handleCellChange(dataitem.x, dataitem.y, number)}
              // onClick = {this.props.onClick}
              />
            );
          })}
        </div>
      );
    });
  }

  render() {
    let className =
      "ticket " + this.props.size + (this.props.selected ? " selected" : "");
    className += this.props.clickable ? " clickable" : "";
    return (
      <div className={`${className} ${this.props.editable ? 'editable' : ''}`} onClick={this.onTicketClick}>
        <div className="ticket-border1">
          <div className="ticket-border2">
            {this.renderTicket(this.props.ticketData)}
          </div>
        </div>
      </div>
    );
  }
}

Ticket.defaultProps = {
  isGame: true,
  onCellClick: null,
  onTicketClick: null,
  size: ""
};

export default Ticket;
