import React, { Component } from 'react'
import { images } from "config"
import { FooterPages } from 'components/Views'

export default class Privacy extends Component {
  render() {
    return (
      <div className="w-100 h-100 d-flex flex-fill flex-column pt-3">
        <img src={images.logoWhite} style={{ height: 40, marginBottom: 20 }} alt="" />
        <div className="h-100 rounded-lg bg-white text-dark overflow-auto p-3 p-md-5 m-2">
          <div _ngcontent-cvt-c280="" className="policy">
            <span _ngcontent-cvt-c280="" className="mat-h1">Company Policy</span>
            <div _ngcontent-cvt-c280="" className="data-list">
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="TitleUnderline0"></div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="Article1">
                <h3>In&nbsp;order to&nbsp;show our firm commitment to&nbsp;strong business practices milli10.com publishes this Privacy Statement.</h3>
                <p>
                  <br/>
                </p>
                <h3>Here at&nbsp;milli10.com, we&nbsp;believe that good business relationships are built over time by&nbsp;way of&nbsp;honesty and trust. That is&nbsp;why the privacy of&nbsp;your information is&nbsp;extremely important for us. This means that we&nbsp;are committed to&nbsp;protecting your privacy so&nbsp;that your experience with milli10.com is&nbsp;a&nbsp;pleasant and secure one.</h3>
                <p>
                  <br/>
                </p>
                <h2>Secured Transactions</h2>
                <p>
                  <br/>
                </p>
                <h3>You can rest assured that we&nbsp;employ all possible measures to&nbsp;provide you with secure money transactions. We&nbsp;use security connections SSL, certified by&nbsp;Secure Server Certification Authority GoDaddy. The site has security measures in&nbsp;place to&nbsp;protect the loss, misuse and alteration of&nbsp;any information you give us.</h3>
                <p>
                  <br/>
                </p>
                <h3>Winnings&nbsp;— your winnings and&nbsp;cash-outs&nbsp;are kept strictly confidential, and winnings information is&nbsp;stored in&nbsp;secure operating environments. milli10.com keeps your winnings information private and does not provide this information to&nbsp;any third party unless such information is&nbsp;required to&nbsp;be&nbsp;disclosed by&nbsp;law, regulation or&nbsp;any governmental or&nbsp;competent regulatory authority.</h3>
                <p>
                  <br/>
                </p>
                <h3>Credit Card Security&nbsp;— using your Credit Card at&nbsp;milli10.com is&nbsp;perfectly safe. CVC utilizes sophisticated RSA public/private key encryption technology to&nbsp;ensure that sensitive data is&nbsp;transferred securely over the Internet. All Credit Card details are stored on&nbsp;a&nbsp;secure server, which is&nbsp;protected by&nbsp;the latest firewall system. This means that using your Credit Card at&nbsp;milli10.com is&nbsp;even safer than using it&nbsp;to&nbsp;order food at&nbsp;your local restaurant.</h3>
                <p>
                  <br/>
                </p>
                <h2>Personal Information</h2>
                <p>
                  <br/>
                </p>
                <h3>Our policy at&nbsp;milli10.com is&nbsp;that all personal information you provide, such as&nbsp;your name, postal address,&nbsp;e-mail&nbsp;address, telephone number, date of&nbsp;birth, sex, IP&nbsp;address is&nbsp;private and confidential and will not be&nbsp;shared, rented, sold or&nbsp;distributed in&nbsp;any manner to&nbsp;any other person, company or&nbsp;organization.</h3>
                <p>
                  <br/>
                </p>
                <h2>Anti-Spam&nbsp;Policy</h2>
                <p>
                  <br/>
                </p>
                <h3>milli10.com operates a&nbsp;strict&nbsp;anti-spam&nbsp;policy. All emails sent to&nbsp;our players are legitimate and are in&nbsp;regard to&nbsp;the players account, and to&nbsp;inform them of&nbsp;opportunities open to&nbsp;them like casino promotions, draws and tournaments. The frequency of&nbsp;promo mails may vary and reach up&nbsp;to&nbsp;3 emails per day. All players reserve the right to&nbsp;request that these emails be&nbsp;stopped, and are to&nbsp;contact us&nbsp;directly for this to&nbsp;be&nbsp;fulfilled or&nbsp;unsubscribe by&nbsp;clicking the link in&nbsp;the bottom of&nbsp;an&nbsp;email. We&nbsp;operate using IP&nbsp;addresses to&nbsp;help diagnose problems with our servers. Your IP&nbsp;address is&nbsp;used to&nbsp;help identify you and your wagering account.</h3>
                <p>
                  <br/>
                </p>
                <h2>Cookie Policy</h2>
                <p>
                  <br/>
                </p>
                <h3>Cookies are small files that a&nbsp;site or&nbsp;its service provider transfers to&nbsp;your computer‘s&nbsp;hard drive through your Web browser (if&nbsp;you allow) that enables the sites or&nbsp;service providers systems to&nbsp;recognize your browser and capture and remember certain information.</h3>
                <p>
                  <br/>
                </p>
                <h2>Your Consent</h2>
                <p>
                  <br/>
                </p>
                <h3>By&nbsp;using our site, you consent to&nbsp;our website privacy policy.</h3>
                <p>
                  <br/>
                </p>
                <h2>Changes to&nbsp;our Privacy Policy</h2>
                <p>
                  <br/>
                </p>
                <h3>This Privacy Policy is&nbsp;subject to&nbsp;change, so&nbsp;please check it&nbsp;regularly. We&nbsp;reserve the right to&nbsp;amend or&nbsp;modify this Privacy Policy at&nbsp;any time and in&nbsp;response to&nbsp;changes in&nbsp;applicable data protection and privacy legislation. Any changes to&nbsp;our Policy can be&nbsp;found on&nbsp;this page. If&nbsp;you have any other questions or&nbsp;concerns regarding data privacy and security please do&nbsp;not hesitate to&nbsp;contact our friendly Customer Support Team or&nbsp;send your questions to&nbsp; <a href="mailto:support@milli10.com" target="_blank" rel="noopener noreferrer">support@milli10.com</a>. Our representatives will be&nbsp;more than happy to&nbsp;assist you. </h3>
                <h3>The services and the Content on the Website are provided by: means COLDMINDS SOLUTION LTD, a company registered in United Kingdom.</h3>
              </div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="TitleUnderline2"></div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="Article3">
                <h3>Responsible game – one of the priorities of the milli10.com. For us it is important that each player enjoys the game process, knowing the risks and consequences, and does not face the problem of gambling addiction.</h3>
                <h3>&nbsp;</h3>
                <h2>Maintaining control</h2>
                <h3>&nbsp;</h3>
                <h3>Playing in milli10.com should be seen as entertainment and leisure, not as earning money. Unfortunately, today the problem of gambling addiction is getting more acute and many people get into a situation when they can not stop chasing the win and jeopardizing not only financial but also psychological state. In order to control gambling passion we strongly advise you to consider the following recommendations:</h3>
                <div className="list">
                  <div className="list__item">Playing in a casino should be moderate and considered as a form of leisure, but not making money.</div>
                  <div className="list__item">Do not try to win back the money immediately after a loss. Do it being calm.</div>
                  <div className="list__item">Play only when you can cover the losses. Never borrow money for gambling.</div>
                  <div className="list__item">Monitor time and money spent on the game.</div>
                </div>
                <h3>If you need to limit gambling, please contact our casino support team and we will restrict your access for a while.</h3>
                <h3>If you need advice or assistance concerning gambling addiction and other problems that result from gambling, please contact one of the following organizations.</h3>
                <h3>&nbsp;</h3>
                <h2>Do you have a problem?</h2>
                <h3>&nbsp;</h3>
                <h3>If you are worried that gambling can have a negative impact on your life or the lives of your close people, please answer the following questions. They will help you to determine the extent of gambling addiction:</h3>
                <div className="list">
                  <div className="list__item">Do gambling distract you from working or studying?</div>
                  <div className="list__item">Do you gamble to escape from problems or to avoid boredom?</div>
                  <div className="list__item">Do you prefer playing alone to playing group games?</div>
                  <div className="list__item">Have you ever been blamed for gambling?</div>
                  <div className="list__item">Does gambling affect your relationships with family and friends?</div>
                  <div className="list__item">Do you hide gambling from close people?</div>
                  <div className="list__item">Have you ever borrowed money to gamble?</div>
                  <div className="list__item">Once you have money, you will immediately spend it on the game?</div>
                  <div className="list__item">Do you gamble until you lose everything?</div>
                  <div className="list__item">Have you ever had the desire to win back money immediately after a loss?</div>
                  <div className="list__item">Do you have the feeling of despondency and despair, when you run out of money while playing games?</div>
                  <div className="list__item">How often do you play being depressed?</div>
                  <div className="list__item">Does your playing in a casino cause depressive or suicidal thoughts?</div>
                </div>
                <h3>The more you gave affirmative answers to the questions, the more your addiction to gambling is, that must be solved by yourself or with the help of specialists.</h3>
                <h3>&nbsp;</h3>
                <h2>Self-exclusion</h2>
                <h3>&nbsp;</h3>
                <h3>If you think that you have a gambling problem and need a break from gambling, you can resort to the procedure of temporary access restriction by contacting our support team to support@milli10.com. The access to your account will be closed for a period of at least 3 months, but you have to remember, we can reactivate it according to your request. If you want the full access restriction – wright us about it and we will delete your account from the system. You can also request freeze of your game account for a period of 7 days. If you decide to resort to self-exclusion procedure, please contact all the online casinos, where you are registered, and ask them to block your accounts as well. We also strongly recommend that you install the software, which will allow to block access to gambling websites. At the bottom of the page there is a list of filtration systems best suited for this purpose.</h3>
                <h3>&nbsp;</h3>
                <h2>Gambling counseling organisations</h2>
                <h3>&nbsp;</h3>
                <h3>GamCare – the leading authoritative association, providing information and consulting services, as well as real help in solving the problem of gambling. Confidential Helpline: 0845 133 6000. Website Organization: www.gamcare.org.uk</h3>
                <h3>GamblingTherapy – online service providing psychological assistance and counseling for those affected by gambling. Support is provided throughout the world. The organization’s website: www.gamblingtherapy.org</h3>
                <h3>Gamblers Anonymous – a society of people who have united to support each other and to help other players in the solution of problems associated with gambling. Offices in different countries. The organization’s website: www.gamblersanonymous.org.uk</h3>
                <h3>&nbsp;</h3>
                <h2>Underage gambling</h2>
                <h3>&nbsp;</h3>
                <h3>The registration and participation of persons under the age of 18 in the games at milli10.com are prohibited. milli10.com enforces this rule and carries out regular procedures of checking the age of clients who use payment systems. In the case of violation of this rule the player’s account will be blocked and any winnings will be forfeited.</h3>
                <h3>&nbsp;</h3>
                <h2>Filtration systems</h2>
                <h3>&nbsp;</h3>
                <h3>Filtration systems allow you to limit access to sites with gambling, that is useful for parents who have children as well as for players suffering from gambling addiction problems. We recommend the following filtration systems: CyberPatrol – www.cyberpatrol.com, Net Nanny – www.netnanny.com</h3>
              </div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="TitleUnderline4"></div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="Article5">
                <h3>milli10.com understands the importance of client’s confidence in the integrity of services and works with the most famous game companies, using the most reliable software and the latest technology to ensure fair play.</h3>
                <p>
                  <br/>
                </p>
                <h3>In milli10.com games are provided by companies with a worldwide reputation and many years’ experience. They are leaders in the market of game software: Microgaming, NetEnt and Evolution Gaming. Being one of the founders of eCOGRA and IGC, Microgaming company creates the best games for over 10 years, placing the highest demands not only on the quality of services, but also on the fairness of the games offered, as well as on security and confidentiality of the players.</h3>
                <p>
                  <br/>
                </p>
                <h3>milli10.com is proud of its reputation and wide recognition among players worldwide. It is important for us to know that every client enjoys the game process and is sure that the casino respects and protects their interests, ensuring integrity and safety of the game.</h3>
                <p>
                  <br/>
                </p>
                <h3>All games presented on the website milli10.com are periodically tested on fair play and payments statistics by the international organization eCOGRA. In addition, the game data and results are encrypted via integrity monitoring system and a digital signature that guarantees non-interference in the game process. The use of a random number generator and complex mathematical algorithms ensures objectivity, independence and randomness of the game.</h3>
                <p>
                  <br/>
                </p>
                <h3>In addition, the player may at any time get acquainted with the statistics of transactions and bets in the account, that are free and available in your account.</h3>
              </div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="TitleUnderline6"></div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="Article7">
                <h3>Use Of Cookies on the website</h3>
                <h3>
                  <br/>
                </h3>
                <h3>The Company uses ‘cookies’ in order to provide a certain functionality of the Website. A cookie is a small text file that is placed on your computer when you access the Website, which allows us to recognize you when you come back to the Website. More information about deleting or controlling cookies is available at&nbsp; <a href="https://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer">www.aboutcookies.org</a>. </h3>
                <h3>
                  <br/>
                </h3>
                <h3>!Please note that by deleting our cookies or disabling cookies you may not be able to access the certain areas or use the certain features of the Website.</h3>
              </div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="TitleUnderline8"></div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="Article9">
                <h3>By clicking the «Add Deposite» button User expresses full consent to this Payment Policy and Terms and Conditions of payment service provider and authorize payment service provider to execute payments automatically charge form User’s credit card for pay for all goods and/or services or other additional services he/she ordered on the Platform/Website including, but not limited to all possible taxes, charges, etc.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>User acknowledges and agree that confirmation notification of the One-Click Transaction will be provided within 2 (two) business days using agreed method of communication.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>User certifies that he/she is an authorized user of credit card, details provided in One-Click Transaction Form, and that he/she will not dispute the scheduled payments with his/her credit card company provided the transactions correspond to the terms indicated in Terms and Conditions of the payment service provider, Terms and Conditions and this Payment Policy.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>After clicking the «Add Deposite» button the transaction is irrevocably deemed to be processed and executed. After clicking the «Add Deposite» button User agrees that he/she will not be eligible to cancel the payment or request to cancel it. By placing the order on the Website, User confirms and states that he/she does not violate legislation of any country. Also, by accepting these rules (and/or Terms &amp; Conditions), he/she as cardholder, confirms that he/she is entitled to use Services offered via the Website.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>When using the Platform/Website, User makes legally binding declaration that he/she have reached or surpassed the legal age, which is considered as such by his/her jurisdiction in order to use Services provided by the Website.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>By starting to use the Website’s Services User takes legal responsibility for not violating the legislation of any country where this Service is being used, and confirm that the payment service provider is not responsible for any such unlawful or unauthorised violation.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>By agreeing to use the Website’s Services, User understands and accepts that processing of any of his/her payments are executed by the payment service provider, and there is no statutory right of revocation of already purchased goods and/or services or any other opportunities to cancel the payment.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>User acknowledges that One-Click Payment Transaction services will remain in effect until User cancel it. If User wishes to reject to use One-Click Transaction services for his/her next purchases of goods and/or services or other facilities on the Website, User can do that by using Website email provided in Website contact details.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>Payment service provider is not responsible for any failure to process the data related to User’s payment card, or for the issuing bank’s refusal to provide authorisation of the payment with his/her payment card. Payment service provider is not responsible for the quality, quantity, price, terms or conditions of any goods and/or services or other facilities offered to User or purchased by User from the Website by using User’s payment card. When User pays for any of the Website’s goods and/or services, User is primarily bound by the Website terms and conditions. Please note that only User, as the cardholder, is responsible for paying for all goods and/or services he/she has ordered through the Website and for any additional expenses/fees that can be applied to this payment. Payment service provider acts only as the executor of the payment in the amount stated by the Website, and it is not responsible for pricing, total prices and/or total sums.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>User acknowledges and accept own responsibility to be updated in information provided on Website regarding Terms and Conditions and/or using his/her Personal account/profile on the Website.</h3>
                <h3>
                  <br/>
                </h3>
                <h3>In case there is a situation when User does not agree with the terms and conditions and/or other reasons, contact directly the Administration/Support Team of the Website using email with following Website contact details.</h3>
                <p>
                  <br/>
                </p>
                <p>
                  <br/>
                </p>
              </div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="TitleUnderline10"></div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="Article11">
                <h2>
                  <br/>
                </h2>
                <h2>
                  <span className="yellow">1. COMPANY POLICY</span>
                </h2>
                <p>1.1 It is the policy of COLDMINDS SOLUTION LTD (hereinafter "Company") to prohibit and actively prevent money laundering and any activity that facilitates money laundering or the funding of terrorist or criminal activities. The Company strives to comply with all applicable requirements under the legislations in force in the jurisdictions in which the Company operates, to prevent of the use of the financial system for the purpose of money laundering and terrorist financing.</p>
                <p>&nbsp;</p>
                <p>1.2 The Company is licensed and regulated by AntillePhone N.V., to offer remote games over the internet, under the National Decree August 18, 1998 No. 14. Under the license conditions issued by Curaçao, the Company is required to have in place adequate measures to prevent its systems from being used for the purposes of money laundering, terrorist financing or any other criminal activity.</p>
                <p>&nbsp;</p>
                <p>2. OBJECTIVE OF THE POLICY</p>
                <p>2.1 The Company is fully committed to be constantly vigilant to prevent money laundering and combat the financing of terrorism in order to minimize and manage risks such as the risks to its reputational risk, legal risk and regulatory risk. It is also committed to its social duty to prevent serious crime and not to allow its systems to be abused in furtherance of these crimes.</p>
                <p>&nbsp;</p>
                <p>2.2 The Company will endeavor to keep itself updated with developments both at national and international level on any initiatives to prevent money laundering and the financing of terrorism. It commits itself to protect, at all times, the organization and its operations and safeguards its reputation and all from the threat of money laundering, the funding of terrorist and other criminal activities.</p>
                <p>&nbsp;</p>
                <p>2.3 The Company's policies, procedures and internal controls are designed to ensure compliance with all applicable laws, rules, directives and regulations relevant to the Company's operations and will be reviewed and updated on a regular basis to ensure appropriate policies, procedures and internal controls are in place.</p>
                <p>&nbsp;</p>
                <p>3. PLAYER IDENTIFICATION PROGRAM</p>
                <p>3.1 The Company will take reasonable steps to establish the identity of any person for whom it is proposed to provide its service (hereinafter "Players"). For this purpose, the process for the registration of Players provided for under the General Terms and Conditions of the Company provides for the due diligence process that must be carried out before or during the opening of a user account.</p>
                <p>&nbsp;</p>
                <p>3.2 The Company will keep at all times a secure online list of all registered Players and information and documents will be retained in accordance with the applicable data protection obligations.</p>
                <p>&nbsp;</p>
                <p>3.3 The Company will collect certain minimum Player identification information from each Player who opens an account. The Company will not accept to open anonymous accounts or accounts in fictitious names such that the true beneficial owner is not known. The information required will include at least:</p>
                <p>&nbsp;</p>
                <p>- Player's date of birth (showing that the player is over eighteen (18) years of age);</p>
                <p>- Player's first and last name;</p>
                <p>- Player's valid email address;</p>
                <p>- Player's username (or login) and a password.</p>
                <p>&nbsp;</p>
                <p>3.4 Documents to verify the identity information received will be requested from the Player if and when there is considered to be risk or uncertainty about the information provided and prior to any payment in excess of EUR 3,000 per occasion or when payments to the account are made in excess of EUR 3,000. These documents shall include, to the extent permitted under the relevant data protection regulations:</p>
                <p>&nbsp;</p>
                <p>- A copy of a valid identity card or passport;</p>
                <p>- Proof of address.</p>
                <p>&nbsp;</p>
                <p>3.5 The Company may supplement the use of documentary evidence by using other means, which may include:</p>
                <p>&nbsp;</p>
                <p>-&nbsp;Independently verifying the Player's identity through the comparison of information provided by the Player with information obtained from a reporting agency, public database or other source;</p>
                <p>- Checking references with financial institutions;</p>
                <p>- Obtaining a financial statement.</p>
                <p>&nbsp;</p>
                <p>3.6 The Company will inform relevant Players by e-mail or another&nbsp;method that the Company may seek identification information to verify their identity.</p>
                <p>&nbsp;</p>
                <p>3.7 Any employee of the Company who becomes aware of an uncertainty in relation to the accuracy and truthfulness of the Player information provided shall immediately notify the AML Compliance Person, who will review the materials and determine whether further identification is required and or so that it may be determined whether a report is to be sent to the relevant authorities.</p>
                <p>&nbsp;</p>
                <p>3.8 If a potential or existing Player either refuses to provide the information described above when requested, or ignores the request within 3 days from the request, or appears to have intentionally provided misleading information, the Company will not open a new account and, after considering the risks involved, consider closing any existing account. In either case, the AML Compliance Person will be notified so that it may be determined whether a report is to be sent to the relevant authorities.</p>
                <p>&nbsp;</p>
                <p>3.9 If a Player has been identified as attempting or participating in any criminal or unlawful activity, the Company will take the appropriate steps to immediately freeze the account of the Player.</p>
                <p>&nbsp;</p>
                <p>3.10 If any material personal information of a Player changes, verification documents will be requested.</p>
                <p>&nbsp;</p>
                <p>4. CONTINUOUS TRANSACTION DUE DILIGENCE</p>
                <p>4.1 The Company will monitor account activity with special attention, and to the extent possible, the background and purpose of any more complex or large transactions and any transactions which are particularly likely, by their nature, to be related to money laundering or the funding of terrorism.</p>
                <p>&nbsp;</p>
                <p>4.2 Monitoring will be conducted through the following methods: Transactions will be automatically monitored and reviewed daily for all transactions above 1000 EURO along with all the details of the users making those transactions. Documents may be required at the determination of the AML Compliance Person.</p>
                <p>&nbsp;</p>
                <p>4.3 The AML Compliance Person will be responsible for this monitoring, will review any activity that the monitoring system detects, will determine whether any additional steps are required, will document when and how this monitoring is carried out, and will report suspicious activities to the relevant authorities.</p>
                <p>&nbsp;</p>
                <p>4.4 Parameters that signal possible money laundering or terrorist financing include, but are not limited to:</p>
                <p>&nbsp;</p>
                <p>- Wire transfers to/from financial secrecy havens or high-risk geographic location without an apparent reason.</p>
                <p>- Many small, incoming wire transfers or deposits made using checks and money orders or processed by any anther payment systems.</p>
                <p>- Wire activity that is unexplained, repetitive, unusually large or shows unusual patterns or with no apparent specific purpose.</p>
                <p>&nbsp;</p>
                <p>4.5 When an employee of the Company detects any activity that may be suspicious, he or she will notify the AML Compliance Person. AML Compliance Person will determine whether or not and how to further investigate the matter. This may include gathering additional information internally or from third-party sources, contacting the government, freezing the account and/or filing a report.</p>
                <p>&nbsp;</p>
                <p>4.6 The Company will not accept cash or non-electronic payments from Players. Funds may be received from Players only by any of the following methods: credit cards, debit cards, electronic transfer, wire transfer cheques and any other method approved by the Company or respective regulators.</p>
                <p>&nbsp;</p>
                <p>4.7 The Company will only transfer payments of winnings or refunds back to the same route from where the funds originated, where possible.</p>
                <p>&nbsp;</p>
                <p>4.8 To the extent the Company utilizes a third party to process and record payments to and from Player and accounts, the Company will use best efforts to ensure the services provider has transaction monitoring systems in place which will allow for screening of the transactions pursuant to these provisions and in accordance with the applicable legislation. The AML Compliance Person shall be responsible to review the relevant service agreement with the service provider to ensure the adequacy of the agreement.</p>
                <p>&nbsp;</p>
                <p>4.9 Records relating to the financial transactions shall be maintained in accordance with the data protection and retention requirements in the applicable jurisdiction of Curaçao.</p>
                <p>&nbsp;</p>
                <p>5. SUSPICIOUS TRANSACTIONS AND REPORTING</p>
                <p>The AML Compliance Person will report any suspicious transactions (including deposits and transfers) conducted or attempted by, at or through a Player account involving EUR 1,000 or more of funds (either individually or in the aggregate) where the AML Compliance Person knows, suspects or has reason to suspect:</p>
                <p>&nbsp;</p>
                <p>- The Player is included on any list of individuals assumed associated with terrorism or on a sanctions list;</p>
                <p>- The transaction involves funds derived from illegal activity or is intended or conducted in order to hide or disguise funds or assets derived from illegal activity as part of a plan to violate or evade laws or regulations or to avoid any transaction reporting requirement under law or regulation;</p>
                <p>- The transaction has no ordinary lawful purpose or is not the sort in which the Player would normally be expected to engage, and after examining the background, possible purpose of the transaction and other facts, we know of no reasonable explanation for the transaction; or</p>
                <p>- The transaction involves the use of the Company to facilitate criminal activity.</p>
                <p>&nbsp;</p>
                <p>6. TRAINING PROGRAMS</p>
                <p>6.1 The Company will develop ongoing employee training under the leadership of the AML Compliance Person and senior management. The training will occur on at least an annual basis. It will be based on the Company's size, its Player base, and its resources and be updated as necessary to reflect any new developments in the law.</p>
                <p>&nbsp;</p>
                <p>6.2 The training will include, at a minimum:</p>
                <p>&nbsp;</p>
                <p>- How to identify red flags and signs of money laundering that arise during the course of the employees' duties;</p>
                <p>- What to do once the risk is identified (including how, when and to whom to escalate unusual Player activity or other red flags for analysis;</p>
                <p>- What employees' roles are in the Company's compliance efforts and how to perform them;</p>
                <p>- The Company's record retention policy;</p>
                <p>- The disciplinary consequences for non-compliance with legislation.</p>
              </div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="TitleUnderline12"></div>
              <div _ngcontent-cvt-c280="" className="data formatted-text ng-star-inserted" id="Article13">
                <p>If you used funds/part of funds from your Account all your payments are made through the payment service provider and there is no statutory right of revocation of the purchased goods and/or services or other facilities. Refund is possible in case when no goods/services were purchased and all deposited funds available on the Account. In other cases refund possibillity is at discretion of the Administration or payment systems/agents. In case of refund/Cancellation all coins available at the Account of the User will be adjusted.</p>
                <p>
                  <br/>
                </p>
                <p>You acknowledge and agree that Your User's Account is not a bank account and is therefore not insured, guaranteed, sponsored or otherwise protected by any banking or other system insurance. Additionally, any funds deposited in Your Account will not earn any interest and can be lost.</p>
              </div>
            </div>
          </div>
        </div>
        <FooterPages />
      </div>
    )
  }
}
