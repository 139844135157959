import { io } from "socket.io-client";
import config from "config/config";
import { getUser } from "./session"


/*
const socket = io.connect(config.SOCKET_URL, {
  query: { token: session.getUser().token },
  autoConnect: false
}, () => {
  console.log("maybe here connected callback")
});
*/

export let socket = io(config.SOCKET_URL, {
  autoConnect: false,
  transports: ['websocket']
});


export const registerConnectErrorHandler = onConnectError => {
  socket.io.on("error", onConnectError);
};

export const unregisterConnectErrorHandler = () => {
  socket.io.off("error");
};

export const registerErrorListener = (onError) => {
  socket.on("error", onError)
}

export const unregisterErrorListener = (onError) => {
  socket.off("error", onError)
}

export const registerReconnectingHandler = onReconnecting => {
  socket.io.on("reconnect_attempt", onReconnecting);
}

export const unregisterReconnectingHandler = onReconnecting => {
  socket.io.off("reconnect_attempt", onReconnecting);
}

// General socket listener for for reconnection
export const registerReconnectHandler = onReconnect => {
  socket.io.on("reconnect", onReconnect);
};

export const unregisterReconnectHandler = onReconnect => {
  socket.io.off("reconnect", onReconnect);
};

// General socket listener for for reconnection
export const registerDisconnectHandler = onDisconnect => {
  socket.on("disconnect", reason => {
    if (reason === "io server disconnect") {
      onDisconnect();
    }
  });
};

export const unregisterDisconnectHandler = onDisconnect => {
  socket.off("disconnect", onDisconnect);
};

export const registerStartGameHandler = onStartGame => {
  socket.on("connect_to_game", onStartGame);
};

export const unregisterStartGameHandler = (onStartGame) => {
  socket.off("connect_to_game");
};

export const registerUserInfoUpdateHandler = (onUserInfoUpdate) => {
  socket.on("user", onUserInfoUpdate)
}

export const unregisterUserInfoUpdateHandler = (onUserInfoUpdate) => {
  socket.off("user", onUserInfoUpdate)
}

export const registerUnauthorized = onUnauthorized => {
  socket.on("unauthorize", onUnauthorized);
}

export const unregisterUnauthorized = (onUnauthorized) => {
  socket.off("unauthorize", onUnauthorized);
}
// General socket listener for new received numbers
export const registerBallHandler = onBallReceived => {
  socket.on("ball", onBallReceived);
};

// 
export const registerCloseScreen = (onCloseScreen) => {
  socket.on("close_screen", onCloseScreen)
}

export const unregisterCloseScreen = (onCloseScreen) => {
  socket.off("close_screen", onCloseScreen);
}

// socket listener for next or current game information
export const registerInfoHandler = onInfoReceived => {
  socket.on("info", onInfoReceived);
};

export const registerGameListener = (uuid, onGame) => {
  socket.on(uuid, onGame);
}

export const unregisterGameListener = (uuid) => {
  socket.off(uuid)
}

export const registerGamesListener = onListGames => {
  socket.on("games", onListGames);
}

export const unregisterGamesListener = () => {
  socket.off("games");
}

export const isConnected = () => {
  return socket.connected;
};

export const registerPlayListener = onPlayReceived => {
  socket.on("play", onPlayReceived)
}

export const unregisterPlayListener = () => {
  socket.off("play")
}


export const registerTicketChangeListener = onTicketChangeReceived => {
  socket.on("ticket_changed", onTicketChangeReceived)
}

export const unregisterTicketChangeListener = () => {
  socket.off("ticket_changed")
}


// socket listener for winner information
export const registerWinnersListener = onWinnerReceived => {
  socket.on("winners", onWinnerReceived);
};


// socket listener for winner information
export const registerJackpotWinnersListener = onWinnerReceived => {
  socket.on("jackpotWinners", onWinnerReceived);
};

// unregister info listener
export const unregisterInfoHandler = () => {
  socket.off("info");
};

export const unregisterWinnersListener = () => {
  socket.off("winners");
};

export const unregisterJackpotWinnersListener = () => {
  socket.off("jackpotWinners");
};

// unregister all listeners
export const unregisterHandlers = () => {
};


export const test = (cb = null) => {
  socket.emit("test");
}


export const setAuto = (uuid, isAuto = false, cb) => {
  socket.emit("set_auto_mode", uuid, isAuto, cb);
};

// send cell click
export const cell = (uuid, cell, cb) => {
  socket.emit("cell", uuid, cell, cb);
};

export const getGames = (cb = null) => {
  socket.emit("games", null, cb)
}

export const getGame = (uuid, cb = null) => {
  socket.emit("game", uuid, cb)
}

export const requestGameData = (uuid, cb = null) => {
  socket.emit("request_game_data", uuid, cb);
}

export const requestUserData = cb => {
  socket.emit("user", null, cb);
}

// send play game registering tickets
export const buy = (uuid, tickets, cb) => {
  socket.emit("buy", uuid, tickets, cb);
};

export const leaveGame = (uuid, cb) => {
  socket.emit("leave_game", uuid, cb);
};

// connect to socket server
export const connect = (cb = null) => {
  /*
  console.log("socket connect called", session.getUser())
  let token = session.getUser().token;
  const a = Math.random();
  let query = {
    token: token,
    randomInt: a,
  }
  console.log(query);
  socket = socket.connect(config.SOCKET_URL, {
    auth: query,
    forceNew: true
  });
  //  authenticate(cb);
  */
  let token = getUser().token;
  const a = Math.random();
  let query = {
    token: token,
    randomInt: a,
  }
  // console.log(query);

  socket = io(config.SOCKET_URL, {
    autoConnect: false,
    auth: query
  });
  socket.open();
};

// disconnect from socket server
export const disconnect = () => {
  socket.disconnect();
};

export const getGameInfo = cb => {
  if (socket.connected) {
    socket.emit("info", null, cb);
  } else {
    cb(new Error("not connected"));
  }
};

export const getTickets = cb => {
  socket.emit("ticket_changed", null, cb);
};

//
// return {
//   registerBallHandler,
//   registerInfoHandler,
//   registerJackpotWinnersListener,
//   unregisterJackpotWinnersListener,
//   registerWinnersListener,
//   unregisterWinnersListener,
//   unregisterInfoHandler,
//   unregisterHandlers,
//   registerConnectErrorHandler,
//   unregisterConnectErrorHandler,
//   registerReconnectingHandler,
//   unregisterReconnectingHandler,
//   registerReconnectHandler,
//   unregisterReconnectHandler,
//   registerErrorListener,
//   unregisterErrorListener,
//   registerStartGameHandler,
//   unregisterStartGameHandler,
//   registerDisconnectHandler,
//   unregisterDisconnectHandler,
//   registerGameListener,
//   unregisterGameListener,
//   registerGamesListener,
//   unregisterGamesListener,
//   registerCloseScreen,
//   registerUserInfoUpdateHandler,
//   unregisterUserInfoUpdateHandler,
//   registerTicketChangeListener,
//   unregisterTicketChangeListener,
//   unregisterCloseScreen,
//   registerPlayListener,
//   unregisterPlayListener,
//   getGame,
//   getGames,
//   leaveGame,
//   unregisterUnauthorized,
//   registerUnauthorized,
//   isConnected,
//   requestGameData,
//   getTickets,
//   requestUserData,
//   getGameInfo,
//   cell,
//   setAuto,
//   disconnect,
//   buy,
//   connect,
//   socket,
//   test
// };
