import React, { Component } from 'react'
import { images } from "config"
import { FooterPages } from 'components/Views'
import { Ticket } from "components/Ticket";
import tools from "utils/tools";

export default class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
      singleTicket: tools.generateSingleTicket(),
      allTicket: tools.generateSingleTicket(),
    }
  }

  render() {
    let { singleTicket, allTicket } = this.state;


    let single = Object.assign({}, singleTicket).numbers.map((row, rowIndex) => {
      return row.map((item) => {
        if (rowIndex === 0 && !item.isEmpty) {
          item.isRevealed = true
        }
        return item;
      })
    })

    let all = Object.assign({}, allTicket).numbers.map((row, rowIndex) => {
      return row.map((item) => {
        if (!item.isEmpty) {
          item.isRevealed = true
        }
        return item;
      })
    })


    return (
      <div className="w-100 h-100 d-flex flex-fill flex-column pt-3">
        <img src={images.logoWhite} style={{ height: 40, marginBottom: 20 }} alt="" />
        <div className="h-100 rounded-lg bg-white text-dark overflow-auto p-3 p-md-5 m-2">
          <div _ngcontent-gvc-c282="" className="policy formatter-text ng-star-inserted">
            <span _ngcontent-gvc-c282="" className="mat-h1">ABOUT THE GAME</span>
            <div _ngcontent-gvc-c282="" className="data-list">
              <div _ngcontent-gvc-c282="" className="data formatted-text ng-star-inserted" id="Article0">
                <h2 className="ql-align-justify"><span>INTRODUCTION</span></h2>
                <h3 className="ql-align-justify">legi10 is an online Lotto game for you and your friends, in which players compete for attention. It is played with cards with numbers from 1 to 90 and barrels that drop out in random order.</h3>
                <h3 className="ql-align-justify">An unlimited number of players can play at the same time.</h3>
                <p className="ql-align-justify"><br /></p>
                <h2 className="ql-align-justify"><span>GAME RULES</span></h2>
                <h3 className="ql-align-justify">At the beginning of the game, you must choose a room in which you want to take part. The rooms are classified into 5 sections.</h3>
                <h3 className="ql-align-justify">They are classified according to the cost of tickets for participation in a particular draw.</h3>
                <p className="ql-align-justify"><br /></p>
                <h2 className="ql-align-justify"><span>ROOMS</span></h2>
                <h3 className="ql-align-justify"> - 0.20 AZN</h3>
                <h3 className="ql-align-justify"> - 0.50 AZN</h3>
                <h3 className="ql-align-justify"> - 1.00 AZN</h3>
                <h3 className="ql-align-justify"> - 5.00 AZN</h3>
                <h3 className="ql-align-justify"> - 10.00 AZN</h3>
                <h3 className="ql-align-justify">After the players choose the draw in which they want to take part, everyone receives 1-3 cards with numbers. The numbers dropped out on the cards are given in random order.</h3>
                <h3 className="ql-align-justify">Players can replace the ticket with any other by clicking the refresh button. After the tickets are approved, the player enters the waiting room for the game.</h3>
                <p className="ql-align-justify"><br /></p>
                <h2 className="ql-align-justify"><span>GAME PROCESS</span></h2>
                <h3 className="ql-align-justify">The task of the players is to mark on the cards the numbers that appear in random order in time. Also, the game has an "Auto" mode (autocomplete the dropped numbers on the card).</h3>
                <h3 className="ql-align-justify">The winner of the Online Lotto is the one who marks all the numbers on any of the cards first.</h3>
                <h3 className="ql-align-justify">After the drawing of the draw, the winner's balance is automatically replenished with the amount that was drawn.</h3>
                <h3 className="ql-align-justify">This application allows you to play lotto online. Before starting, you can choose the type of game.</h3>
                <h3 className="ql-align-justify">There are 2 modes available in the game:</h3>
                <h3 className="ql-align-justify pl-2 pt-1">1) short game - the player who closes any row on one of the cards wins</h3>
                <div style={{ padding: 15 }}>
                  <Ticket
                    isGame={false}
                    background="green"
                    ticketData={single}
                  />
                </div>
                <h3 className="ql-align-justify pl-2">2) long game - to win you need to fill out the card completely</h3>
                <div style={{ padding: 15 }}>
                  <Ticket
                    isGame={false}
                    background="green"
                    ticketData={all}
                  />
                </div>
                <h3 className="ql-align-justify">To withdraw funds, an automated system operates, with the help of which the player, by entering the data of his card, transfers his game balance to the balance of his bank account.</h3>
                <p className="ql-align-justify"><br /></p>
              </div>
            </div>
          </div>
        </div>
        <FooterPages />
      </div>
    )
  }
}
