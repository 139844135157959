import React, { Component } from 'react';
import { withContext, session } from 'services';
import { ContainerView, LoadingButton, Dialog } from 'components/Views'
import { Input, Form, FormGroup, Label, FormFeedback } from "reactstrap";
import { config } from "config";
import axios from "axios";


class ChangePassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: session.getUser(),
      invalidFields: {
        currentPassword: false,
        newPassword: false,
        newPasswordRetype: false
      },
      dialog: {
        show: false,
        title: "",
        message: "",
      },
      fields: {
        currentPassword: "",
        newPassword: "",
        newPasswordRetype: ""
      },
      errors: {
        currentPassword: "",
        newPassword: "",
        newPasswordRetype: ""
      },
      message: "",
      isLoading: false,
    }

    this.props.client.requestUserData();
    this.setStateWithMount = this.setStateWithMount.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }


  setStateWithMount = (data, cb = null) => {
    if (this._isMounted) {
      if (cb !== null) {
        this.setState(data, cb);
      } else {
        this.setState(data);
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updatePassword() {
    let { fields, invalidFields, errors, dialog, user } = this.state
    let isFormValid = true;
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$/i;

    isFormValid = !Object.values(invalidFields).reduce((invalid, next) => invalid || next, false);

    if (fields['currentPassword'].length === 0) {
      invalidFields['currentPassword'] = true;
      errors['currentPassword'] = this.props.t("error.passwordEmpty");
      isFormValid = false;
    }

    if (fields['newPassword'].length === 0) {
      invalidFields['newPassword'] = true;
      errors['newPassword'] = this.props.t("error.newPasswordEmpty");
      isFormValid = false
    } else if (fields['newPassword'].length < 8){
      invalidFields['newPassword'] = true;
      errors['newPassword'] = this.props.t("error.passwordLength");
      isFormValid = false
    } else if (!passwordRegex.test(fields['newPassword'])){
      invalidFields['newPassword'] = true;
      errors['newPassword'] = this.props.t("error.passwordWeak");
      isFormValid = false
    }


    if (fields['newPasswordRetype'].length === 0) {
      invalidFields['newPasswordRetype'] = true;
      errors['newPasswordRetype'] = this.props.t("error.passwordRetypeError");
      isFormValid = false
    }


    if (isFormValid) {
      this.setStateWithMount({ isLoading: true });
      axios
        .put(config.API_URL + "/password", {
          current_password: fields["currentPassword"],
          new_password: fields["newPassword"]
        },
          {
            headers: {
              Authorization: user.token
            }
          }
        )
        .then(result => {
          let data = result.data;

          if (data.status === "error") {
            dialog.title = this.props.t("dialog.error");
            dialog.message = this.props.t("error.wrongCurrentPassword");
            dialog.show = true;

            errors["currentPassword"] = this.props.t("error.wrongCurrentPassword");
            invalidFields["currentPassword"] = true;
          } else {
            dialog.title = this.props.t("dialog.success");
            dialog.message = this.props.t("changePassword.passwordChangeSuccess");
            dialog.show = true;

            fields = {
              currentPassword: "",
              newPassword: "",
              newPasswordRetype: ""
            }
          }

          this.setStateWithMount({
            isLoading: false,
            dialog,
            fields
          })
        })
        .catch(error => {
          // console.log(error);
          this.setStateWithMount({
            isLoading: false
          })
        });

    }


    this.setStateWithMount({
      invalidFields,
      errors,
    })

  }


  handleChange(evt) {
    let { name: field, value } = evt.target;
    let { invalidFields, errors, fields } = this.state;
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$/i;

    fields[field] = value;

    if (field === "newPassword") {

      if (value.length === 0) {
        invalidFields['newPassword'] = true;
        errors['newPassword'] = this.props.t("error.newPasswordEmpty");
      } else if (value.length < 8){
        invalidFields['newPassword'] = true;
        errors['newPassword'] = this.props.t("error.passwordLength");
      } else if (!passwordRegex.test(value)){
        invalidFields['newPassword'] = true;
        errors['newPassword'] = this.props.t("error.passwordWeak");
      } else  if (fields['newPasswordRetype'].length > 0 && fields['newPasswordRetype'] !== fields['newPassword']) {
        invalidFields[field] = true;
        errors[field] = this.props.t("error.passwordRetypeError");
      } else  {
        invalidFields[field] = false;
      }
  
    } else if (field === "newPasswordRetype") {
      if (fields['newPasswordRetype'] !== fields['newPassword']) {
        invalidFields[field] = true;
        errors[field] = this.props.t("error.passwordRetypeError");
      } else {
        invalidFields[field] = false;
      }
    } else if (field === "newPassword" && fields["newPasswordRetype"] !== "") {
      if (fields['newPasswordRetype'] !== fields['newPassword']) {
        invalidFields["newPasswordRetype"] = true;
        errors[field] = this.props.t("error.passwordRetypeError");
      } else {
        invalidFields["newPasswordRetype"] = false;
      }
    } else {
      invalidFields[field] = false;
    }


    this.setStateWithMount({
      invalidFields,
      errors,
      fields
    });
  }

  render = () => {
    let { dialog, invalidFields, fields, errors, isLoading } = this.state;
    return (
      <ContainerView loading={this.props.isChatLoading} t={this.props.t}>
        <Dialog
          t={this.props.t}
          isOpen={dialog.show}
          toggle={() => {
            dialog.show = !dialog.show;
            this.setStateWithMount({
              dialog
            })
          }}
          cancelText={this.props.t("dialog.close")}
          onCancelClick={() => {
            dialog.show = false;

            this.setStateWithMount({
              dialog
            })
          }}
          title={dialog.title}
          content={dialog.message}
        >
        </Dialog>

        <div className="section-header">{this.props.t("profile.changePassword")}</div>

        <div className="page-container">
          <Form className="login-form profile">
            <FormGroup>
              <Label >{this.props.t("changePassword.oldPassword")}</Label>
              <Input
                type="password"
                name="currentPassword"
                value={fields["currentPassword"]}
                invalid={invalidFields["currentPassword"]}
                onChange={this.handleChange}
                placeholder={this.props.t("changePassword.oldPassword")}
              />
              <FormFeedback>{errors["currentPassword"]}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label >{this.props.t("changePassword.newPassword")}</Label>
              <Input
                type="password"
                name="newPassword"
                value={fields["newPassword"]}
                invalid={invalidFields["newPassword"]}
                onChange={this.handleChange}
                placeholder={this.props.t("changePassword.newPassword")}
              />
              <FormFeedback>{errors["newPassword"]}</FormFeedback>
            </FormGroup>


            <FormGroup>
              <Label>{this.props.t("changePassword.passwordRetry")}</Label>
              <Input
                type="password"
                name="newPasswordRetype"
                value={fields["newPasswordRetype"]}
                invalid={invalidFields["newPasswordRetype"]}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                placeholder={this.props.t("changePassword.passwordRetry")}
              />
              <FormFeedback>{errors["newPasswordRetype"]}</FormFeedback>
            </FormGroup>
          </Form>
        </div>


        <LoadingButton
          className="btn-accept btn-profile"
          isLoading={isLoading}
          onClick={this.updatePassword}
          text={this.props.t("changePassword.changePassword")}
        />
      </ContainerView>

    )
  }

}

export default withContext(ChangePassword);