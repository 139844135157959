import axios from "axios";
import config from "config/config";
import * as session from "./session"


const getHeaders = () =>{
  return {
    headers: {
      Authorization: session.getUser().token
    }
  }
}

export const getData = (promise) => {
  return new Promise((resolve, reject) => {
    // get user information
    const user = session.getUser();
    // global settings for axios
    axios.defaults.baseURL = config.API_URL;
    if (typeof user !== "undefined" && user !== null) {
      axios.defaults.headers.Authorization = user.token;
    }

    axios.defaults.headers.post["Content-Type"] = "application/json";
    promise
      .then(result => {
        let data = result.data;
        if (data.status === "error") {
          let error = new Error(data.error.message);
          error.code = data.error.code;
          error.title = data.error.title;
          reject(error);
        } else {
          resolve(data.data);
        }
      })
      .catch(error => {
        error.code = 500;
        error.title = "INTERNAL_ERROR";
        reject(error)
      })
  })

}

export const updateAccount = (name, email) => {
  const result = axios.put("/user", {
    name,
    email
  });

  return getData(result)
}

export const login = (email, password) => {
  return axios.post("/auth", {
    email: email,
    password: password
  });
};

export const getContacts = async () => {
  const result = axios.get(config.API_URL + "/contacts/admin", getHeaders());
  return getData(result)
}

export const getUser = () => {
  // console.log("getUser called");
  return axios.get("/user");
};

// export default {
//   login,
//   getUser,
//   getContacts,
//   updateLanguage,
//   updatePassword,
//   updateAccount,
// };