import React from 'react';
import PropTypes from 'prop-types';
import { config } from "config"
// import { Helmet } from 'react-helmet';
import axios from "axios";
import { session } from 'services';

export default class LiveChat extends React.Component {

  componentDidMount() {
    this.loadLiveChatApi.bind(this)();
  }

  chatLoaded() {
    const { onChatLoaded } = this.props
    if (window.LC_API) {
      this.setCallbacks.bind(this)();
      if (typeof this.props.onChatLoaded === 'function') {
        window.LC_API.on_after_load = function () {
          onChatLoaded(window.LC_API);
        }
      }
    }
  }

  componentWillUnmount() {
    // // remove script from head
    // const livechatScript = document.getElementById("lc_id_api");
    // document.head.removeChild(livechatScript)

    // // destroy livechatwidget
    // window.LiveChatWidget.call("destroy")
  }

  chatNotLoaded() {
    if (typeof this.props.onErrorLoading === 'function') {
      this.props.onErrorLoading();
    }
  }

  loadLiveChatApi() {

    const { name, email, username, token, user_id, livechat_group, admin_id } = this.props.user;

    // console.log("group", livechat_group)

    let tokenPromise = null
    let cachedToken = null

    const fetchLiveChatToken = () => {
      return axios
        .get(config.API_URL + "/lc/token",
          {
            headers: {
              Authorization: token
            }
          }
        )
    }

    // const fetchToken = () => {
    //   tokenPromise = fetchLiveChatToken().then(result => {
    //     tokenPromise = null
    //     return result.data
    //   });

    //   return tokenPromise
    // }

    const isExpired = ({ creationDate, expiresIn }) => Date.now() >= creationDate + expiresIn
    
    const getToken = () => {
        if (tokenPromise) {
            return tokenPromise
        }

        if (session.getLCToken() && !isExpired(session.getLCToken())) {
            return Promise.resolve(session.getLCToken())
        }

        return getFreshToken()
    }

    const getFreshToken = () => {
        tokenPromise = fetchLiveChatToken().then(
            res => {
                tokenPromise = null
                // session.setItem(cacheKey, JSON.stringify(token))
                session.setLCToken(res.data)
              // console.log("getting token", res.data)

                cachedToken = res.data
                return res.data
            }
        )
        return tokenPromise
    }

    const hasToken = () => {
        return Promise.resolve(!!cachedToken)
    }

    const invalidate = () => {
        cachedToken = null
        return session.deleteLCToken()
    }


    if (!window.LC_API) {
      window.__lc = window.__lc || {};
      // console.log(tokenPromise)

      window.__lc.custom_identity_provider = () => ({
        getToken,
        getFreshToken,
        hasToken,
        invalidate
      })


      const visitor = { name, email, username, admin_id }
      const params =
        [{ name: 'username', value: username },
        { name: 'user_id', value: user_id },
        { name: 'manager_id', value: admin_id},
        { name: 'email', value: email }]



      window.__lc.license = 13093878;
      window.__lc.group = livechat_group;
      window.__lc.chat_between_groups = this.props.chatBetweenGroups;
      window.__lc.params = params;
      window.__lc.visitor = visitor;
      const lc = document.createElement('script');

      lc.text = `
          ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.id="lc_id_api",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
      `
      // lc = 
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(lc, s);

      const api = document.getElementById("lc_id_api")
      api.addEventListener('load', this.chatLoaded.bind(this));
      api.addEventListener('error', this.chatNotLoaded.bind(this));

    } else {
      if (typeof this.props.onChatLoaded === 'function') {
        this.props.onChatLoaded(window.LC_API);
      }
    }
  }

  render() {
    return null
  }

  setCallbacks() {
    if (typeof this.props.onBeforeLoad === 'function')
      window.LC_API.on_before_load = this.props.onBeforeLoad.bind(this);

    // if (typeof this.props.onAfterLoad === 'function')
    //   window.LC_API.on_after_load = this.props.onAfterLoad.bind(this);

    if (typeof this.props.onChatWindowOpened === 'function')
      window.LC_API.on_chat_window_opened = this.props.onChatWindowOpened.bind(this);

    if (typeof this.props.onChatWindowMinimized === 'function')
      window.LC_API.on_chat_window_minimized = this.props.onChatWindowMinimized.bind(this);

    if (typeof this.props.onChatWindowHidden === 'function')
      window.LC_API.on_chat_window_hidden = this.props.onChatWindowHidden.bind(this);

    if (typeof this.props.onChatStateChanged === 'function')
      window.LC_API.on_chat_state_changed = this.props.onChatStateChanged.bind(this);

    if (typeof this.props.onChatStarted === 'function')
      window.LC_API.on_chat_started = this.props.onChatStarted.bind(this);

    if (typeof this.props.onChatEnded === 'function')
      window.LC_API.on_chat_ended = this.props.onChatEnded.bind(this);

    if (typeof this.props.onMessage === 'function')
      window.LC_API.on_message = this.props.onMessage.bind(this);

    if (typeof this.props.onTicketCreated === 'function')
      window.LC_API.on_ticket_created = this.props.onTicketCreated.bind(this);

    if (typeof this.props.onPrechatSurveySubmitted === 'function')
      window.LC_API.on_prechat_survey_submitted = this.props.onPrechatSurveySubmitted.bind(this);

    if (typeof this.props.onRatingSubmitted === 'function')
      window.LC_API.on_rating_submitted = this.props.onRatingSubmitted.bind(this);

    if (typeof this.props.onRatingCommentSubmitted === 'function')
      window.LC_API.on_rating_comment_submitted = this.props.onRatingCommentSubmitted.bind(this);
  }
}

LiveChat.propTypes = {
  // important
  onChatLoaded: PropTypes.func,
  chatBetweenGroups: PropTypes.bool,
  onBeforeLoad: PropTypes.func,
  onAfterLoad: PropTypes.func,
  onChatWindowOpened: PropTypes.func,
  onChatWindowMinimized: PropTypes.func,
  onChatWindowHidden: PropTypes.func,
  onChatStateChanged: PropTypes.func,
  onChatStarted: PropTypes.func,
  onChatEnded: PropTypes.func,
  onMessage: PropTypes.func,
  onTicketCreated: PropTypes.func,
  onPrechatSurveySubmitted: PropTypes.func,
  onPostchatSurveySubmitted: PropTypes.func,
  onRatingSubmitted: PropTypes.func,
  onRatingCommentSubmitted: PropTypes.func,
};

LiveChat.defaultProps = {
  group: 0,
};