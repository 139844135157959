import React, { Component } from 'react';
import withContext from 'services/withContext';
import { ContainerView, LoadingButton, Dialog } from 'components/Views'
import { Input, Form, FormGroup, Label, FormFeedback } from "reactstrap";
import { session, api } from 'services';



class Account extends Component {

  constructor(props) {
    super(props);

    const user = session.getUser();

    this.state = {
      user,
      isLoading: false,
      fields: {
        name: user.name,
        email: user.email
      },
      invalidFields: {
        name: false,
        email: false
      },
      errors: {
        name: "",
        email: ""
      },
      dialog: {
        show: false,
        title: "",
        message: "",
      },
    }


    this.setStateWithMount = this.setStateWithMount.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
  }

  setStateWithMount = (data, cb = null) => {
    if (this._isMounted) {
      if (cb !== null) {
        this.setState(data, cb);
      } else {
        this.setState(data);
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateUserInfo() {
    let { invalidFields, dialog, fields, errors } = this.state
    let isFormValid = true;

    isFormValid = !Object.values(invalidFields).reduce((invalid, next) => invalid || next, false);

    if (isFormValid) {
      this.setStateWithMount({ isLoading: true });

      api.updateAccount(fields["name"], fields["email"])
        .then(data => {
          dialog.show = true;
          dialog.title = this.props.t("dialog.success");
          dialog.message = this.props.t("profile.accountUpdateSuccess");

          session.setUser(data);

          this.setStateWithMount({
            isLoading: false,
            dialog,
            fields,
            errors
          })
        })
        .catch(error => {
          dialog.show = true;
          dialog.title = this.props.t("dialog.error");
          if (error.code === 407) {
            dialog.message = this.props.t("error.emailExists");
            errors["email"] = this.props.t("error.emailExists");
            invalidFields["email"] = true;
          } else {
            dialog.message = this.props.t("error.internalError");
          }
          console.log(error);
          this.setStateWithMount({
            isLoading: false,
            dialog,
            fields,
            errors
          })
        });
    }
  }

  handleChange(evt) {
    let { name: field, value } = evt.target;
    let { invalidFields, errors, fields } = this.state;
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;


    fields[field] = value;

    if (field === "name") {
      if (value.length < 3) {
        invalidFields[field] = true;
        errors[field] = this.props.t("error.nameLength");
      } else {
        invalidFields[field] = false;
      }
    } else if (field === "email") {
      if (!emailRegex.test(value)) {
        invalidFields[field] = true;
        errors[field] = this.props.t("error.emailFormat");
      } else {
        invalidFields[field] = false;
      }
    } else {
      invalidFields[field] = false;
    }


    this.setStateWithMount({
      invalidFields,
      errors,
      fields
    });
  }

  render = () => {
    let { user, invalidFields, errors, fields, isLoading, dialog } = this.state;

    return (
      <ContainerView loading={this.props.isChatLoading} t={this.props.t}>

        <Dialog
          t={this.props.t}
          isOpen={dialog.show}
          toggle={() => {
            dialog.show = !dialog.show;
            this.setStateWithMount({
              dialog
            })
          }}
          cancelText={this.props.t("dialog.close")}
          onCancelClick={() => {
            dialog.show = false;

            this.setStateWithMount({
              dialog
            })
          }}
          title={dialog.title}
          content={dialog.message}
        >
        </Dialog>
        <div className="section-header">{this.props.t("profile.personalInformation")}</div>
        <div className="page-container">
          <Form className="login-form profile ">
            <FormGroup className="disabled">
              <Label>{this.props.t("login.username")}</Label>
              <Input
                type="text"
                name="username"
                defaultValue={user.username}
                disabled={true}
                placeholder={this.props.t("login.username")}
              />
            </FormGroup>
            <FormGroup className="disabled">
              <Label >{this.props.t("login.name")}</Label>
              <Input
                type="text"
                defaultValue={fields["name"]}
                invalid={invalidFields["name"]}
                name="name"
                // onChange={this.handleChange}
                placeholder={this.props.t("login.username")}
              />
              <FormFeedback>{errors["name"]}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label >{this.props.t("login.email")}</Label>
              <Input
                type="email"
                name="email"
                onChange={this.handleChange}
                invalid={invalidFields["email"]}
                defaultValue={fields["email"]}
                placeholder={this.props.t("login.username")}
              />
              <FormFeedback>{errors["email"]}</FormFeedback>
            </FormGroup>

          </Form>
        </div>

        <LoadingButton
          className="btn-accept btn-profile"
          isLoading={isLoading}
          onClick={this.updateUserInfo}
          text={this.props.t("profile.save")}
        />
      </ContainerView>

    )
  }


}

export default withContext(Account);