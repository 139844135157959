import React, { Component } from 'react';
import withContext from 'services/withContext';
import { ContainerView, Header, Footer, Dialog, Body, ButtonWithSVG, Manat } from 'components/Views'
import { config, images } from "config";
import axios from "axios";
import { ReactComponent as MoneyIcon } from '../assets/images/svg/money.svg'
import { ReactComponent as StarIcon } from '../assets/images/svg/star.svg'


class Leaderboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isAcceptLoading: false,
            ranking: [],
            infoDialog: false,
            ticketCoins: [],
            event: null,
            lastEventResult: null,
            shortVersion: false
        }

        this.lastGamePrizeModal = React.createRef();

        this.loadRanking = this.loadRanking.bind(this);
        this.loadLastRankingResult = this.loadLastRankingResult.bind(this);
        this.claimLastEventPrize = this.claimLastEventPrize.bind(this);
        this.countdown = this.countdown.bind(this);
    }

    countdown = () => {
        const { event } = this.state;
        if (event) {

            const remaining = parseInt(event.remaining);

            if (remaining > 0) {
                event.remaining = remaining - 1
                this.setStateWithMount({ event })
            }

            // if (remaining < 35487) {
            //     this.loadLastRankingResult();
            // }

        } else {
            clearInterval(this.countdownTimer)
        }

    }
    setStateWithMount = (data, cb = null) => {
        if (this._isMounted) {
            if (cb !== null) {
                this.setState(data, cb);
            } else {
                this.setState(data);
            }
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.loadRanking();
        this.loadTimer = setInterval(this.loadRanking, 60000)
        this.loadLastRankingResult()
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.loadTimer)
        clearInterval(this.countdownTimer)
    }

    renderRanking = (ranking, shortVersion = false) => {
        const { user } = this.props;
        return ranking.map((player, index) => {
            return (<RankingItem {...player} isMe={parseInt(player.user_id) === parseInt(user.user_id)} key={index} shortVersion={shortVersion} />)
        })
    }

    getDateFromSeconds = (remaining) => {
        const days = parseInt(remaining / (24 * 3600));

        remaining = remaining % (24 * 3600);
        const hours = parseInt(remaining / 3600)

        remaining %= 3600;
        const minutes = parseInt(remaining / 60);

        remaining %= 60;
        const seconds = remaining;
        // console.log(`${remaining} => ${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`)
        return {
            minutes,
            seconds,
            hours,
            days,
        }
    }


    loadLastRankingResult = () => {
        const { user } = this.props;

        axios
            .get(config.API_URL + '/ranking/monthly/result',
                {
                    headers: {
                        Authorization: user.token
                    }
                }
            )
            .then(result => {
                const data = result.data;
                // console.log('load result')

                if (data.data) {
                    const lastEventResult = data.data
                    this.setStateWithMount({
                        lastEventResult,
                        isAcceptLoading: false
                    });
                }
            })
            .catch(error => {
                // console.log(error);
            });
    }

    loadRanking = () => {
        const { user } = this.props;


        axios
            .get(config.API_URL + '/ranking/monthly',
                {
                    headers: {
                        Authorization: user.token
                    }
                }
            )
            .then(result => {
                const data = result.data;
                if (data.data) {
                    const { ranking = [], event = null, ticket_coins = [] } = data.data;
                    this.setStateWithMount({
                        isLoading: false,
                        ranking,
                        event,
                        ticketCoins: ticket_coins
                    });
                    clearInterval(this.countdownTimer);
                    this.countdownTimer = setInterval(this.countdown, 1000);
                }
            })
            .catch(error => {
                // console.log(error);
                this.setStateWithMount({
                    isLoading: false
                })
            });
    }


    claimLastEventPrize = (eventId) => {
        const { user } = this.props;

        // toJpeg(this.lastGamePrizeModal.current, { cacheBust: true, }).then((dataUrl => {
        //     const link = document.createElement('a')
        //     link.download = 'my-image-name.png'
        //     link.href = dataUrl
        //     link.click()
        // }));

        this.setStateWithMount({ isAcceptLoading: true })
        axios({
            method: "POST",
            url: config.API_URL + "/ranking/monthly/claim",
            data: {
                event_id: eventId
            },
            headers: {
                Authorization: user.token
            }
        }).then(result => {
            let data = result.data;
            if (data.status === "error") {
                // console.log("error", data)
            } else {
                this.loadLastRankingResult();
            }

        }).catch(error => {
            // console.log(error);
            this.setStateWithMount({
                isAcceptLoading: false
            })
        });

    }

    getMonth = (monthIndex, languageCode) => {

        let month;
        if (languageCode === 'az') {
            const azMonth = ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'İyun',
                'İyul', 'Avqust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr'];
            month = azMonth[monthIndex - 1]
        } else {
            const objDate = new Date();
            objDate.setDate(1);
            objDate.setMonth(monthIndex - 1);

            month = objDate.toLocaleString(languageCode, { month: "long" });
            // month = month.charAt(0) + month.slice(1)
        }



        return month;
    }


    renderInformationDialog = () => {
        const { infoDialog, ticketCoins } = this.state;
        const { t } = this.props;

        return (
            <Dialog
                isOpen={infoDialog}
                title={t('history.information')}
                cancelText={t("dialog.close")}
                onCancelClick={() => {
                    this.setStateWithMount({ infoDialog: false })
                }}
                toggle={() => {
                    this.setStateWithMount({ infoDialog: false })
                }}
                t={t}
            >
                <table className='table'>
                    <tbody>
                        {ticketCoins.map(((game, index) => {
                            return (
                                <tr key={index} className='ticket-info'>
                                    <td className='prize'><MoneyIcon className='money' />{game.ticket_price}<Manat color='black' /></td>
                                    <td style={{ textAlign: 'right' }} className='coins'>{game.coins} coin <StarIcon /></td>
                                </tr>
                            )
                        }))}
                    </tbody>
                </table>

            </Dialog>
        )
    }

    claimPrize = () => {
        // send request to server
    }

    renderRankingResultDialog = () => {
        const { lastEventResult, isAcceptLoading } = this.state;
        const { t } = this.props;

        if (lastEventResult === null) {
            return;
        }

        const {
            place = null,
            prize = null,
            coins = null,
            seen = true,
            has_won: hasWon = false,
            event_id: eventId
        } = lastEventResult

        if (seen) {
            return;
        }

        let cancelText = hasWon ? 'Uduşu götürün' : t('dialog.close')

        let prizeText = hasWon ? (<div><b>Təbriklər </b>, siz <span className='last-event-prize'>  {prize} <MoneyIcon /> </span> qazandınız.</div>) : (
            <div>Təəssüf ki siz qalib ola bilmədiniz. Növbəti yarışmada sizə uğurlar.
                arzu edirik.
            </div>)

        return (
            <Dialog
                isOpen={true}
                id='modal-prize'
                title={t('Yarışma bitdi')}
                className='last-event-modal'
                acceptText={cancelText}
                isAcceptLoading={isAcceptLoading}
                onAcceptClick={() => {
                    this.claimLastEventPrize(eventId)
                }}

                t={t}
            >
                <div ref={this.lastGamePrizeModal} style={{ padding: 2 }}>
                    İyun ayı boyu davam edən yarışmamız sonlandı. <br />
                    <div className='last-event-info'>
                        Siz bu yarışmanı <span className='last-event-coins'>{coins} <StarIcon />  </span> ilə <span style={{ fontWeight: 600, fontSize: 20 }}>{place} </span>yerdə tamamlandınız.
                    </div>

                    {/* <div className={`ranking sticky`} style={{ borderRadius: 20 }}>
                        <div className={`place `} style={{ marginLeft: 15 }}>{place} </div>
                        <div className='player'>{'asfafsa'} </div>
                        <div className='coins' style={{ marginRight: 15 }}><StarIcon /> {coins} </div>
                    </div> */}
                    {prizeText}
                </div>


            </Dialog >
        )
    }

    render = () => {
        const { isLoading, ranking, event, shortVersion } = this.state;
        const { user, t } = this.props;

        if (!event) {
            return <ContainerView t={t}>
                Coming soon
            </ContainerView>
            { /* return <ContainerView t={t} loading={isLoading} /> */ }
        }

        const { days, hours, minutes, seconds } = this.getDateFromSeconds(event.remaining)
        const year = event.year;
        const month = this.getMonth(event.month, user.language_code);

        return (
            <div className="d-flex flex-fill flex-column flex-nowrap" >
                {this.renderInformationDialog()}
                {this.renderRankingResultDialog()}

                <Header t={this.props.t} />
                <Body loading={isLoading} cardStyle={{ display: "flex", flexDirection: "column" }}>
                    <div className='d-flex flex-row align-items-center p-2'>
                        <div className='d-flex flex-column ranking-date' >
                            <span>{month}</span>
                            <span>{year}</span>
                        </div>
                        <div className='ranking-remaining'>
                            <div><span>{days}</span> {t('event.days')} </div>
                            <div><span>{hours}</span> {t('event.hours')} </div>
                            <div><span>{minutes}</span> {t('event.minutes')} </div>
                            <div><span>{seconds}</span> {t('event.seconds')} </div>
                            {/* <div><span>{days}</span> <span>days</span> </div>
                            <div><span>{hours}</span> <span>hours</span></div>
                            <div><span>{minutes}</span> <span>minutes</span></div>
                            <div><span>{seconds}</span> <span>seconds</span></div> */}
                        </div>
                    </div>

                    <div className='d-flex flex-row justify-content-end p-1 mb-2'>
                        <ButtonWithSVG
                            text={t('event.information')}
                            className='btn-svg-blue'
                            onClick={() => {
                                this.setStateWithMount({ infoDialog: true })
                            }}
                        >

                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.99992 0.666644C13.6024 0.666645 17.3333 4.39748 17.3333 8.99998C17.3333 13.6025 13.6024 17.3333 8.99992 17.3333C4.39742 17.3333 0.666584 13.6025 0.666585 8.99998C0.666585 4.39748 4.39742 0.666644 8.99992 0.666644ZM9.83325 6.49998L9.83325 4.83331L8.16659 4.83331L8.16659 6.49998L9.83325 6.49998ZM9.83325 13.1666L9.83325 8.16665L8.16659 8.16665L8.16659 13.1666L9.83325 13.1666Z" fill="white" />
                            </svg>

                        </ButtonWithSVG>

                    </div>

                    <div className='d-flex flex-column position-relative  overflow-hidden'>
                        <div className='d-flex flex-column position-relative overflow-auto h-100'>
                            {this.renderRanking(ranking, shortVersion)}
                        </div>

                    </div>
                </Body >
                <Footer loading={isLoading} unReadMessageCount={this.props.chat.getUnreadMessagesCount()} />
            </div>
        )

        // return (
        //     <ContainerView
        //         loading={isLoading}
        //         balance={200}
        //         onlineCount={200}
        //     >
        //         <div>
        //             <span>{event.month}</span>
        //             <span>{event.year}</span>
        //         </div>
        //         <div className='d-flex flex-column position-relative overflow-auto h-100'>
        //             {this.renderRanking(ranking)}s
        //         </div>
        //         {/* <RankingItem {...myRanking} name={user.name} isMe={true} /> */}
        //     </ContainerView>

        // )
    }
}

const RankingItem = (props) => {
    const { name, prize, coins, place, isMe = false, shortVersion = false } = props;

    let coinsContent = parseInt(coins);
    if (shortVersion) {
        if (coinsContent > 1000000) {
            coinsContent = `${(Math.round(coinsContent / 100000) / 10).toFixed(1)}M`;
        } else if (coinsContent > 1000) {
            coinsContent = `${(Math.round(coinsContent / 100) / 10).toFixed(1)}K`;
        }

    }

    let placeContent = place, className;

    switch (parseInt(place)) {
        case 1:
            className = 'first';
            placeContent = <img src={images.ranking.gold} alt='gold' />
            break;
        case 2:
            className = 'second';
            placeContent = <img src={images.ranking.silver} alt='silver' />
            break;
        case 3:
            className = 'third';
            placeContent = <img src={images.ranking.bronze} alt='bronze' />
            break;
        default:
            className = ''
    }

    if (isMe) {
        className = 'sticky'
    }

    // if (place <= 3) {
    //     placeContent = 'icon'
    // }
    return (
        <div className={`ranking ${className}`}>
            <div className={`place ${className}`}>{placeContent} </div>
            <div className='player'>{name.substring(0, 7)} </div>
            {parseInt(prize) !== 0 &&
                <div className='prize'> <MoneyIcon /> {prize} </div>}
            <div className='coins'><StarIcon /> {coinsContent} </div>
        </div>
    );

}

export default withContext(Leaderboard);