import React, { Component } from 'react'
import Manat from "./Manat"
import { images } from "config";
import tools from "utils/tools";
import AnimatedNumber from "animated-number-react";
import FlipNumbers from 'react-flip-numbers';
import { ReactComponent as StarIcon } from '../../assets/images/svg/star.svg'


import "odometer/themes/odometer-theme-slot-machine.css";

export default class GameItem extends Component {

    renderBalls(lastGameNumbers) {
        if (lastGameNumbers.length !== 5) {
            lastGameNumbers = [10, 56, 40, 80, 23]
        }

        return lastGameNumbers.map((ball, index) => {
            // const { settings } = this.props.game;

            return <span className="ball"
                key={index}
                style={{
                    backgroundColor: "white",
                    // color: settings.colorSecondary,
                    // borderColor: settings.colorSecondary
                }}
            >{ball}</span>
        })
    }

    render() {
        const { game, onClick } = this.props;
        const { settings } = game

        let label, clock;


        if (game.status === "starting") {
            label = this.props.t("gameItem.starting");
            clock = tools.secondsToClock(game.remaining);
        } else if (game.status === "active") {
            label = this.props.t("gameItem.waiting")
            clock = "00:00"
        } else if (game.status === "started") {
            label = this.props.t("gameItem.started")
            clock = tools.secondsToClock(game.elapsed);
        }

        let ticketPrize = <> {game.ticketPrice.toFixed(0)}<Manat /></>

        if (game.ticketPrice === 0) {
            ticketPrize = this.props.t("gameItem.free")
        }

        let labelClass = `label ${game.status}`
        let clockClass = `clock ${game.status}`

        const gradient = JSON.parse(settings.colorGradient)

        const gradientStyle = {
            background: `linear-gradient(to bottom, ${gradient[0]} 0%, ${gradient[1]} 100%)`
        }

        // console.log(gradientStyle)

        // const jackpot = parseInt(game.jackpot.currentAmount).toString().padStart(3, '0'); Ayaz
        // const isInRange = game.jackpot.isInRange Ayaz


        return (
            <div className={"game-container " + game.status + " px-1"} onClick={onClick}>
                <div className={"gradient "} style={gradientStyle}>

                    <div className={"card"} style={{ paddingBottom: 8 }}>
                        <div className="current-game-info ">
                            <div className="current-game-stats ">
                                <div className='d-flex flex-row align-self-start position-relative'>
                                    <div style={gradientStyle} className={"game-title new2 " + (game.isNew ? "new-game" : "")}>
                                        
                                        <span className="type">{game.typeId === 1 ? "classic" : "lottery"}:</span>
                                        
                                        <span style={{ textShadow: `0px 1px ${settings.colorSecondary}` }}>{ticketPrize}</span>
                                        {game.gameId === 1 && <span className="new-label">{this.props.t("gameItem.fullTicket")}</span>}
                                    </div>
                                    <div className='coin-container'>
                                        <div className='game-star'>
                                            {game.coins.amount} <StarIcon height={30} width={30} />
                                        </div>
                                        <div style={{ marginTop: -9 }}>{this.props.t('gameItem.get')}</div>
                                        {game.coins.multiplier !== 1 &&

                                            <span className='multiplier'>x{game.coins.multiplier}</span>}
                                    </div>
                                </div>

                                <div className="game-prize">
                                    <span className="prize"><img alt="prize" src={images.coinstack} style={{ width: 38, marginRight: 3 }} /><span className="count" >
                                        <AnimatedNumber
                                            value={game.money}
                                            formatValue={(value) => value.toFixed(0)}
                                        /><Manat height="16" /></span></span>
                                    <span className="online-users"><span className="count">{game.userCount}</span> {this.props.t("gameItem.playersOnline")}</span>
                                </div>
                            </div>


                            <div className="game-info">
                                <div className="clock-info">
                                    <span className={labelClass}>{label}</span>
                                    <span className={clockClass}>{clock}</span>
                                </div>
                                {/* Ayaz
                                <span className={isInRange ? 'jackpot-sign draw' : 'jackpot-sign'} >JACKPOT</span>
                                <div className='jackpot'>
                                    <img src={`/jackpot/jackpotBg.svg`} alt='jackpot' />
                                    <FlipNumbers height={20} width={21} color={isInRange ? "#dc3545" : "#414141"} play
                                        perspective={70} numberStyle={{ fontSize: 12, fontWeight: 600, fontFamiily: "cursive", textShadow: "0px 1px 1px rgba(0,0,0,0.5)" }} duration={2} numbers={jackpot} />
                                </div>
                                */}
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="last-game-info" style={{ paddingTop: 3 }}>
                            <div className="last-game-numbers">
                                {/* <span className="label" style={{ color: "#495057" }}>{this.props.t("gameItem.winningNumbers")}</span> */}
                                <div className="numbers">
                                    {this.renderBalls(game.lastGameNumbers)}
                                </div>
                            </div>

                            <div className="last-game-prize">
                                {/* <span className="label" style={{ color: "#495057" }}>{this.props.t("gameItem.lastPrize")}</span> */}
                                <span className="prize">{game.lastGamePrize}<Manat width="20" style={{ marginLeft: 0 }} /></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )

        /*
        // ticket price
         <div className="ticket-price-info">
                                <span className="label">ticket price</span>
                                <span className="ticket-price">{ticketPrize}</span>
                            </div>
        */
    }
}
