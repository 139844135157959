import React, { Component } from "react";
import { session } from "services"


/*
    This is Component created for managing ticket cells
*/

class Cell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    if (this.props.onCellChange !== null) {
      const value = event.target.value
      const re = /^[1-9][0-9\b]*$/;

      if (value === '' || (re.test(value) && parseInt(value) >= 1 && parseInt(value) <= 90)) {
        const valueToSend = value !== '' ? parseInt(value) : null;
        this.props.onCellChange(valueToSend)
      }
    }
  }

  render() {
    const { data, disabled, editable } = this.props;

    let settings = session.getSettings();
    let cellColor = settings.hasOwnProperty("numberColor") ? settings.numberColor : "blue";

    let className = "ticket-cell " + cellColor + " ";

    let onClick = this.props.onClick;

    if (data.isRevealed) {
      className += " success";
    } else {
      if (data.isMissed) {
        className += " missed";
      } else if (data.isPending) {
        className += " pending";
      }
    }

    if (disabled) {
      className += " disabled";
      onClick = null;
    }

    if (editable) {
      return (
        <div className={`ticket-cell ${data.isInvalid ? 'invalid' : ''}`}>
          <input className="editable" onChange={this.handleChange} value={data.value === null ? '' : data.value} maxLength={2} />
        </div>

      );
    } else {
      return (
        <button className={className} onClick={onClick}>
          {this.props.data.value}
        </button>
      );
    }

  }
}

export default Cell;
