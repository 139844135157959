import React from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import * as pages from "pages";
import { GameContext } from "context";
// import { session, socket as client, chat, firebase, audio } from "services" zzz
import { session, socket as client, chat, audio } from "services"


audio.addListener();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      session.isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);





// const VerifyRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       <Component isLoggedIn={session.isLoggedIn()} {...props} />
//     }
//   />
// );

const LoginRoute = ({ component: Component, isRegister = false, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !session.isLoggedIn() ? (
        <Component isRegister={isRegister} {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);


const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Component {...props} />
    }
  />
);

function App() {
  return (
    // <GameContext.Provider value={{ client, chat, firebase, audio }} > zzz
    <GameContext.Provider value={{ client, chat, audio }} >
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={pages.Home} />
          <PrivateRoute path="/game" component={pages.Game} />
          <PrivateRoute path="/profile" component={pages.Profile} />
          <PrivateRoute path="/history" component={pages.History} />
          <PrivateRoute path="/leaderboard" component={pages.Leaderboard} />
          <PrivateRoute path="/settings" component={pages.Settings} />
          <PrivateRoute path="/password" component={pages.ChangePassword} />
          <PrivateRoute path="/language" component={pages.Language} />
          <PrivateRoute path="/playedgames" component={pages.PlayedGames} />
          <PrivateRoute path="/transactions" component={pages.Transactions} />
          <PrivateRoute path="/messages" component={pages.Chat} />
          <PrivateRoute path="/chat" component={pages.Chat} />
          <PrivateRoute path="/account" component={pages.Account} />
          <PrivateRoute path="/wallet*" component={pages.Wallet} />
          <PrivateRoute path="/withdraw" component={pages.Withdraw} />
          <PrivateRoute path="/deposit" component={pages.Deposit} />
          <PrivateRoute path="/deposit" component={pages.Deposit} />
          <PrivateRoute path="/test" component={pages.Test} />
          <PrivateRoute path="/ticket" component={pages.TicketSelect} />
          <PrivateRoute path="/ticketEdit" component={pages.TicketEdit} />
          <PrivateRoute path="/example" component={pages.Example} />
          <LoginRoute path="/login" component={pages.Login} />
          <LoginRoute path="/register" component={pages.Login} isRegister={true} />
          <PublicRoute path="/about" component={pages.About} />
          <PublicRoute path="/terms-and-conditions" component={pages.TermsAndConditions} />
          <PublicRoute path="/privacy" component={pages.Privacy} />
          <PublicRoute path="*" component={pages.NotFound} />
        </Switch>
      </Router>
    </GameContext.Provider>
  );
}

export default App;
