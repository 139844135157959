import React, { Component } from 'react'
import Header from './Header'
import Footer from './Footer'
import Body from './Body'

export default class ContainerView extends Component {


    render() {

        const { loading, hideFooter, hideHeader, children, style, t, cardStyle, unReadMessageCount } = this.props

        let header = (
            <Header t={t} />
        )

        if (hideHeader) {
            header = ""
        }


        let footer = (
            <Footer loading={loading} unReadMessageCount={unReadMessageCount} />
        )

        if (hideFooter) {
            footer = "";
        }


        return (
            <div className="d-flex flex-fill flex-column flex-nowrap" style={style}>
                {header}
                <Body loading={loading} cardStyle={cardStyle} >
                    {children}
                </Body >
                {footer}
            </div>
        )
    }
}
