import React, { Component, forwardRef } from 'react';
import withContext from 'services/withContext';
import { Header, Footer, Body, NotFound, Dialog, ButtonWithSVG, Manat } from 'components/Views'
import InfiniteScroll from 'react-infinite-scroller';
import { config, images } from "config";
import { Ticket } from "components/Ticket";
import DatePicker from "react-datepicker";
import tools from "utils/tools";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Trans } from 'react-i18next';

class PlayedGames extends Component {

  constructor(props) {
    super(props);

    this.props.client.requestUserData();

    this.state = {
      isLoading: true,
      cursor: null,
      gameDialog: false,
      selectedIndex: null,
      games: [],
      isWon: null,
      endDate: moment().toDate(),
      startDate: moment().subtract(30, 'days').toDate()
    }

    this.loadGames = this.loadGames.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
  }

  setStateWithMount = (data, cb = null) => {
    if (this._isMounted) {
      if (cb !== null) {
        this.setState(data, cb);
      } else {
        this.setState(data);
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadGames();

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setStartDate = (date) => {
    this.setStateWithMount({ startDate: date, cursor: null, games: [] }, () => this.loadGames())
  }


  setEndDate = (date) => {
    this.setStateWithMount({ endDate: date, cursor: null, games: [] }, () => {
      this.loadGames()
    })
  }

  loadGames = () => {
    const { user } = this.props;
    const { cursor, games, startDate, endDate, isWon } = this.state

    let requestURL = '';

    if (cursor) {
      requestURL = cursor.next;
    } else {
      const params = [];
      if (startDate) params.push('start_date=' + moment(startDate).format("DD/MM/YYYY"))
      if (endDate) params.push('end_date=' + moment(endDate).format("DD/MM/YYYY"))
      if (isWon) params.push('is_won=' + isWon)

      requestURL = '/games?' + params.join('&')
      console.log(requestURL)
      this.setStateWithMount({ isLoading: true })

    }


    // const requestURL = cursor === null ? '/games' : cursor.next
    axios
      .get(config.API_URL + requestURL,
        {
          headers: {
            Authorization: user.token
          }
        }
      )
      .then(result => {
        const data = result.data;
        this.setStateWithMount({
          games: [...games, ...data.data.games],
          isLoading: false,
          cursor: data.data.hasOwnProperty("cursor") ? data.data.cursor : null
        })
      })
      .catch(error => {
        // console.log(error);
        this.setStateWithMount({
          isLoading: false
        })
      });
  }


  onMoreClick = (index) => {
    this.setStateWithMount({
      gameDialog: true,
      selectedIndex: index
    })
  }

  renderGames = (games) => {
    if (games.length === 0) {
      return <NotFound />
    } else {
      return games.map((game, index) => {
        return (
          <PlayedGameItem
            t={this.props.t}
            key={index}
            onMoreClick={this.onMoreClick.bind(this, index)}
            {...game}
          />
        )
      })
    }
  }

  renderGameDialog = () => {
    const { gameDialog, selectedIndex, games } = this.state;
    const { t } = this.props;

    let content = '';



    if (selectedIndex !== null) {
      const game = games[selectedIndex];
      const { selected_numbers, prize, is_won, ticket_price, tickets } = game;


      const sumPrize = tickets.reduce(
        (previousValue, currentValue) => previousValue + currentValue.prize,
        0
      );

      content = (
        <div className="modal-body-list overflow-auto">

          <table className='table'>
            <tbody>
              <tr>
                <td>{t('history.ticketPrice')}</td>
                <td className='d-flex align-items-center' style={{ fontWeight: '500' }}>{ticket_price} <Manat /></td>
              </tr>
              <tr>
                <td>{t('history.totalPrize')}</td>
                <td className='d-flex align-items-center' style={{ fontWeight: '500' }}>{prize}<Manat /></td>
              </tr>
              <tr>
                <td>{t('history.result')}</td>
                <td>{t(is_won ? 'history.won' : 'history.lost')} </td>
              </tr>
              <tr>
                <td>{t('history.wonPrize')}</td>
                <td className='d-flex align-items-center' style={{ fontWeight: '500' }}>{sumPrize} <Manat /></td>
              </tr>
              <tr>
                <td>{t('history.step')}</td>
                <td>{selected_numbers.length}</td>
              </tr>
            </tbody>
          </table>
          <div className='d-flex flex-wrap justify-content-center'>
            {selected_numbers.map((number, index) => {
              return (
                <div style={{ height: 35, width: 35, position: "relative", margin: 2 }} >
                  <div className="loto-keg-new">
                    <span style={{ fontSize: 12 }}>{number}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }

    return (
      <Dialog
        isOpen={gameDialog}
        title={t('history.information')}
        cancelText={t("dialog.close")}
        onCancelClick={() => {
          this.setStateWithMount({ gameDialog: false, selectedIndex: null })
        }}
        toggle={() => {
          this.setStateWithMount({ gameDialog: false, selectedIndex: null })
        }}
        t={t}
      >
        {content}

      </Dialog>
    )
  }

  render = () => {
    const { games, isLoading, cursor, startDate, endDate } = this.state;
    const { t } = this.props;

    return (
      <div className="d-flex flex-fill flex-column flex-nowrap" >
        <Header t={t} />
        <Body loading={isLoading} cardStyle={{ height: "100%" }}>
          {this.renderGameDialog()}
          <div className='scroll-container'>
            <InfiniteScroll
              initialLoad={false}
              hasMore={cursor !== null}
              threshold={20}
              loadMore={this.loadGames}
              loader={<div className="loader" key={0}>Loading ...</div>}
              useWindow={false}
            >
              <div style={{ padding: 10 }}>
                <span style={{ fontSize: 24, fontWeight: 500 }}>{this.props.t("profile.playedGames")}</span>
              </div>

              <div className='date-range-container'>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => this.setStartDate(date)}
                  disabledKeyboardNavigation
                  withPortal
                  dateFormat="dd/MM/yyyy"
                  calendarClassName="loto-calendar"
                  maxDate={endDate}
                  customInput={<DatePickerTrigger label={t('history.fromDate')} />}
                  startDate={startDate}
                  endDate={endDate}
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => this.setEndDate(date)}
                  disabledKeyboardNavigation
                  dateFormat="dd/MM/yyyy"
                  withPortal
                  minDate={startDate}
                  customInput={<DatePickerTrigger label={t('history.toDate')} />}
                  calendarClassName="loto-calendar"
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                />
              </div>

              {this.renderGames(games)}

            </InfiniteScroll>
          </div>
        </Body >
        <Footer loading={isLoading} />
      </div>
    )
  }
}

const PlayedGameItem = (props) => {
  let { is_won, tickets, created_date, selected_numbers, onClick, t, onMoreClick } = props;

  const wonTicketCount = tickets.filter(ticket => ticket.is_won).length;

  const sumPrize = tickets.reduce(
    (previousValue, currentValue) => previousValue + currentValue.prize,
    0
  );


  const ticketsView = tickets.map((ticket, index) => {
    let numbers = tools.convertToTicket(ticket.numbers);
    return (
      <div className='d-flex w-50 pr-1 pb-1' key={index + "-container"}>
        <Ticket
          key={index}
          isGame={false}
          editable={false}
          size={"mini"}
          clickable={false}
          ticketData={numbers}
        />
      </div>
    )
  });

  let selectedNumbers;
  if (selected_numbers) {
    selectedNumbers = selected_numbers.slice(0, 7).map((number, index) => {
      return (
        <div style={{ height: 35, width: 35, position: "relative", margin: 2 }} key={index} >
          <div className="loto-keg-new">
            <span style={{ fontSize: 12 }}>{number}</span>
          </div>
        </div>
      )
    })
  }



  return (
    <div style={{
      borderBottomWidth: 1,
      padding: 5,
      borderBottomColor: "#efecec29",
      borderBottomStyle: "solid"
    }}>

      <div className='d-flex flex-row justify-content-between align-items-center'>
        <div className='d-flex flex-column'>
          <span style={{ fontSize: 12 }}>{is_won ? t('history.wonTicket') : t('history.lostTicket')}</span>
          <span style={{ fontSize: 14, fontWeight: "bold" }}><Trans i18nKey="history.ticketInfo">{{wonTicketCount}} bilet udub, {{sumPrize}} manat pul qazanmisiniz</Trans></span>
        </div>
        {is_won && <img src={images.wonsign} style={{ height: 30 }} alt="won-sign" />}
      </div>

      <div className='d-flex p-1 pr-2 align-items-center'>
        <div className='d-flex flex-wrap'>
          {selectedNumbers}
        </div>
        <ButtonWithSVG className='ml-auto btn-more' onClick={onMoreClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-three-dots" viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
          </svg>
        </ButtonWithSVG>
      </div>

      <div
        className="d-flex flex-wrap py-2"
        style={{
          borderRadius: 10,
          cursor: "pointer",
        }}
        onClick={onClick}>
        {ticketsView}
      </div>
      <span style={{ fontSize: 12 }}>{moment(created_date).format("DD/MM/YYYY HH:mm")}</span>
    </div>

  )
}

const DatePickerTrigger = forwardRef((props, ref) => {
  return (
    <button onClick={props.onClick} className='calendar-picker'>
      <div className='d-flex flex-column justify-content-start align-items-start'>
        <span style={{ fontSize: 14 }}>{props.label}</span>
        <span>{props.value}</span>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" width="20.383" height="22.858" viewBox="0 0 20.383 22.858">
        <g id="calendar-interface-symbol-tool" transform="translate(-1.974)">
          <g id="Group_1099" data-name="Group 1099" transform="translate(1.974)">
            <path id="Path_15965" data-name="Path 15965" d="M19.692,2.476H19V1.725a1.675,1.675,0,1,0-3.349,0v.751H13.935V1.725a1.675,1.675,0,1,0-3.349,0v.751H8.868V1.725a1.675,1.675,0,1,0-3.349,0v.751H4.639A2.669,2.669,0,0,0,1.974,5.141V20.193a2.669,2.669,0,0,0,2.665,2.665H19.692a2.668,2.668,0,0,0,2.665-2.665V5.141A2.669,2.669,0,0,0,19.692,2.476Zm-3.1-.751a.735.735,0,1,1,1.468,0V4.8a.735.735,0,1,1-1.468,0Zm-5.068,0a.735.735,0,1,1,1.468,0V4.8a.735.735,0,1,1-1.468,0Zm-5.068,0a.735.735,0,1,1,1.468,0V4.8a.735.735,0,1,1-1.468,0Zm14.33,18.469a1.1,1.1,0,0,1-1.1,1.1H4.639a1.1,1.1,0,0,1-1.1-1.1V6.4H20.789Z" transform="translate(-1.974)" fill="#f1b750" />
            <rect id="Rectangle_261" data-name="Rectangle 261" width="2.882" height="2.565" transform="translate(2.979 9.173)" fill="#f1b750" />
            <rect id="Rectangle_262" data-name="Rectangle 262" width="2.882" height="2.565" transform="translate(6.826 9.173)" fill="#f1b750" />
            <rect id="Rectangle_263" data-name="Rectangle 263" width="2.882" height="2.565" transform="translate(10.675 9.173)" fill="#f1b750" />
            <rect id="Rectangle_264" data-name="Rectangle 264" width="2.882" height="2.565" transform="translate(14.522 9.173)" fill="#f1b750" />
            <rect id="Rectangle_265" data-name="Rectangle 265" width="2.882" height="2.563" transform="translate(2.979 12.596)" fill="#f1b750" />
            <rect id="Rectangle_266" data-name="Rectangle 266" width="2.882" height="2.563" transform="translate(6.826 12.596)" fill="#f1b750" />
            <rect id="Rectangle_267" data-name="Rectangle 267" width="2.882" height="2.563" transform="translate(10.675 12.596)" fill="#f1b750" />
            <rect id="Rectangle_268" data-name="Rectangle 268" width="2.882" height="2.563" transform="translate(14.522 12.596)" fill="#f1b750" />
            <rect id="Rectangle_269" data-name="Rectangle 269" width="2.882" height="2.563" transform="translate(2.979 16.018)" fill="#f1b750" />
            <rect id="Rectangle_270" data-name="Rectangle 270" width="2.882" height="2.563" transform="translate(6.826 16.018)" fill="#f1b750" />
            <rect id="Rectangle_271" data-name="Rectangle 271" width="2.882" height="2.563" transform="translate(10.675 16.018)" fill="#f1b750" />
            <rect id="Rectangle_272" data-name="Rectangle 272" width="2.882" height="2.563" transform="translate(14.522 16.018)" fill="#f1b750" />
          </g>
        </g>
      </svg>

    </button>
  );
});


export default withContext(PlayedGames);