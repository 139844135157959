import React, { Component } from 'react'
import { Button } from "reactstrap";

export default class ButtonWithIcon extends Component {

    render() {
        let { isLoading, disabled, text, icon, ...otherProps } = this.props;


        return (
            <Button {...otherProps} disabled={disabled} className="btn-image">
                <img src={icon} alt={text} />
                {text}
            </Button>
        )
    }
}
