import React, { Component } from 'react'
import { Button } from "reactstrap";

export default class ButtonMenu extends Component {

  render() {
    let { isLoading, disabled, text, icon, title = "title", subtitle = "subtitle", ...otherProps } = this.props;


    return (
      <Button {...otherProps} disabled={disabled} className="btn-menu">
        <div className="icon-container">

          {this.props.children}
        </div>

        <div className="title-text">
          <span className="title">{title}</span>
          <span className="subtitle">{subtitle}</span>
        </div>
        <svg
          className="chevron-right"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
            fill="currentColor"
          />
        </svg>
      </Button>
    )
  }
}
