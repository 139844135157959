import React, { Component } from 'react';
import withContext from 'services/withContext';
import { ContainerView } from 'components/Views'
import { Button, CustomInput } from "reactstrap"
import packageJson from '../../package.json';
import { images } from "config"
import { session } from "services"

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            settings: session.getSettings()
        }

        this.onSelectNumberColor = this.onSelectNumberColor.bind(this);
        this.onSoundToggle = this.onSoundToggle.bind(this);
    }

    onSoundToggle = (e) => {
        let settings = this.state.settings;
        settings.isSoundOn = e.target.checked;
        session.setSettings(settings);
        this.setState({ settings })
    }

    onSelectNumberColor = (color = "blue") => {
        let settings = this.state.settings;
        settings.numberColor = color;
        session.setSettings(settings);
        this.setState({ settings })
    }

    render = () => {
        const { isSoundOn, numberColor } = this.state.settings

        return (
            <ContainerView
                username="Ali"
                balance={200}
                t={this.props.t}
                onlineCount={200}
                unReadMessageCount={this.props.chat.getUnreadMessagesCount()}
            >
                <div className="section-header">{this.props.t("settings.settings")}</div>
                <div className="page-container">
                    <div className="settings-section">

                        <div className="section-container">
                            <span className="section">{this.props.t("settings.numberColor")}</span>
                            <span className="description">{this.props.t("settings.numberColorDesc")}</span>
                            <div style={{ display: "flex", flexDirection: "row-reverse", paddingTop: 10 }}>
                                <Button onClick={() => this.onSelectNumberColor("yellow")} className={"btn-number yellow " + (numberColor === "yellow" ? "selected" : "")} />
                                <Button onClick={() => this.onSelectNumberColor("blue")} className={"btn-number blue " + (numberColor === "blue" ? "selected" : "")} />
                                <Button onClick={() => this.onSelectNumberColor("purple")} className={"btn-number purple " + (numberColor === "purple" ? "selected" : "")} />
                            </div>
                        </div>
                    </div>
                    <div className="settings-section">
                        <div className="section-container">
                            <span className="section">{this.props.t("settings.gameSound")}</span>
                            <span className="description">{this.props.t("settings.gameSoundDesc")}</span>

                        </div>
                        <CustomInput type="switch" id="soundSwitch" checked={isSoundOn} name="soundSwitch" onChange={this.onSoundToggle} bsSize="lg" />
                    </div>

                    <div className="settings-section">
                        <div className="section-container">
                            <span className="section">{this.props.t("settings.appVersion")}</span>
                            <span className="description">{packageJson.version}</span>
                        </div>
                    </div>

                    <div className="settings-section" style={{ border: "none" }}>
                        <div className="section-container" >
                            <span className="section">{this.props.t("settings.contactUs")}</span>
                            <div className="social-contacts" style={{ paddingTop: 5, display: "flex" }}>
                                <a href="https://wa.me/123456789" target="_blank" rel="noopener noreferrer"><img src={images.social.whatsapp} alt="whatsapp" /></a>
                                <a href="https://www.tiktok.com/@123456789" target="_blank" rel="noopener noreferrer"><img style={{ width: 38 }} src={images.social.tiktok} alt="tiktok" /></a>
                                <img src={images.social.facebook} alt="facebook" style={{ height: 33 }} />
                                <a href="https://www.instagram.com/123456789/" target="_blank" rel="noopener noreferrer"><img src={images.social.instagram} alt="facebook" /></a>
                            </div>
                        </div>
                    </div>
                </div>

            </ContainerView>

        )
    }
}

export default withContext(Settings);