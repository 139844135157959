import React, { Component } from 'react';
import { withContext, session } from 'services';
import { ContainerView, GameItem, Dialog, } from 'components/Views'


import tools from 'utils/tools';

class Home extends Component {

    _isMounted = false;
    customerSDK = null

    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            selectedIndex: 0,
            games: [],
            timer: 5000,
            isChatloading: false,
            loading: true
        }

        //custom state function
        this.setStateWithMount = this.setStateWithMount.bind(this);

        this.onListGames = this.onListGames.bind(this);
        this.countdown = this.countdown.bind(this);
        // this.onChatLoaded = this.onChatLoaded.bind(this);

        this.props.client.requestUserData();
        this.user = session.getUser();
    }


    onChatLoaded = (ref) => {
        this.livechat = ref
        this.setState({ isChatloading: false })
    }


    setStateWithMount = (data, cb = null) => {
        if (this._isMounted) {
            if (cb !== null) {
                this.setState(data, cb);
            } else {
                this.setState(data);
            }
        }
    };


    countdown = async () => {


        // const sounds = this.props.audio.getSounds();

        // if (sounds) {
        //     sounds['ball'].play();
        // }
        // // if (this.props.isSoundLoaded()){
        //     this.props.sounds()['ball'].play();
        // } else {
        //     console.log('not loaded')
        // }



        let games = this.state.games;
        await tools.asyncForEach(games, (game, index) => {
            if (game.status === "starting") {
                games[index].remaining = games[index].remaining - 1
            } else if (game.status === "started") {
                games[index].elapsed = games[index].elapsed + 1
            }
        })

        this.setStateWithMount({
            games: games
        })

    }

    onListGames = (data) => {

        // filter all games with starting status
        const startingGames = data.filter(({ status }) => status === "starting" || status === "started");

        if (startingGames.length > 0) {
            // clear timer
            clearInterval(this.timer);
            // add new timer
            this.timer = setInterval(this.countdown, 1000);
        } else {
            clearInterval(this.timer);
        }
        // console.log(data)

        this.setStateWithMount({
            games: data,
            loading: false
        })

    }


    componentDidMount = () => {
        this._isMounted = true;

        this.props.client.registerGamesListener(this.onListGames)

        this.props.client.getGames();

        // this.props.audio.getSounds()['background'].play();


        // window.LiveChatWidget.on('ready', this.onChatLoaded)
    }
    componentWillUnmount = () => {
        this._isMounted = false;

        this.props.client.unregisterGamesListener();
        // filter all games with starting status
        clearInterval(this.timer)

        // window.LiveChatWidget.off('ready', this.onChatLoaded)

    }

    renderDialog = () => {
        return (
            <Dialog
                t={this.props.t}
                isOpen={this.state.showDialog}
                toggle={() => {
                    this.setStateWithMount({
                        showDialog: false
                    })
                }}
                onCancelClick={() => {
                    this.setStateWithMount({
                        showDialog: false
                    })
                }}
                cancelText={this.props.t("dialog.close")}
                acceptText={this.props.t("dialog.accept")}
                onAcceptClick={() => {
                    const gameId = this.state.games[this.state.selectedIndex].nextGameUUID;
                    this.props.history.push({
                        pathname: "/ticket",
                        search: "?gameId=" + gameId
                    });
                }}
                title={this.props.t("game.started")}
            >
                {this.props.t("game.startedMessage")}
            </Dialog>)
    }

    renderGames = (games) => {

        return games.map((game, index) => {
            // game.isNew = game.gameId === 2
            return (
                <GameItem
                    onClick={() => {
                        if (game.status === "started") {
                            this.setStateWithMount({
                                showDialog: true,
                                selectedIndex: index
                            })
                        } else {
                            this.props.history.push({
                                pathname: "/ticket",
                                search: "?gameId=" + game.uuid
                            });
                        }

                    }}
                    t={this.props.t}
                    key={index}
                    game={game}
                />
            );
        });
    }

    render = () => {
        const { loading, games, action } = this.state;
        return (
            <div className="d-flex flex-fill">
                {this.renderDialog(action)}
                <ContainerView t={this.props.t} loading={loading || this.props.isChatLoading} cardStyle={{ height: '100%', paddingTop: 10, overflow: "auto" }} unReadMessageCount={this.props.chat.getUnreadMessagesCount()}>
                    <div className='floating-button'></div>

                    {this.renderGames(games)}
                </ContainerView>
            </div>


        )
    }

}

export default withContext(Home);