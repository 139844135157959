const USER = "user";
const JACKPOTS = "jackpots";
const SETTINGS = "settings";
const LC_TOKEN = "lc_token";
const DEPOSIT_MESSAGE = "deposit_message";
const AUTO_MODE_MESSAGE = "auto_mode_message";
const CONTACT_NUMBER = "contact_number";
const IS_LIVECHAT_LOADED = "IS_LIVECHAT_LOADED";
const CHAT_ID = "CHAT_ID";
const CHAT_UNREAD_MESSAGE_COUNT = 'CHAT_UNREAD_MESSAGE_COUNT';

export const getUser = () => {
    // get user information
    let userJSON = localStorage.getItem(USER);

    // parse user information to storage
    const user = JSON.parse(userJSON)

    return user;
}

export const setUser = (user) => {
    localStorage.setItem(USER, JSON.stringify(user));
}

export const setBalance = (balance) => {
    // get user information
    let user = getUser();
    // update balance information
    user.balance = balance
    // update user information
    setUser(user);
}

export const getBalance = () => {
    const user = getUser();

    return user.balance;
}

export const getLCToken = () => {
    const token = localStorage.getItem(LC_TOKEN);
    return JSON.parse(token)
}

export const isLiveChatLoaded = () => {
    return (localStorage.getItem(IS_LIVECHAT_LOADED) !== null)
}

export const setLiveChatLoaded = (isLoaded) => {
    localStorage.setItem(IS_LIVECHAT_LOADED, isLoaded);
}

export const setLCToken = (token) => {
    localStorage.setItem(LC_TOKEN, JSON.stringify(token))
}

export const deleteLCToken = (token) => {
    localStorage.removeItem(LC_TOKEN)
}

export const getJackpots = () => {
    // get jackpot item from localstorage
    let jackpotJSON = localStorage.getItem(JACKPOTS);

    // parse json string 
    const jackpots = JSON.parse(jackpotJSON);

    return jackpots;
}
export const hideDepositMessage = () => {
    localStorage.setItem(DEPOSIT_MESSAGE, false);
}

export const showDepositMessage = () => {
    const show = localStorage.getItem(DEPOSIT_MESSAGE);
    return show === null ? true : show
}

export const hideAutoMessage = () => {
    localStorage.setItem(AUTO_MODE_MESSAGE, false)
}

export const showAutoMessage = () => {
    const show = localStorage.getItem(AUTO_MODE_MESSAGE);
    return show === null ? true : false
}

export const setContactNumber = (contact) => {
    localStorage.setItem(CONTACT_NUMBER, contact)
}

export const getContactNumber = () => {
    return localStorage.getItem(CONTACT_NUMBER);
}

export const setJackpots = (jackpots) => {
    localStorage.setItem(JACKPOTS, JSON.stringify(jackpots))
}

export const setSettings = (settings = {}) => {
    localStorage.setItem(SETTINGS, JSON.stringify(settings))
}

export const getSettings = () => {
    let settingsJSON = localStorage.getItem(SETTINGS);

    const settings = JSON.parse(settingsJSON) || {};


    if (!settings.hasOwnProperty("numberColor")) {
        settings['numberColor'] = 'blue'
    }

    if (!settings.hasOwnProperty("isSoundOn")) {
        settings['isSoundOn'] = true
    }

    return settings;
}

export const getLangauge = () => {
    const user = getUser();

    return user.language_code;
}

export const isLoggedIn = () => {
    return (localStorage.getItem(USER) !== null)
}

export const setChatId = (chatId) => {
    localStorage.setItem(CHAT_ID, chatId)
}

export const setUnreadMessagesCount = (count) => {
    localStorage.setItem(CHAT_UNREAD_MESSAGE_COUNT, count.toString())
}

export const getUnreadMessagesCount = () => {
    return localStorage.getItem(CHAT_UNREAD_MESSAGE_COUNT)
}


export const clear = () => {
    localStorage.clear();
}