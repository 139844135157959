/*

    This file is created in order to assits some general tools 
    generating ticket, random number and etc.

*/

var uuid4 = require("uuid4");

const extractValuesFromTicketNumbers = numbers => {
  let ticket = [];
  numbers.forEach(rows => {
    let ticketCol = [];
    rows.forEach(column => {
      if (column.isEmpty === false) {
        ticketCol.push(column.value);
      }
    });
    ticket.push(ticketCol);
  });

  return JSON.stringify(ticket);
};

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const randomBoolean = (alwaysTrue = false) => {
  return alwaysTrue ? true : Math.random() < 0.51;
};

// 1-9, 10-19, 20-29, 30-39, 40-49, 50-59, 60-69, 70-79, 80-90
const randomNumber = row => {
  return Math.floor(
    Math.random() * (row === 8 ? 11 : row === 0 ? 9 : 10) + (row === 0 ? 1 : row * 10)
  );
};

const fillNumbers = n => {
  let numbers = [];
  for (let i = 1; i <= n; i++) {
    numbers.push(i);
  }
  return numbers;
};

const generateLotoNumbers = count => {
  var tickets = [];
  for (let i = 0; i < count; i++) {
    let ticket = {
      index: i,
      numbers: generateLotoNumber()
    };
    tickets.push(ticket);
  }
  return tickets;
};

const generateLotoNumber = () => {
  var ticket = [],
    row = [],
    selectedNumberCount = 0,
    selectedNumbers = [],
    number = 0;
  const rowCount = 3,
    columnCount = 9;

  for (var i = 0; i < rowCount; i++) {
    row = [];
    selectedNumberCount = 0;
    for (var j = 0; j < columnCount; j++) {
      // If unselected columns count equal to columns left
      // then choose numbers without generating random boolean
      if (columnCount - j === 5 - selectedNumberCount) {
        number = randomNumber(j);
        while (selectedNumbers.includes(number)) {
          number = randomNumber(j);
        }
        selectedNumbers.push(number);
        row.push(number);
        selectedNumberCount++;
      } else {
        // If count of selected numbers is less than 5 then select number
        // otherwise break the current loop
        if (selectedNumberCount < 5) {
          if (randomBoolean()) {
            number = randomNumber(j);
            while (selectedNumbers.includes(number)) {
              number = randomNumber(j);
            }
            row.push(number);
            selectedNumbers.push(number);
            selectedNumberCount++;
          }
        } else {
        }
      }
    } // end columnCount loop

    ticket.push(row);
  }
  return ticket;
};

const convertToTicket = numberJSON => {
  const numbers = typeof numberJSON === 'string' || numberJSON instanceof String ? JSON.parse(numberJSON) : numberJSON;
  const rowCount = 3;
  const columnCount = 9;

  var ticket = [];

  for (let i = 0; i < rowCount; i++) {
    ticket.push([]);
    for (let j = 0; j < columnCount; j++) {
      ticket[i][j] = {
        x: i,
        y: j,
        clickable: false,
        isRevealed: false,
        isMissed: false,
        value: null,
        isEmpty: true
      };
    }
  }


  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 5; j++) {
      let index = Math.floor(numbers[i][j] / 10);

      if (index === 9) {
        index = 8;
      }

      ticket[i][index].isEmpty = false;
      ticket[i][index].value = numbers[i][j];
    }
  }

  return ticket;
};

const checkWinner = (winners, userId) => {
  let winner = false;
  winners.forEach(win => {
    if (parseInt(win.user.userId) === parseInt(userId)) {
      winner = win;
    }
  });

  return winner;
};

const generateTickets = count => {
  var tickets = [];
  for (let i = 0; i < count; i++) {
    let ticket = {
      id: uuid4(),
      index: i,
      selected: false,
      numbers: generateTicket()
    };
    tickets.push(ticket);
  }
  return tickets;
};

const generateSingleTicket = () => {
  const ticket = generateTickets(1)[0];
  ticket.isAuto = true;
  return ticket;
}

const secondsToClock = secs => {
  if (secs < 0) {
    return "00:00";
  }

  let minutes = Math.floor(secs / 60);
  let seconds = Math.floor(secs - minutes * 60);
  let clockToShow =
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds);
  return clockToShow;
};

const generateTicket = () => {
  let ticket = [], selectedNumberCount = 0, selectedNumbers = [], number, trueCount = 0;
  const rowCount = 3, columnCount = 9;
  for (var i = 0; i < rowCount; i++) {
    ticket.push([]);
    selectedNumberCount = 0;
    trueCount = 0;
    for (var j = 0; j < columnCount; j++) {
      // If unselected columns count equal to columns left
      // then choose numbers without generating random boolean
      if (columnCount - j === 5 - selectedNumberCount) {
        number = randomNumber(j);
        while (selectedNumbers.includes(number)) {
          number = randomNumber(j);
        }
        ticket[i][j] = {
          x: i,
          y: j,
          clickable: false,
          isMissed: false,
          value: number,
          isRevealed: false,
          isEmpty: false,
        };
        selectedNumbers.push(number);
        selectedNumberCount++;
      } else {
        // If count of selected numbers is less than 5 then select number
        // otherwise break the current loop
        if (selectedNumberCount < 5) {
          //console.log('row:',i,'col:',j,'true:', trueCount);
          if (randomBoolean() && trueCount < 3) {
            trueCount++;
            number = randomNumber(j);
            while (selectedNumbers.includes(number)) {
              number = randomNumber(j);
            }
            ticket[i][j] = {
              x: i,
              y: j,
              clickable: false,
              isMissed: false,
              value: number,
              isRevealed: false,
              isEmpty: false
            };
            selectedNumbers.push(number);
            selectedNumberCount++;
          } else {
            trueCount = 0;
            ticket[i][j] = {
              x: i,
              y: j,
              clickable: false,
              isMissed: false,
              isRevealed: false,
              value: null,
              isEmpty: true
            };
          }
        } else {
          ticket[i][j] = {
            x: i,
            y: j,
            clickable: false,
            isMissed: false,
            isRevealed: false,
            value: null,
            isEmpty: true
          };
        }
      }
    } // end columnCount loop
  } // end rowCount loop

  return ticket;
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  generateLotoNumber,
  generateLotoNumbers,
  generateSingleTicket,
  checkWinner,
  randomBoolean,
  generateTicket,
  asyncForEach,
  generateTickets,
  convertToTicket,
  fillNumbers,
  extractValuesFromTicketNumbers,
  secondsToClock
};
