import { sounds as audios } from "config";

let sounds = null

export const addListener = () => {
  document.body.addEventListener('click', listener);
  document.body.addEventListener('touchstart', listener);
}

const listener = () => {
  loadSounds()
}

export const removeListener = () => {
  document.body.removeEventListener('click', listener);
  document.body.removeEventListener('touchstart', listener);
}

export const loadSounds = () => {
  if (!sounds) {
    sounds = {};
    for (let key of Object.keys(audios)) {
      // console.log(sounds[key])
      try {
        const audio = new Audio(audios[key]);
        audio.play()
        audio.pause()
        audio.currentTime = 0;
        sounds[key] = audio
      } catch (error) {

      }
    }
    removeListener();
  }
}


export const getSounds = () => {
  return sounds;
}