import React, { Component } from 'react'
import { Button } from "reactstrap";

export default class ButtonWithSVG extends Component {

    render() {
        let { isLoading, disabled, text, iconDirection = "left", className = "", ...otherProps } = this.props;

        return (
            <Button {...otherProps} disabled={disabled} className={"btn-image " + className} > 
                {iconDirection === "left" && this.props.children}
                {text}
                {iconDirection === "right" && this.props.children}
            </Button>
        )
    }
}
