import React, { Component } from 'react'
import { withContext, session } from 'services';
import { Header, Body, Dialog, Manat } from 'components/Views'
import { Collapse, Button } from "reactstrap"
import { Trans } from "react-i18next";
import { Ticket } from "components/Ticket";
import Bounty from 'react-bounty';
import { images } from "config";
import tools from "utils/tools";
import AnimatedNumber from "animated-number-react";
import FlipNumbers from 'react-flip-numbers';
import { ReactComponent as StarIcon } from '../assets/images/svg/star.svg'


class Game extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        // const ticket = tools.generateSingleTicket();
        // ticket.user = {
        //     userId: 21,
        //     name: "test",
        //     username: 'test'
        //   }
        //   ticket.prize = 100;
        this.state = {
            loading: true,
            showDialog: false,
            // showJackpot: false,
            // showJackpotResult: false,
            // jackpotCounter: 5,
            gameId: new URLSearchParams(this.props.location.search).get("gameId"),
            errorCode: 0,
            // jackpotWinners: [],
            isAuto: false,
            winners: null,
            passed: 0,
            game: null,
            play: {
                selectedNumbers: [],
                stat: null
            },
            tickets: [],
        }

        this.loadErrors();

        this.setStateWithMount = this.setStateWithMount.bind(this);
        this.updateGameDetails = this.updateGameDetails.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.onPlayReceived = this.onPlayReceived.bind(this);
        this.onWinnerReceived = this.onWinnerReceived.bind(this);
        // this.onJackpotWinnerReceived = this.onJackpotWinnerReceived.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
        this.onTicketChangeReceived = this.onTicketChangeReceived.bind(this);
        this.leaveGame = this.leaveGame.bind(this);
        this.onAutoClick = this.onAutoClick.bind(this);
        // this.jackpotWinnerCounter = this.jackpotWinnerCounter.bind(this);
        // this.jackpotCounter = this.jackpotCounter.bind(this);
        // this.showJackpotResult = this.showJackpotResult.bind(this);
    }

    showJackpotResult = () => {
        clearInterval(this.jackpotDrawTimer)
        this.setStateWithMount({
            showJackpotResult: true
        })
    }

    onAutoClick = () => {
        let { gameId, isAuto } = this.state;

        isAuto = !isAuto;

        this.props.client.setAuto(gameId, isAuto, (err) => {
            if (!err) {
                this.setStateWithMount({
                    isAuto
                })
            }
        });
    }

    loadErrors = () => {
        this.errors = {
            500: this.props.t("error.general"),
            404: this.props.t("error.gameNotFound"),
            206: this.props.t("error.cannotFindTicketForUser")
        };
    };


    setStateWithMount = (data, cb = null) => {
        if (this._isMounted) {
            if (cb !== null) {
                this.setState(data, cb);
            } else {
                this.setState(data);
            }
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isConnected !== this.props.isConnected && this.props.isConnected) {

            this.props.client.requestGameData(this.state.gameId, (err, data) => {
                if (err) {
                    // console.log(err);
                    this.showErrorMessage(err.code);
                } else {
                    this.updateGameDetails(data.game);
                    // console.log(data)
                    // console.log(data.play)
                    this.setStateWithMount({
                        play: data.play,
                        tickets: data.tickets
                    })
                }
            })
        }

    }

    componentDidMount = () => {
        this._isMounted = true;

        // console.log("didMount")

        this.props.client.requestGameData(this.state.gameId, (err, data) => {
            if (err) {
                // console.log(err);
                this.showErrorMessage(err.code);
            } else {

                this.props.client.registerGameListener(this.state.gameId, this.updateGameDetails)
                this.props.client.registerWinnersListener(this.onWinnerReceived);
                // this.props.client.registerJackpotWinnersListener(this.onJackpotWinnerReceived);
                this.props.client.registerPlayListener(this.onPlayReceived);
                this.props.client.registerTicketChangeListener(this.onTicketChangeReceived);

                this.updateGameDetails(data.game);

                this.setStateWithMount({
                    play: data.play,
                    isAuto: data.tickets[0].isAuto,
                    tickets: data.tickets
                })
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        // clear active timer
        clearInterval(this.timer)

        // unregister listener
        this.props.client.unregisterGameListener(this.state.gameId);
        this.props.client.unregisterWinnersListener(this.onWinnerReceived);
        // this.props.client.unregisterJackpotWinnersListener(this.onJackpotWinnerReceived);
        this.props.client.unregisterPlayListener(this.onPlayReceived);
        this.props.client.unregisterTicketChangeListener(this.onTicketChangeReceived);

    }

    leaveGame = () => {
        let history = this.props.history;
        this.props.client.leaveGame(this.state.gameId, function (err, data) {
            if (data === "okay") {
                history.push("/")
            }
        })
    }

    playSound = (sound) => {
        let settings = session.getSettings() || {}
        const { audio, isPageVisible } = this.props;
        const sounds = audio.getSounds();

        if (settings.isSoundOn && sounds && isPageVisible) {
            // sounds['ball'].currentTime = 0
            sounds[sound].play()
        }
    }

    onPlayReceived = (data) => {
        // let settings = session.getSettings() || {}
        // const { audio, isPageVisible } = this.props;
        // const sounds = audio.getSounds();

        // if (settings.isSoundOn && sounds && isPageVisible) {
        //     // sounds['ball'].currentTime = 0
        //     sounds['ball'].play()
        // }

        this.playSound('ball');

        // let showDialog = this.state.showDialog;

        // if (data.selectedNumbers.length > 0){
        //     showDialog = false;
        // }

        this.setStateWithMount({
            play: data,
            // showDialog: showDialog
        })
    }

    jackpotCounter = () => {
        let { jackpotCounter, showJackpot } = this.state;

        if (jackpotCounter === 0) {
            clearInterval(this.jackpotTimer)
            showJackpot = true;
            this.jackpotDrawTimer = setInterval(this.showJackpotResult, 9500)
        } else {
            jackpotCounter--;
        }

        this.setStateWithMount({ jackpotCounter, showJackpot })
    }

    jackpotWinnerCounter = () => {
        const { jackpotWinners } = this.state;
        // if there are any jackpot winners
        if (jackpotWinners.length > 0) {
            // if the first jackpot winner time equal to zero
            if (jackpotWinners[0].wait === 0) {
                // remove first element from the array
                if (jackpotWinners.length === 1) {
                    clearInterval(this.jackpotWinnersCounter);
                }
                jackpotWinners.shift();
            } else {
                jackpotWinners[0].wait -= 1;
            }
            this.setState({ jackpotWinners })
        } else {
            clearInterval(this.jackpotWinnersCounter);
        }
    }


    onTicketChangeReceived = (ticket) => {
        // console.log(ticket)
        let tickets = this.state.tickets;
        var index = tickets.findIndex(t => t.uuid === ticket.uuid);

        if (index !== -1) {
            tickets[index] = ticket;
        }

        this.setStateWithMount({ tickets: tickets });
    }

    onWinnerReceived = (data) => {
        // console.log("winners", data);
        if (this.state.game.jackpot) {
            if (this.state.game.jackpot.isInRange) {
                this.jackpotTimer = setInterval(this.jackpotCounter, 1000);
            }
        }

        this.setStateWithMount({
            winners: data
        })

        const isUserWon = this.isUserWon(data.tickets);

        if (isUserWon) {
            this.playSound('win');
        } else {
            this.playSound('lose');
        }
    }


    onJackpotWinnerReceived = (data) => {

        this.setStateWithMount({
            jackpotWinners: data
        })

        this.jackpotWinnersCounter = setInterval(this.jackpotWinnerCounter, 1000);
    }

    showErrorMessage = code => {
        // console.log("showError", code);
        this.setState({
            errorCode: code,
            showDialog: true,
            loading: false
        });
    };


    countdown = () => {
        let game = this.state.game;

        // decrease remaining time
        game.remaining--;

        if (game.remaining < -4) {
            window.location.reload();
        }


        // update game details
        this.setState({
            game: game
        })
    }

    updateGameDetails = (game) => {

        // console.log("game", game)

        // console.log("game_received", game);
        let showDialog = this.state.showDialog;

        if (game !== null) {
            if (game.status === "active") {
                showDialog = true;
            } else if (game.status === "starting") {
                // clear previous timer
                clearInterval(this.timer);

                // set new timer
                this.timer = setInterval(this.countdown, 1000);
                showDialog = true;
            } else if (game.status === "started") {
                // clear countdown timer
                clearInterval(this.timer);

                showDialog = false;
            }

            if (game === undefined) {
                this.showErrorMessage(404);
            } else {
                this.setState({
                    game: game,
                    loading: false,
                    showDialog: showDialog
                })
            }
        }

    }

    handleCellClick(index, x, y) {
        if (!this.state.isAuto) {
            let ticket = this.state.tickets[index];
            let selectedNumbers = this.state.play.selectedNumbers;
            if (!ticket.numbers[x][y].isMissed && !ticket.numbers[x][y].isRevealed) {
                if (selectedNumbers.includes(ticket.numbers[x][y].value)) {
                    // console.log("clicked")
                    let tickets = this.state.tickets;
                    // set ticket marked
                    tickets[index].numbers[x][y].isPending = true;

                    this.setStateWithMount({
                        tickets: tickets
                    })

                    this.props.client.cell(
                        this.state.gameId,
                        {
                            ticketId: ticket.uuid,
                            x: x,
                            y: y
                        }, (err) => {

                            if (err) {
                                let tickets = this.state.tickets;
                                // set ticket marked
                                tickets[index].numbers[x][y].isPending = false;
                                // console.log("not_changed")
                            } else {
                                // console.log("changed")
                                tickets[index].numbers[x][y].isRevealed = true;
                            }

                            this.setStateWithMount({
                                tickets: tickets
                            })

                        });
                }
            }
        }
    }




    renderJackpot = () => {
        const { showJackpot, winners, showJackpotResult, game } = this.state;
        const { t, user } = this.props;

        let content, result, message, title, className;

        let hasAnimation = false;

        if (winners !== null) {

            let prize = '000';


            if (winners.jackpot) {
                // create lsit view from tickets
                const ticket = winners.jackpot.ticket;
                hasAnimation = true
                prize = winners.jackpot.prize;

                if (ticket.user.userId === user.userId) {
                    message = (
                        <div className="jackpot-winner-message" style={{ height: showJackpotResult ? "auto" : 0 }}>
                            <span style={{ fontSize: 25, color: "#dc3545" }} role="img" aria-label="won">{t('game.congratulations')}! 🤩</span>
                            <span className="jackpot-winner">{t('game.youWonJackpot')}</span>
                        </div>
                    )
                } else {
                    const jackpotWinnerUsername = ticket.user.name;
                    message = (
                        <div className="jackpot-winner-message" style={{ height: showJackpotResult ? "auto" : 0 }}>
                            <span style={{ fontSize: 25, color: "#dc3545" }} role="img" aria-label="won">{t('game.jackpotWon')}! 🤩</span>
                            <span><Trans i18nKey={'game.othersWonJackpot'}>Qızıl kartın sahibi <span className="jackpot-winner">{{ jackpotWinnerUsername }}</span> oldu.</Trans></span>
                        </div >
                    )
                }

                result =
                    (
                        <div className="px-2">
                            <div className="py-2">
                                {message}
                            </div>
                            <Ticket
                                key={ticket.id}
                                size="small jackpot-winner"
                                isGame={false}
                                editable={false}
                                ticketData={ticket.numbers}
                            />
                        </div>
                    )

            } else {
                result = (
                    <div className="px-2">
                        <div className="jackpot-winner-message" style={{ height: showJackpotResult ? "auto" : 0 }}>
                            <span style={{ fontSize: 25, color: "#dc3545" }} role={"img"} aria-label="sad">{t('game.jackpotNotWon')} 😞</span>
                            <span style={{ textAlign: "center" }}>{t('game.jackpotNotWonMessage')}</span>
                        </div>
                    </div>
                )
            }

            if (!showJackpotResult) {
                hasAnimation = false;
            }

            content = (
                <div className="expand-container">
                    <div className={`expand-content ${showJackpotResult ? 'expanded' : ''}`}>
                        {result}
                        <div className="modal-footer" style={{ marginTop: 19, padding: 1 }}>
                            <Button className="btn-cancel" onClick={() => { this.props.history.push("/") }}>{t('dialog.close')}</Button>
                            <Button className="btn-accept" onClick={() => { this.props.history.replace(`/ticket?gameId=${game.nextGameUUID}`) }}>{t('dialog.joinAgain')}</Button>
                        </div>
                    </div>
                </div>
            )

            const prizeToShow = parseInt(prize).toString().padStart(3, '0').split('')
            // content = null;

            return (
                <Dialog
                    t={this.props.t}
                    className={className}
                    title={title}
                    hideFooter={true}

                    // cancelText="asfasf"
                    // acceptText="asfaf"
                    isOpen={showJackpot}
                >
                    <div >
                        <div className="d-flex w-100 align-items-center justify-content-center flex-column" style={{ marginBottom: -20 }}>
                            <div className={`bouncing-text ${hasAnimation ? '' : 'no-animation'}`}>
                                <div className="b">J</div>
                                <div className="o">A</div>
                                <div className="u">C</div>
                                <div className="n">K</div>
                                <div className="c">P</div>
                                <div className="e">O</div>
                                <div className="d">T</div>
                            </div>
                            <img src={`/jackpot/jackpotWinners.jpg`} alt='jackpot' style={{ height: 'auto' }} />
                            <div className={`jackpot-sign-container ${hasAnimation ? '' : 'no-animation'}`}>

                                <Bounty value={prizeToShow[0]} animationDelay={1000} initialValue={'0'} letterAnimationDelay={5000} duration={6000} className="bounty" />
                                <Bounty value={prizeToShow[1]} animationDelay={1400} initialValue={'0'} letterAnimationDelay={5000} duration={7000} className="bounty" />
                                <Bounty value={prizeToShow[2]} animationDelay={1800} initialValue={'0'} letterAnimationDelay={5000} duration={7500} className="bounty" />
                            </div>

                            {/* <span className="jackpot-winner-prize" >501</span> */}
                        </div>
                        <div className="px-0">
                            {content}
                        </div>
                    </div>
                </Dialog>
            )
        }
    }

    renderJackpotWinners = () => {
        const { jackpotWinners } = this.state;
        const { t } = this.props;
        let title, className, content, isOpen = false;

        if (jackpotWinners.length > 0) {
            isOpen = true;

            const jackpotWinner = jackpotWinners[0];
            let { ticket, wait, prize, ticketPrice } = jackpotWinner

            ticketPrice = ticketPrice.toFixed(0)
            prize = parseInt(prize);

            // create lsit view from tickets
            const ticketsListView =
                // className = "confetti medal-background";
                (
                    <div style={{ marginTop: 10 }}>
                        <Ticket
                            key={ticket.id}
                            size="small jackpot-winner"
                            isGame={false}
                            editable={false}
                            ticketData={ticket.numbers}
                        />
                    </div>
                )
            title = (
                <span className="countdown-timer">{wait}</span>
            )

            const jackpotWinnerUsername = ticket.user.name;

            content = (
                <div >
                    <div className="d-flex w-100 align-items-center justify-content-center flex-column" style={{ marginBottom: -5 }}>
                        <div className="bouncing-text">
                            <div className="b">J</div>
                            <div className="o">A</div>
                            <div className="u">C</div>
                            <div className="n">K</div>
                            <div className="c">P</div>
                            <div className="e">O</div>
                            <div className="d">T</div>
                        </div>
                        <img src={`/jackpot/jackpotWinners.jpg`} alt='jackpot' style={{ height: 'auto' }} />
                        <div className="jackpot-sign-container">
                            {prize.toString().padStart(3, '0').split('').map((digit, index) => {
                                return (<span key={index} className="jackpot-winner-prize">{digit}</span>)
                            })}
                        </div>
                    </div>
                    <span className="game-result-info">
                    </span>
                    <div className="px-3">
                        <div className="jackpot-winner-message">
                            <span style={{ fontSize: 25, color: "#dc3545" }} role="img" aria-label="won">{t('game.jackpotWon')}! 🤩</span>
                            <span><Trans i18nKey={'game.jackpotWonByRoom'}><span style={{ fontWeight: 600, fontSize: 21 }}>{{ ticketPrice }} ₼ </span> otaqda qızıl kartın sahibi <span className="jackpot-winner">{{ jackpotWinnerUsername }}</span> oldu.</Trans></span>
                        </div >
                        {ticketsListView}
                    </div>
                </div>
            )
        }

        return (
            <Dialog
                t={this.props.t}
                className={className}
                title={title}
                isOpen={isOpen}
            >
                {content}
            </Dialog>
        )
    }

    renderKegs(num, numberRow) {
        //let num = numbers.slice(0,5);
        return new Array(5).fill(0).map((current, index) => {
            let mainClass = index === 0 ? "keg-hole main" : "keg-hole";
            if (num[index] !== void 0 && num[index] !== null) {
                //console.log(num[num.length - 1], index);
                return (
                    <div className={mainClass} key={index}>
                        <div className="loto-keg-new">
                            <span>{num[index]}</span>
                        </div>
                        {index === 0 && <span className="label-number">{numberRow}</span>}
                    </div>
                );
            } else {
                return <div className={mainClass} key={index} />;
            }
        });
    }


    renderTickets = tickets => {
        const { game } = this.state;
        if (game) {
            const { settings } = game
            const gradient = JSON.parse(settings.colorGradient)

            const gradientStyle = `linear-gradient(to bottom, ${gradient[0]} 0%, ${gradient[1]} 100%)`

            return tickets.map((datarow, index) => {
                return (
                    <div style={{ borderRadius: 9, padding: 4, background: gradientStyle, marginBottom: 10 }} key={index}>

                        <Ticket
                            key={datarow.id}
                            index={datarow.index}
                            isGame={true}
                            selected={datarow.selected}
                            ticketData={datarow.numbers}
                            onCellClick={this.handleCellClick.bind(this, index)}
                        />
                    </div>


                );
            });
        }
    };


    isUserWon = (tickets) => {
        const { user } = this.props;

        return tickets.filter((ticket) => parseInt(ticket.user.userId) === user.user_id).length > 0
    }

    checkWinner = tickets => {
        // console.log("called")
        let result = {
            ticketsWon: 0,
            prizeWon: 0,
            singleWinner: false
        };
        const user = session.getUser();
        tickets.forEach(ticket => {
            // console.log("res", ticket.user.userId, user.user_id)
            if (parseInt(ticket.user.userId) === parseInt(user.user_id)) {
                result.ticketsWon++;
                result.prizeWon += ticket.prize;
            }
        });

        if (result.ticketsWon === tickets.length) {
            result.singleWinner = true;
        }

        return result;
    };


    renderWinners = () => {
        const { winners, game, showJackpot } = this.state;
        // const { t } = this.props;

        let content, isOpen = false, message, title, className, cancelText = this.props.t("dialog.goBack")
        let acceptText = this.props.t('dialog.joinAgain');

        if (winners !== null) {
            isOpen = !showJackpot;
            if (winners.found) {
                // console.log("Found winners");

                // get the result of the game
                const gameResult = this.checkWinner(winners.tickets);

                // check if user won the game alone
                if (gameResult.singleWinner) {
                    title = this.props.t("game.youWon");

                    className = "confetti medal-background";

                    let prize = parseFloat(gameResult.prizeWon).toFixed(0);
                    message = (
                        <Trans i18nKey="game.onlyWinnerText">
                            Sizi təbrik edirik. Siz qalib oldunuz. Balansınıza
                            <span style={{ color: "black", fontWeight: "bold" }}>
                                {" "}
                                {{ prize }} <Manat />
                            </span>{" "}
                            yatırıldı.
                        </Trans>
                    )

                    content = (
                        <div className="modal-body-list">
                            <span className="game-result-info">
                                {message}
                            </span>
                        </div>
                    )
                } else {

                    // create lsit view from tickets
                    const ticketsListView =
                        winners.tickets.map((ticket, index) => {
                            return (
                                <div key={"container" + index} style={{ marginTop: 10 }}>
                                    <div className="winner-user-info">
                                        <span className="winner-name">{ticket.user.name}</span>
                                        <span className="winner-prize">{ticket.prize.toFixed(0)}<Manat width={15} height={8} color="#d9720a" /></span>
                                    </div>
                                    <Ticket
                                        key={ticket.id}
                                        size="small"
                                        isGame={false}
                                        editable={false}
                                        ticketData={ticket.numbers}
                                    />
                                </div>
                            )

                        });


                    if (gameResult.ticketsWon === 0) {
                        className = "sad-background";
                        title = this.props.t("game.gameFinished");
                        message = (
                            <Trans i18nKey="game.youLostText">
                                Təəssüf ki, siz{" "}
                                <span style={{ color: "black" }}> qalib ola bilmədiniz</span>
                                . Aşağıda qalib olanların siyahısı:
                            </Trans>
                        )
                    } else {
                        className = "confetti medal-background";
                        title = this.props.t("game.youWon");

                        const winnerCount = winners.tickets.length;
                        const prizeCount = gameResult.prizeWon;
                        const ticketCount = gameResult.ticketsWon;

                        message = (
                            <Trans i18nKey="game.multipleWinnerText">
                                Təbrik edirik. Sizin{" "}
                                <span style={{ color: "green", fontWeight: "700" }}>{{ ticketCount }}</span>{" "}
                                biletiniz
                                <span style={{ color: "black", fontWeight: "700" }}>
                                    {{ winnerCount }}{" "}
                                </span>{" "}
                                qalib bilet dsgsg. Balansınıza
                                <span style={{ color: "black", fontWeight: "bold" }}>
                                    {{ prizeCount }} <Manat color="black" />
                                </span>
                                yatırıldı. Bütün qalib biletlərin siyahısı:
                            </Trans>
                        );
                    }

                    const toggle = !this.state.showList ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                        </svg>;

                    content = (
                        <div className="modal-body-list">
                            <span className="game-result-info">
                                {message}
                            </span>


                            <div className="list-of-winners-bar">
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 9, alignItems: "center" }}>
                                    <span style={{ color: "#414141" }}>{this.props.t("game.listOfWinners")}</span>
                                    <Button className="btn-img" onClick={() => {
                                        this.setStateWithMount({
                                            showList: !this.state.showList
                                        })
                                    }}>{toggle}</Button>
                                </div>


                                <Collapse isOpen={this.state.showList}>
                                    {ticketsListView}
                                </Collapse>
                            </div>


                        </div>
                    )
                }
            } else {
                className = "confetti";
                // console.log("No winners found");
                title = this.props.t("game.winnerNotFound")
                let prize =
                    ((this.state.tickets.length * this.state.game.ticketPrice * (100 - winners.commission)) / 100).toFixed(0);

                let commission = winners.commission;
                message = (
                    <Trans i18nKey="game.winnerNotFoundText">
                        Bu oyunda heç bir qalib tapılmadı. Biletlərinizdən oyunun
                        komissiya faizi
                        <span style={{ color: "black" }}> {{ commission }} % </span>
                        çıxılaraq balansınıza qaytarıldı. Ümumi məbləğ{" "}
                        <span style={{ color: "black" }}> {{ prize }} <Manat /> </span> .
                    </Trans>
                )

                content = (
                    <div className="modal-body-list">
                        <span className="game-result-info">
                            {message}
                        </span>
                    </div>
                )
            }

            /* jackpot stuff zzz
            if (game.jackpot) {
                if (game.jackpot.isInRange) {
                    cancelText = ""
                    acceptText = ""
                    title = (
                        <div>
                            {title}
                            <span className="countdown-timer">{this.state.jackpotCounter}</span>
                        </div>
                    )
                }
            }
            */

        }

        return (
            <Dialog
                t={this.props.t}
                className={className}
                title={title}
                onCancelClick={() => {
                    this.props.history.push("/");
                }}
                isOpen={isOpen}
                acceptText={acceptText}
                onAcceptClick={() => {
                    this.props.history.replace(`/ticket?gameId=${game.nextGameUUID}`)
                }}
                cancelText={cancelText}
            >
                {content}
            </Dialog>
        )
    }

    renderDialog = () => {

        let title, content, acceptButton, onCancelClick, bodyStyle = {}, cancelText, onAcceptClick, className;


        if (this.state.errorCode !== 0) {
            title = this.props.t("dialog.error")
            // set default text for cancel button
            cancelText = this.props.t("dialog.close");
            acceptButton = "";
            content = this.errors[this.state.errorCode];

            if (this.state.errorCode === 404 || this.state.errorCode === 104) {
                onCancelClick = () => {
                    this.props.history.push("/");
                }
            }
        } else {
            const { game } = this.state;

            if (game) {
                const { settings, jackpot } = game
                const jackpotAmount = parseInt(jackpot.currentAmount).toString().padStart(3, '0');
                const isInRange = jackpot.isInRange
                let label, clock;

                const gradient = JSON.parse(settings.colorGradient)

                const gradientStyle = {
                    background: `linear-gradient(to bottom, ${gradient[0]} 0%, ${gradient[1]} 100%)`
                }

                let ticketPrize = <> {game.ticketPrice.toFixed(0)}<Manat /></>
                // console.log(gradient, gradientStyle, ticketPrize)

                if (game.status === "starting") {
                    label = this.props.t("gameItem.starting")
                    clock = tools.secondsToClock(game.remaining);
                } else if (game.status === "active") {
                    label = this.props.t("gameItem.waiting")
                    clock = "00:00"
                    cancelText = this.props.t("dialog.exit");
                    onCancelClick = this.leaveGame
                }


                className = "clock-background";
                bodyStyle = { paddingTop: 0, paddingBottom: 10, paddingRight: 20, paddingLeft: 20 }

                content = (
                    <div className="current-game-info" style={{ padding: 0 }}>
                        <div className="current-game-stats">
                            <div className='d-flex flex-row align-self-start position-relative'>
                                <div style={gradientStyle} className={"game-title new2 " + (game.isNew ? "new-game" : "")}><span className="type">{game.typeId === 1 ? "classic" : "lottery"}:</span><span style={{ textShadow: `0px 1px ${settings.colorSecondary}` }}>{ticketPrize}</span>
                                    {game.gameId === 1 && <span className="new-label">{this.props.t("gameItem.fullTicket")}</span>}
                                </div>
                                <div className='coin-container'>
                                    <div className='game-star'>
                                        {game.coins.amount} <StarIcon height={30} width={30} />
                                    </div>
                                    <div style={{ marginTop: -9 }}>{this.props.t('gameItem.get')}</div>
                                    {game.coins.multiplier !== 1 &&

                                        <span className='multiplier'>x{game.coins.multiplier}</span>}
                                </div>
                            </div>
                            {/* <span className="game-title" style={{ fontSize: 14, }}>{game.typeId === 1 ? "classic" : "lottery"}</span> */}
                            <div className="game-prize" style={{ marginTop: 5 }}>
                                <span className="online-users"><span className="count">{game.userCount}</span> {this.props.t("game.player")}</span>
                                {game.ticketPrice !== 0 && <span className="prize"><img alt="prize" src={images.coinstack} style={{ width: 38, marginRight: 3 }} /> <span className="count">
                                    <AnimatedNumber
                                        value={game.money}
                                        formatValue={(value) => value.toFixed(0)}
                                    /><Manat height={18} style={{ marginLeft: 3 }} /></span></span>}
                            </div>

                        </div>
                        <div className="game-info">
                            <div className="clock-info">
                                <span className={game.status === "starting" ? "label starting" : "label"}>{label}</span>
                                <span className={game.status === "starting" ? "clock starting" : "clock"}>{clock}</span>
                            </div>

                            {/* zzz jackpot stuff
                            <span className={isInRange ? 'jackpot-sign draw' : 'jackpot-sign'} style={{ marginTop: 13 }}>JACKPOT</span>
                            <div className='jackpot'>
                                <img src={`/jackpot/jackpotBg.svg`} alt='jackpot' />
                                <FlipNumbers height={20} width={21} color={isInRange ? "#dc3545" : "#414141"} play
                                    perspective={70} numberStyle={{ fontSize: 12, fontWeight: 600, fontFamiily: "cursive", textShadow: "0px 1px 1px rgba(0,0,0,0.5)" }} duration={2} numbers={jackpotAmount} />
                            </div>
                            */}
                        </div>

                    </div>
                )
            }
        }

        return (
            <Dialog
                t={this.props.t}
                // toggle={onCancelClick}
                cancelText={cancelText}
                onCancelClick={onCancelClick}
                acceptText={acceptButton}
                onAcceptClick={onAcceptClick}
                title={title}
                className={className}
                bodyStyle={bodyStyle}
                isOpen={this.state.showDialog}
            >
                {content}
            </Dialog>
        )

    }

    render = () => {
        // console.log(this.state);
        const { game, isAuto, play } = this.state;
        const userCount = game ? game.userCount : 0;
        const money = parseFloat(game ? game.money : 0).toFixed(0)

        return (
            <div className="d-flex flex-fill flex-column flex-nowrap">
                {/* zzz jackpot stuff 
                {this.renderJackpot()}
                {this.renderJackpotWinners()} 
                */}                 
                {this.renderWinners()}
                {this.renderDialog()}
                <Header
                    jackpotBelow={true}
                    logoClick={false}
                    auto={isAuto ? "on" : "off"}
                    t={this.props.t}
                    players={userCount}
                    prize={money}
                    stat={play.stat}
                    onAutoClick={this.onAutoClick}
                    hideTopBar={true}
                    showGameInfo={true}
                >
                    <div className="number-list">
                        {this.renderKegs(this.state.play.selectedNumbers, this.state.play.selectedNumberRow)}
                    </div>
                </Header>

                <Body style={{ paddingTop: 10 }} loading={this.state.loading}>
                    {this.renderTickets(this.state.tickets)}
                </Body>

            </div >

        )
    }
}

export default withContext(Game);