

import * as session from './session'
import { config } from '../config'
import axios from "axios";

// console.log("group", livechat_group)

let tokenPromise = null
let cachedToken = null

const fetchLiveChatToken = () => {
  if (session.getUser()) {
    return axios
      .get(config.API_URL + "/lc/token",
        {
          headers: {
            Authorization: session.getUser().token
          }
        }
      )
  }
}

// const fetchToken = () => {
//   tokenPromise = fetchLiveChatToken().then(result => {
//     tokenPromise = null
//     return result.data
//   });

//   return tokenPromise
// }

const isExpired = ({ creationDate, expiresIn }) => Date.now() >= creationDate + expiresIn

const getToken = () => {
  if (tokenPromise) {
    return tokenPromise
  }

  if (session.getLCToken() && !isExpired(session.getLCToken())) {
    return Promise.resolve(session.getLCToken())
  }

  return getFreshToken()
}



const getFreshToken = () => {
  tokenPromise = fetchLiveChatToken().then(
    res => {
      tokenPromise = null
      // session.setItem(cacheKey, JSON.stringify(token))
      session.setLCToken(res.data)
      // console.log("getting token", res.data)

      cachedToken = res.data
      return res.data
    }
  )
  return tokenPromise
}

const hasToken = () => {
  return Promise.resolve(!!cachedToken)
}

const invalidate = () => {
  cachedToken = null
  return session.deleteLCToken()
}

export const provider = () => ({
  getToken,
  getFreshToken,
  hasToken,
  invalidate
})
