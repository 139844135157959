const configProduction = {
  API_URL: "https://api2.milli10.com/rest",
  SOCKET_URL: "https://api2.milli10.com/realtime",
  STATIC_URL: "https://static2.milli10.com",
};

const configDevelopment = {
  API_URL: "https://apiBeta2.milli10.com/rest",
  SOCKET_URL: "https://apiBeta2.milli10.com/realtime",
  STATIC_URL: "https://static.milli10.com",
}

// console.log(process.env) 

const config =
  process.env.REACT_APP_RELEASE_TYPE === "beta" ? configDevelopment : configProduction

export default config;
