import React, { Component } from 'react'

export default class NotFound extends Component {
  render() {
    return (
      <div className="w-100 h-100 bg-white text-dark">
        not found
      </div>
    )
  }
}
