import React, { Component } from 'react';
import { withContext, session } from 'services';
import { ContainerView, LoadingButton, Dialog } from 'components/Views'
import { images, config } from "config"
import axios from "axios";


class Language extends Component {

  constructor(props) {
    super(props);

    const user = session.getUser();

    this.state = {
      user,
      dialog: {
        show: false,
        title: "",
        message: "",
      },
      isLoading: false,
      languageCode: user.language_code
    }

    this.setStateWithMount = this.setStateWithMount.bind(this);
    this.updateLanguage = this.updateLanguage.bind(this);
  }

  updateLanguage() {
    let { dialog, languageCode, user } = this.state;
    this.setStateWithMount({ isLoading: true });
    axios
      .put(config.API_URL + "/language", {
        language_code: languageCode,
      },
        {
          headers: {
            Authorization: user.token
          }
        }
      )
      .then(result => {
        let data = result.data;

        if (data.status === "error") {
          dialog.title = this.props.t("dialog.error");
          dialog.message = this.props.t("error.cannotChangeLanguage");
          dialog.show = true;
          this.setStateWithMount({
            isLoading: false,
            dialog,
          })
        } else {
         
          user.language_code = languageCode;
          session.setUser(user);

          this.props.i18n.changeLanguage(languageCode, () => {
            dialog.title = this.props.t("dialog.success");
            dialog.message = this.props.t("language.languageChangeSuccess");
            dialog.show = true;
            this.setStateWithMount({
              isLoading: false,
              dialog,
            })
          });
        }
      })
      .catch(error => {
        // console.log(error);
        this.setStateWithMount({
          isLoading: false
        })
      });

  }

  setStateWithMount = (data, cb = null) => {
    if (this._isMounted) {
      if (cb !== null) {
        this.setState(data, cb);
      } else {
        this.setState(data);
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render = () => {
    let { isLoading, languageCode, dialog } = this.state;
    return (
      <ContainerView loading={this.props.isChatLoading} t={this.props.t}>
        <Dialog
          t={this.props.t}
          isOpen={dialog.show}
          toggle={() => {
            dialog.show = !dialog.show;
            this.setStateWithMount({
              dialog
            })
          }}
          cancelText={this.props.t("dialog.close")}
          onCancelClick={() => {
            dialog.show = false;

            this.setStateWithMount({
              dialog
            })
          }}
          title={dialog.title}
          content={dialog.message}
        >
        </Dialog>
        <div className="section-header">{this.props.t("language.chooseLanguage")}</div>
        <div className="page-container">
          {/* zzz <Flag
            code="az"
            selected={languageCode === "az"}
            onClick={() => { this.setStateWithMount({ languageCode: "az" }) }}
          /> */}
          <Flag
            code="kk"
            selected={languageCode === "kk"}
            onClick={() => { this.setStateWithMount({ languageCode: "kk" }) }}
          />        
          <Flag
            code="en"
            selected={languageCode === "en"}
            onClick={() => { this.setStateWithMount({ languageCode: "en" }) }}
          />
          {/*zzz
          <Flag
            code="tr"
            selected={languageCode === "tr"}
            onClick={() => { this.setStateWithMount({ languageCode: "tr" }) }}
          />*/}
          <Flag
            selected={languageCode === "ru"}
            onClick={() => { this.setStateWithMount({ languageCode: "ru" }) }}
            code="ru"
          />
        </div>

        <LoadingButton
          className="btn-accept btn-profile"
          isLoading={isLoading}
          style={{ marginTop: 15 }}
          onClick={this.updateLanguage}
          text={this.props.t("language.changeLanguage")}
        />

      </ContainerView>

    )
  }
}

const Flag = (props) => {
  // zzz let { code = "tr", selected = false, onClick } = props;
  let { code = "kk", selected = false, onClick } = props;
  let title;

  switch (code) {
    /* zzz case "az": title = "Azərbaycan Dili"; break;
    case "tr": title = "Türkçe"; break;
    */
    case "kk": title = "Қазақша"; break;
    case "ru": title = "Pусский"; break;
    case "en": title = "English"; break;
    // zzz default: title = "Azərbaycan Dili";
    default: title = "Қазақша";
  }

  return (
    <div className="flag-selection" onClick={onClick}>
      <img src={images.flags[code]} alt={"flag_" + code} className="flag"></img>
      <span>{title}</span>
      {selected &&
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#59C99E" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
      }

      {!selected &&
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        </svg>
      }
    </div>
  )
}

export default withContext(Language);