import React, { Component } from 'react'
import { withContext } from 'services';
import { Button } from "reactstrap";
import { LoadingButton, Dialog, PaymentContainer } from 'components/Views'
import NumberFormat from 'react-number-format';
import axios from "axios";
import { images, config } from "config";
import * as clonedeep from "lodash.clonedeep"

class Deposit extends Component {
  constructor(props) {
    super(props);

    this.initDeposits();

    this.state = {
      selected: {
        country: "kz",
        rowIndex: null,
        index: null,
      },
      messageDialog: {
        message: '',
        accept: '',
        enabled: false,
        index: 0,
        onAccept: null
      },
      errorDialog: {
        enabled: false,
        message: '',
        index: 0
      },
      inputFields: [],
      isPageLoading: false,
      isDepositLoading: false,
    }

    this.setStateWithMount = this.setStateWithMount.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.formRef = React.createRef();
    this.renderMessageDialog = this.renderMessageDialog.bind(this)
    this.renderErrorDialog = this.renderErrorDialog.bind(this)
  }

  setStateWithMount = (data, cb = null) => {
    if (this._isMounted) {
      if (cb !== null) {
        this.setState(data, cb);
      } else {
        this.setState(data);
      }
    }
  };

  initDeposits() {
    const { t } = this.props;

    const name = {
      value: "",
      label: t('wallet.name'),
      name: "name",
      placeholder: "nameplaceholder",
      maxLength: 15,
      regex: /^[A-Za-z]+$/,
      required: true
    }

    const surname = {
      value: "",
      label: t('wallet.surname'),
      name: "surname",
      placeholder: "surname_placeholder",
      maxLength: 15,
      regex: /^[A-Za-z]+$/,
      required: true
    }

    const patronymic = {
      value: "",
      label: t('wallet.patronymic'),
      name: "father",
      placeholder: "surname_placeholder",
      regex: /^[A-Za-z]+$/,
      maxLength: 15,
      required: true
    }

    const amount = {
      value: "",
      label: t('wallet.amount'),
      name: "amount",
      regex: /\d+/,
      placeholder: "0",
      required: true,
      selections: [5, 10, 20, 30, 100]
    }

    // const abbBankMessage = {
    //   message: "Abb banklarda issue",
    //   accept: "asfsaf"
    // }

    // const atbBankMessage = {
    //   message: "Atb banklarda issue",
    //   accept: "asfsaf"
    // }

    this.azDepositMethods = {
      visa: {
        enabled: true,
        title: "Visa",
        icon: images.cardType.visa,
        minAmount: 2,
        maxAmount: 1000,
        fields: [{ ...amount, value: 50, selections: [5, 25, 50, 100, 250] }],
        endpoint: "/payment/deposit?cardType=visa",
        // messages: [abbBankMessage],
        video: null,

      },
      master: {
        enabled: true,
        title: "Mastercard",
        icon: images.cardType.mastercard,
        minAmount: 2,
        maxAmount: 1000,
        fields: [{ ...amount, value: 50, selections: [5, 25, 50, 100, 250] }],
        endpoint: "/payment/deposit?cardType=mc",
        // messages: [abbBankMessage, atbBankMessage],
        video: null
      },
      c2c: {
        enabled: false,
        title: "Card2Card",
        icon: images.cardType.c2c,
        minAmount: 15,
        maxAmount: 1000,
        fields: [{ ...name }, { ...surname }, { ...amount, value: 50, selections: [15, 30, 50, 100, 250] }],
        endpoint: "/payment/visa",
        info: t("wallet.correctInformationMessage"),
        video: null
      },
      smartpay: {
        enabled: false,
        title: "SmartPay",
        icon: images.cardType.smartpay,
        minAmount: 20,
        maxAmount: 1000,
        fields: [{ ...name }, { ...surname }, { ...amount, value: 50, selections: [20, 50, 75, 100, 200] }],
        endpoint: "/payment/visa",
        // message: "This is a message for top",
        video: null
      },
      emanat: {
        enabled: false,
        title: "eManat+",
        icon: images.cardType.emanat,
        minAmount: 20,
        maxAmount: 1000,
        fields: [{ ...name }, { ...surname }, { ...amount, value: 50, selections: [20, 50, 75, 100, 200] }],
        endpoint: "/payment/visa",
        // message: "This is a message for top",
        video: null
      }
    }

    this.kzDepositMethods = {
      visa: {
        enabled: true,
        title: "Visa",
        icon: images.cardType.visa,
        minAmount: 500,
        maxAmount: 50000,
        fields: [{ ...amount, value: 5000, selections: [500, 1000, 3000, 4000, 5000] }],
        endpoint: "/payment/deposit?cardType=visa",
        // messages: [abbBankMessage],
        video: null,

      },
      master: {
        enabled: true,
        title: "Mastercard",
        icon: images.cardType.mastercard,
        minAmount: 500,
        maxAmount: 50000,
        fields: [{ ...amount, value: 5000, selections: [500, 1000, 3000, 4000, 5000] }],
        endpoint: "/payment/deposit?cardType=mc",
        // messages: [abbBankMessage, atbBankMessage],
        video: null
      }
    }    

    this.ruDepositMethods = {
      visa: {
        enabled: false,
        title: "Visa",
        icon: images.cardType.visa,
        minAmount: 5,
        maxAmount: 1000,
        fields: [{ ...name }, { ...surname }, { ...patronymic }, { ...amount, value: 5, selections: [5, 25, 50, 100, 250] }],
        endpoint: "/payment/visa",
        info: t("wallet.correctInformationMessage"),
        video: null
      },
      master: {
        enabled: false,
        title: "Mastercard",
        icon: images.cardType.mastercard,
        minAmount: 5,
        maxAmount: 1000,
        fields: [{ ...name }, { ...surname }, { ...patronymic }, { ...amount, value: 5, selections: [5, 25, 50, 100, 250] }],
        endpoint: "/payment/visa",
        info: t("wallet.correctInformationMessage"),
        video: null
      }
    }
  }

  getDepositMethod() {
    const { selected } = this.state;

    return selected.country === 'kz' ? this.kzDepositMethods[selected.key] : this.ruDepositMethods[selected.key]
  }

  onInputChange = (index, value) => {
    let { inputFields, isDepositLoading } = this.state;

    if (isDepositLoading) {
      return
    }

    const field = inputFields[index];
    const { regex, maxLength } = field;

    if (value !== '') {
      if (!regex.test(value)) {
        return
      }
    }

    if (maxLength !== null && value.length > maxLength) {
      return
    }
    inputFields[index].error = null;
    inputFields[index].value = value;

    this.setStateWithMount({ inputFields })
  }


  getParams(fields) {
    return fields.map((field) => `${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`).join('&');
  }


  createRequestURI(endpoint, query) {
    if (/^.+(\?)\w+=.+$/.test(endpoint)) {
      return `${config.API_URL}${endpoint}&${query}`
    } else if (/^.+(\?)$/.test(endpoint)) {
      return `${config.API_URL}${endpoint}${query}`;
    } else {
      return `${config.API_URL}${endpoint}?${query}`;
    }
  }


  isFormValid() {
    let { inputFields } = this.state;
    const { t } = this.props;
    let isValid = true;

    for (let i = 0; i < inputFields.length; i++) {
      // const index = inputFields.findIndex((input => input.name === 'amount'));
      const field = inputFields[i];

      if (field.name === 'amount') {
        const { minAmount, maxAmount } = this.getDepositMethod();
        const amount = parseFloat(field.value)
        if (amount < minAmount) {
          inputFields[i].error = `${t("wallet.minimum")} ${minAmount.toFixed(0)}`
          isValid = false;
        }

        if (amount > maxAmount) {
          inputFields[i].error = `${t("wallet.maximum")} ${maxAmount.toFixed(0)}`
          isValid = false
        }
      } else {
        if (field.required && field.value === '') {
          inputFields[i].error = t('wallet.requiredField')
          isValid = false
        }
      }
    }

    this.setStateWithMount({
      inputFields
    })
    return isValid;
  }

  sendRequest(endpoint, messages = []) {
    if (!this.isFormValid()) {
      return
    }
    const { inputFields, errorDialog, messageDialog } = this.state;
    const { user, t } = this.props;

    if (messages.length > 0 && messages.length > messageDialog.index) {
      const message = messages[messageDialog.index];
      messageDialog.message = message.message;
      messageDialog.enabled = true;
      messageDialog.accept = message.accept;
      messageDialog.onAccept = () => { this.sendRequest(endpoint, messages); }
      this.setStateWithMount({ messageDialog })
      return
    } else if (messages.length === messageDialog.index) {
      messageDialog.index = 0;
      messageDialog.enabled = false;
    }


    this.setStateWithMount({
      isDepositLoading: true
    })

    const query = this.getParams(inputFields);
    axios
      .get(this.createRequestURI(endpoint, query),
        {
          timeout: 20000,
          headers: {
            Authorization: user.token
          }
        }
      )
      .then(result => {
        const data = result.data;
        if (data.status === "success") {
          const forwardURL = data.data.forwardUrl;
          // console.log(forwardURL)
          window.open(forwardURL, "_self")
        } else {
          errorDialog.enabled = true;
          errorDialog.message = t("wallet.depositNotWorkingTryDifferent")
        }
        this.setStateWithMount({
          isDepositLoading: false,
          errorDialog
        })
      })
      .catch(error => {
        errorDialog.enabled = true;
        errorDialog.message = t("wallet.depositNotWorkingTryDifferent")
        this.setStateWithMount({
          isDepositLoading: false,
          errorDialog
        })
      });
  }


  renderMessageDialog = () => {
    const { t } = this.props;
    const { messageDialog } = this.state
    const { enabled, message, accept, index, onAccept } = messageDialog;

    return (
      <Dialog
        title={t("dialog.info")}
        isOpen={enabled}
        cancelText={t("dialog.close")}
        acceptText={accept || t("dialog.accept")}
        onAcceptClick={() => {
          this.setStateWithMount({ messageDialog: { ...messageDialog, index: index + 1 } }, onAccept)
        }}
        onCancelClick={() => {
          this.setStateWithMount({ messageDialog: { ...messageDialog, enabled: false, index: 0 } })
        }}
        toggle={() => {
          this.setStateWithMount({ messageDialog: { ...messageDialog, enabled: false, index: 0 } })
        }}
        t={t}
      >
        {message}
      </Dialog>
    )
  }


  renderErrorDialog = () => {
    const { t } = this.props;
    const { errorDialog } = this.state
    const { enabled, message } = errorDialog;

    return (
      <Dialog
        title={t("dialog.error")}
        isOpen={enabled}
        cancelText={t("dialog.close")}
        onCancelClick={() => {
          this.setStateWithMount({ errorDialog: { ...errorDialog, enabled: false } })
        }}
        toggle={() => {
          this.setStateWithMount({ errorDialog: { ...errorDialog, enabled: false } })
        }}
        t={t}
      >
        {message}
      </Dialog>
    )
  }


// zzz
  renderInputFields() {
    const { inputFields, isDepositLoading } = this.state;
    return inputFields.map((field, index) => {
      const { label, name, selections, error = '', required } = field
      return (
        <div className="d-flex flex-column  p-1" key={index} >
          <div className="d-flex flex-row align-items-center" >
            <label className="col-4 p-0" style={{ color: "#414141", fontWeight: 600 }}>{label}{required ? '*' : ''}</label>
            <div className='deposit-input col-8 p-0'>
              {name.toLowerCase() === "amount" &&
                <NumberFormat
                  type="text"
                  name={name}
                  suffix=" ₸"
                  className={`w-100 ${error ? 'error' : ''}`}
                  decimalScale={0}
                  fixedDecimalScale={false}
                  allowNegative={false}
                  isNumericString={true}
                  isAllowed={() => !isDepositLoading}
                  value={field.value}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.onInputChange(index, value)
                  }}
                  placeholder={"0 ₸"}
                />
              }
              {name.toLowerCase() !== "amount" &&
                <input value={field.value} className={`w-100 ${error ? 'error' : ''}`} name={name} onChange={(event) => this.onInputChange(index, event.target.value)} />
              }
            </div>
          </div>
          {name.toLowerCase() === "amount" &&
            <div className='d-flex flex-column'>
              <div className='d-flex flex-row m-2 justify-content-end'>

                {selections.map((value, buttonIndex) => {
                  return (
                    <Button
                      key={buttonIndex}
                      active={parseFloat(value) === parseFloat(field.value)}
                      className={`btn-outline ${index !== selections.length - 1 ? 'mr-1' : ''}`}
                      onClick={() => { this.onInputChange(index, value) }}
                    >
                      {value}
                    </Button>)
                })}
              </div>
            </div >
          }
          {error &&
            <div className="col-8 ml-auto m-0 pr-1" style={{ color: "#fa0000", textAlign: "right", fontWeight: 500, fontSize: 15 }}>{error}</div>
          }

        </div>

      )
    })
  }

  renderForm(method) {
    const { minAmount, maxAmount, endpoint, info, title, messages = [] } = method;
    const { t } = this.props;
    return (
      <div ref={this.formRef}>
        {info &&
          <div className='p-3 my-2 border shadow-sm' style={{ borderRadius: 20, background: "white", color: "#414141", fontWeight: 500 }}>
            {info}
          </div>
        }

        <div className='p-3 my-2 border shadow-sm' style={{ borderRadius: 20, background: "white" }}>
          <div className="d-flex justify-content-between mb-2 align-items-end" >
            <span className="d-flex" style={{ color: "#713198", fontSize: 20, fontWeight: 600 }}>{title}</span>
            <div className='d-flex align-items-center' style={{ fontSize: 13 }}>
              <span style={{ color: "#6c757d", fontWeight: 500, marginRight: 5 }}>LIMIT: </span>
              <span style={{ color: "#713198", fontWeight: 600 }}>{minAmount} - {maxAmount}</span>
            </div>
          </div>
          <hr />
          {this.renderInputFields()}
          <LoadingButton text={t('wallet.deposit')} className="btn-yellow mt-4" style={{ fontSize: 20, fontWeight: 500, height: 40 }} isLoading={this.state.isDepositLoading} onClick={() => {
            this.sendRequest(endpoint, messages)
          }} />
        </div>
      </div>

    )
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selected.key !== prevState.selected.key) {
      this.formRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  renderMethodsWithObject(country = "kz") {
    const methodsArr = country === "kz" ? this.kzDepositMethods : this.ruDepositMethods;
    const { selected, isDepositLoading } = this.state;
    const { t } = this.props;
    const rowSize = 3;
    const methods = [];

    const keys = Object.keys(methodsArr).filter((key) => methodsArr[key].enabled);

    if (keys.length === 0) {
      return
    }

    for (let i = 0; i < keys.length; i += rowSize) {
      methods.push(keys.slice(i, i + rowSize))
    }


    const methodsJSX = methods.map((row, rowIndex) => {
      const divRow = row.map((key, itemIndex) => {
        const { fields, minAmount, icon } = methodsArr[key];
        const index = rowIndex * rowSize + itemIndex;
        return (
          <button className={`payment-method ${selected.key === key && selected.country === country ? 'active' : ''}`} onClick={() => {
            if (!isDepositLoading) {
              if (selected.index !== index
                || selected.country !== country) {
                this.setStateWithMount({
                  selected: {
                    key,
                    rowIndex,
                    country
                  },
                  inputFields: clonedeep(fields)
                })
              }
            }
          }} key={itemIndex}>
            <img className='w-100 p-3' src={icon} alt={key} style={{ maxWidth: 170 }} />
            {minAmount !== 0 &&
              <span>Min: {minAmount}</span>}
          </button>
        )
      })

      return (
        <div key={rowIndex}>
          <div className='d-flex flex-row '>
            {divRow}
          </div>
          {selected.rowIndex === rowIndex && selected.country === country &&
            this.renderForm(methodsArr[selected.key])
          }
        </div>
      )
    })

    return (
      <div>
        <div className='p-2 w-100 deposit-section d-flex align-items-center'>
          <img src={images.flags[country]} width={20} height={20} alt={country} className='mr-2' /> <span>{country === 'kz' ? t("wallet.kzBanks") : t("wallet.ruBanks")}</span>
        </div>
        <div className='p-2'>
          {methodsJSX}
        </div>
      </div>
    )
  }

  render() {
    const { history, t } = this.props;
    return (
      <PaymentContainer backText="Back" title="Deposit" history={history} color="white" t={t}>
        {this.renderErrorDialog()}
        {this.renderMessageDialog()}
        <div className='chat-box' onClick={() => { this.props.history.push("/chat") }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24.294" viewBox="0 0 26 24.294">
            <path id="Icon_simple-hipchat" data-name="Icon simple-hipchat" d="M21.381,20.972s.112-.079.289-.215A10.593,10.593,0,0,0,26,12.4C26,6.2,20.179,1.181,13,1.181S0,6.2,0,12.4,5.819,23.614,13,23.614a14.908,14.908,0,0,0,2.734-.247l.284-.049a13.53,13.53,0,0,0,6.812,2.156.7.7,0,0,0,.6-1.2,13.158,13.158,0,0,1-2.045-3.306Zm-1.584-4.9a8.4,8.4,0,0,1-6.775,3.13h-.05A8.414,8.414,0,0,1,6.2,16.069a1.232,1.232,0,0,1-.3-.622.531.531,0,0,1,.484-.57.058.058,0,0,1,.023,0,.681.681,0,0,1,.355.12A9.9,9.9,0,0,0,13,17.221a9.539,9.539,0,0,0,6.24-2.231.5.5,0,0,1,.339-.132.523.523,0,0,1,.523.515,1.431,1.431,0,0,1-.29.7H19.8Z" transform="translate(0 -1.181)" fill="#fff" />
          </svg>
          <span>{t("wallet.contactUs")}</span>
        </div>

        {this.renderMethodsWithObject()}

        {this.renderMethodsWithObject("ru")}

        <div style={{ marginBottom: 90 }}></div>

      </PaymentContainer >
    )
  }
}


export default withContext(Deposit)