import * as CustomerSDK from '@livechat/customer-sdk'
import { provider } from './identityProvider'

// import { session } from './session'


let customerSDK = null, unReadMessageCount = 0;

export const init = () => {

  if (!customerSDK) {
    customerSDK = CustomerSDK.init({
      licenseId: 13093878,
      clientId: 'ed621df27c8dccfa91954860b686cc15',
      redirectUri: '/',
      identityProvider: provider
    })
  }

  customerSDK.on('connected', onChatConnected);
}

export const clearListeners = () => {
  // console.log('clear')
  if (customerSDK) {
    customerSDK = customerSDK.destroy();
  }
  unReadMessageCount = 0;
}

export const getCustomerSDK = () => {
  return customerSDK;
}

// export const registerConnectedHandler = (onConnected) => {
//   customerSDK.on('connected', onChatConnected)
// }

export const getUnreadMessagesCount = () => {
  return unReadMessageCount;
}

const getNotifications = async (customerId) => {
  const sdk = getCustomerSDK();
  const { chatsSummary, totalChats } = await sdk.listChats();

  if (totalChats > 0) {
    const chatId = chatsSummary[0].id;
    const eventsSeenUpToMap = chatsSummary[0].eventsSeenUpToMap;

    const { threads } = await sdk.listThreads({ chatId });



    threads.forEach((thread) => {
      // console.log("foreach", thread, eventsSeenUpToMap)

      thread.events.forEach((event) => {

        const { type, createdAt } = event;
        if (type === 'message') {
          // const b = eventsSeenUpToMap[authorId];
          const lastSeenDate = eventsSeenUpToMap[customerId];
          // console.log(lastSeenDate)
          // console.log(new Date(lastSeenDate), new Date(createdAt), text)

          if (lastSeenDate === null || new Date(lastSeenDate) < new Date(createdAt)) {
            // console.log(new Date(lastSeenDate), new Date(createdAt), lastSeenDate, createdAt)
            unReadMessageCount++;
          }
        }
      })
    })
  }
}

const onChatConnected = async () => {
  // const { getCustomerSDK } = this.props.chat

  // console.log('onChatConnected')

  const sdk = getCustomerSDK();

  // session.setUnreadMessagesCount(0);
  unReadMessageCount = 0

  const { id: customerId } = await sdk.getCustomer();


  await getNotifications(customerId);

  // console.log(totalNotifications)
  // session.setUnreadMessagesCount(totalNotifications);
  // this.setState({ unReadMessageCount: totalNotifications })
  // un
  // console.log(unReadMessageCount)

  sdk.on('events_marked_as_seen', payload => {
    const { userId } = payload
    // console.log('events_marked_as_seen', { chatId, userId, seenUpTo })
    if (userId === customerId) {
      // console.log('customer')

      unReadMessageCount = 0;
    }
  })

  sdk.on('incoming_chat', () => {
    // const { id, access, users, properties, thread } = chat
    // console.log('incoming_chat', { id, access, users, properties, thread })
    // session.setChatId(chat.id)

    // console.log(chat);
    (async () => {
      await getNotifications(customerId);
    })
      ();

  })


  sdk.on('incoming_event', payload => {
    const { event } = payload
    // console.log('event')
    switch (event.type) {
      case 'message':
        // console.log('new message - ', event.text)
        // let unReadMessageCount = parseInt(session.getUnreadMessagesCount());

        unReadMessageCount++;

        // session.setUnreadMessagesCount(unReadMessageCount);
        // this.setState({ unReadMessageCount })
        break
      default:
        break
    }
  })

}





// customerSDK.on('connected', payload => {
//   const { customer, availability, greeting } = payload
//   console.log('connected', { customer, availability, greeting })

//   // this.customerSDK.listChats({}).then(({ chatsSummary, totalChats }) => {
//   //   if (totalChats > 0) {
//   //     let chatId = chatsSummary[0].id;
//   //     console.log(chatId);
//   //   }
//   // }).catch(error => {
//   //   console.log(error)
//   // })

//   // this.customerSDK.on('incoming_chat', payload => {
//   //   const { chat } = payload
//   //   const { id, access, users, properties, thread } = chat
//   //   console.log('incoming_chat', { id, access, users, properties, thread })
//   // })

// })